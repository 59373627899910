import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as authActions from "../../../redux/actions/authAction";

const UsersListForChat = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [clientDataFromNavigation] = useState(location?.state?.dataFromNav);

  useEffect(() => {
    dispatch(authActions.getAllCoachClients());
  }, []);
  const { coachClientsData } = useSelector((state) => state.getClientsData);

  const onClientWholeRow = (selectedUserInfo) => {
    navigate("/chat-client", {
      state: {
        info: selectedUserInfo,
        selectedNotificationData: clientDataFromNavigation,
      },
    });
  };
  const callBack = (resp) => {
    dispatch(authActions.showCoachActivities(true));
  };
  return (
    <>
      <div className="coach-clients-page p-5">
        <>
          <div className="clients-page-main-container">
            {coachClientsData?.data?.length !== 0 ? (
              <>
                <table>
                  <thead>
                    <tr
                      className="header-row"
                      style={{ margin: "10px", height: "50px" }}
                    >
                      <th
                        style={{
                          width: "50px",
                          borderTopLeftRadius: "8px",
                          borderBottomLeftRadius: "8px",
                        }}
                      ></th>
                      <th
                        style={{
                          width: "500px",
                          paddingLeft: "26px",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          width: "473px",
                        }}
                      >
                        Due soon
                      </th>
                      <th
                        style={{
                          width: "275px",
                        }}
                      >
                        <p> Engagements rate</p>
                        <span style={{ fontSize: "12px" }}>
                          7 days | 30 days | 90 days
                        </span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {coachClientsData?.data?.map((clientsData, index) => {
                      return (
                        <tr
                          className="whole-row-client"
                          key={index}
                          style={{
                            borderBottom: "1px solid #f1f5f9",
                          }}
                          onClick={(e) => onClientWholeRow(clientsData)}
                        >
                          <td
                            style={{
                              paddingLeft: "35px",
                            }}
                          >
                            <div className="form-check ">
                              <input
                                className="form-check-input client-checkbox"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                              />
                            </div>
                          </td>
                          <td className="pic-name-in-table">
                            <div className="client-img-in-table ms-3 pt-1">
                              {clientsData?.imageUrl ? (
                                <img
                                  className="coach-clients-data-img "
                                  src={clientsData?.imageUrl}
                                  alt="img"
                                />
                              ) : (
                                <img
                                  src="/img/sample_img_2.png"
                                  className="coach-clients-data-img "
                                  alt="EQWell logo"
                                />
                              )}
                            </div>
                            <div style={{ paddingTop: "20px" }}>
                              <p>
                                {clientsData?.firstName} {clientsData?.lastName}
                              </p>
                            </div>
                          </td>
                          <td>
                            <div
                              className="d-grid gap-2 d-md-block"
                              style={{ fontSize: "13px" }}
                            >
                              {moment(clientsData?.dueSoon).format("Do MMM-YY")}
                            </div>
                          </td>
                          <td
                            style={{
                              paddingLeft: "12px",
                              color: "#1b46b4",
                              textAlign: "center",
                            }}
                          >
                            {clientsData?.engagements
                              ? clientsData?.engagements?.sevenDays
                                  ?.toString()
                                  .split(".")[0] +
                                "%" +
                                "  |  " +
                                clientsData?.engagements?.thirtyDays
                                  ?.toString()
                                  .split(".")[0] +
                                "%" +
                                "  |  " +
                                clientsData?.engagements?.ninetyDays
                                  ?.toString()
                                  .split(".")[0] +
                                "%"
                              : "0"}
                          </td>
                          {/* <td
                            style={{
                              width: "133px",
                            }}
                          >
                            <div className="clients-data-btn">
                              <Dropdown>
                                <Dropdown.Toggle id="toggle-btn-clients">
                                  <img
                                    src="/img/client-detail-btn.png"
                                    alt="btn-img"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-options-container">
                <Dropdown.Item className="dropdown-options">
                  <NavLink
                    className="create-activity-font"
                    to="/coach-activities"
                    onClick={() => {
                      onGetClientId(clientsData._id);
                    }}
                  >
                    Create an activity
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <h3>No client yet</h3>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default UsersListForChat;
