import React, { useState } from "react";
import { Form } from "react-bootstrap";
const Checkqstn = (props) => {
  const [selectBtn, setSelectBtn] = useState();
  const [selectBtnId, setSelectBtnId] = useState();
  const [selectCheckBox, setSelectCheckBox] = useState("");
  const [selectCheckBoxId, setSelectCheckBoxId] = useState("");
  const [txt, setTxt] = useState("");
  const [checkInputForChild, setCheckInputForChild] = useState("");
  const calFun = (str) => {
    let obj = {
      questionId: props.questionId,
      selectedOption: str,
    };
    props.updateData(obj);
    setSelectBtn(str);
    setSelectBtnId(props.questionId);
  };
  const inputForChildFunc = (str) => {
    const childInput = str === "no comment" ? false : true;
    let obj = {
      questionId: props.questionId,
      selectedOption: str,
    };
    setSelectBtn(str);
    setCheckInputForChild(childInput);
  };
  const updateData = (str, questionId) => {
    let obj;
    if (props.data.conditional) {
      obj = {
        questionId: questionId,
        selectedOption: str === "no" ? "no" : str,
      };
    } else {
      obj = {
        questionId: questionId,
        selectedOption: str,
      };
    }

    props.updateData(obj);

    setSelectCheckBox(txt);
    setSelectCheckBoxId(questionId);
  };
  const updateTextInput = (str, questionId) => {
    let obj = {
      questionId: questionId,
      selectedOption: str === "no" ? "no" : str,
    };
    props.updateData(obj);
    setTxt(str);
  };
  return (
    <>
      <div className="question pt-4">
        <p className="d-flex">{props?.question}</p>

        <div className="option d-flex ps-4">
          {props?.data[0]?.options?.optiontype === "fields" ? (
            <Form>
              {props?.data[0]?.options?.values.map((val, index) => (
                <Form.Check
                  key={index}
                  aria-label="option 1"
                  inline
                  checked={val === selectBtn ? true : false}
                  label={val}
                  onChange={() => inputForChildFunc(val)}
                />
              ))}
            </Form>
          ) : (
            <Form>
              {props?.data?.options?.values.map((val, index) => (
                <Form.Check
                  key={index}
                  aria-label="option 1"
                  inline
                  checked={val === selectBtn ? true : false}
                  label={val}
                  onChange={() => calFun(val)}
                />
              ))}
            </Form>
          )}
        </div>
        <div className="survey-question-input">
          {props?.data[0]?.conditional && checkInputForChild ? (
            // props.data[0]?.options?.optiontype === "fields"

            <input
              className="question-input"
              type="text"
              placeholder="Add comment"
              label="Add comment"
              value={txt}
              onChange={(text) =>
                updateTextInput(text.target.value, props.data[0]?._id)
              }
            />
          ) : null}
          {props?.data?.conditional ? (
            // props.data[0]?.options?.optiontype === "fields"

            <input
              className="question-input"
              type="text"
              placeholder="Add comment"
              label="Add comment"
              value={txt}
              onChange={(text) =>
                updateTextInput(text.target.value, props.data[0]?._id)
              }
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Checkqstn;
