import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Oval } from "react-loader-spinner";
import { Modal } from "react-bootstrap";

const CoachClients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState([]);
  const [sortDirection, setSortDirection] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [loader, setloader] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [
    loadingForUserEngagementOfJourney,
    setLoadingForUserEngagementOfJourney,
  ] = useState(false);
  const [modalForUserEngagementOfJourney, setModalForUserEngagementOfJourney] =
    useState(false);

  useEffect(() => {
    setloader(true);
    dispatch(authActions.getAllCoachClients(setloader));
  }, [dispatch]);

  const { coachClientsData, UserEngagementsByJourney } = useSelector(
    (state) => state.getClientsData
  );

  useEffect(() => {
    if (coachClientsData?.data) {
      setSortedData(coachClientsData.data);
    }
  }, [coachClientsData]);

  const callBack = (resp) => {
    dispatch(authActions.showCoachActivities(true));
  };

  const handleViewClick = (e) => {
    dispatch(authActions.getActivityListByUserId(e._id, callBack));
    navigate("/coach-activities", { state: { e } });
  };
  const handleJourneyBtnClick = (e) => {
    setSelectedUser(e);
    setModalForUserEngagementOfJourney(true);
    setLoadingForUserEngagementOfJourney(true);

    dispatch(
      authActions.getUserEngagementsByJourney(
        e._id,
        callBackForUserEngagementByJourney
      )
    );
  };
  const callBackForUserEngagementByJourney = (res) => {
    if (res) {
      setLoadingForUserEngagementOfJourney(false);
    }
  };
  const onUserEngagementOfJourneyModalClose = () => {
    setSelectedUser({});
    setModalForUserEngagementOfJourney(false);
  };
  const handleCreateClick = (e) => {
    dispatch(authActions.getActivityListByUserId(e._id, callBack));
    navigate("/coach-activities", { state: { fromCreateBtn: e } });
  };

  const sortBy = (key, direction) => {
    const sorted = [...sortedData].sort((a, b) => {
      let valA, valB;
      if (key === "firstName") {
        valA = a.firstName.toUpperCase();
        valB = b.firstName.toUpperCase();
      } else if (key === "dueSoon") {
        valA = a.dueSoon ? new Date(a.dueSoon) : new Date(0);
        valB = b.dueSoon ? new Date(b.dueSoon) : new Date(0);
      }

      if (valA < valB) return direction === "asc" ? -1 : 1;
      if (valA > valB) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setSortedData(sorted);
    setSortDirection(direction);
    setSortColumn(key);
    setActiveButton(`${key}-${direction}`);
  };

  return (
    <>
      <div className="coach-clients-page p-5">
        <div className="clients-page-main-container">
          {loader ? (
            <div className="loader-container">
              <Oval
                height={30}
                width={30}
                color="#1b46b4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#56bcb2"
                strokeWidth={6}
                strokeWidthSecondary={6}
              />
            </div>
          ) : sortedData?.length !== 0 ? (
            <table>
              <thead>
                <tr
                  className="header-row"
                  style={{ margin: "10px", height: "50px" }}
                >
                  <th
                    style={{
                      width: "50px",
                      textAlign: "center",
                      borderTopLeftRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                  ></th>
                  <th
                    style={{
                      width: "200px",
                      textAlign: "left",
                      paddingLeft: "26px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <> Name</>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                          onClick={() => sortBy("firstName", "asc")}
                          disabled={activeButton === "firstName-asc"}
                          style={{
                            background: "none",
                            border: "none",
                            cursor:
                              activeButton === "firstName-asc"
                                ? "default"
                                : "pointer",
                            marginLeft: "5px",
                            fontSize: "10px",
                            padding: "2px",
                          }}
                        >
                          ▲
                        </button>
                        <button
                          onClick={() => sortBy("firstName", "desc")}
                          disabled={activeButton === "firstName-desc"}
                          style={{
                            background: "none",
                            border: "none",
                            cursor:
                              activeButton === "firstName-desc"
                                ? "default"
                                : "pointer",
                            marginLeft: "5px",
                            fontSize: "10px",
                            padding: "2px",
                            marginTop: "-8px",
                          }}
                        >
                          ▼
                        </button>
                      </div>
                    </div>
                  </th>
                  <th
                    style={{
                      width: "150px",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <>Due soon</>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <button
                          onClick={() => sortBy("dueSoon", "asc")}
                          disabled={activeButton === "dueSoon-asc"}
                          style={{
                            background: "none",
                            border: "none",
                            cursor:
                              activeButton === "dueSoon-asc"
                                ? "default"
                                : "pointer",
                            marginLeft: "5px",
                            fontSize: "10px",
                            padding: "2px",
                          }}
                        >
                          ▲
                        </button>
                        <button
                          onClick={() => sortBy("dueSoon", "desc")}
                          disabled={activeButton === "dueSoon-desc"}
                          style={{
                            background: "none",
                            border: "none",
                            cursor:
                              activeButton === "dueSoon-desc"
                                ? "default"
                                : "pointer",
                            marginLeft: "5px",
                            fontSize: "10px",
                            padding: "2px",
                            marginTop: "-8px",
                          }}
                        >
                          ▼
                        </button>
                      </div>
                    </div>
                  </th>
                  <th style={{ width: "175px", textAlign: "center" }}>
                    <p>Engagements rate</p>
                    <span style={{ fontSize: "12px" }}>
                      7 days | 30 days | 90 days
                    </span>
                  </th>
                  {/* <th style={{ width: "150px", textAlign: "center" }}>
                    Bonus Points
                  </th> */}
                  <th style={{ width: "150px", textAlign: "center" }}>
                    Journeys
                  </th>
                  <th style={{ width: "150px", textAlign: "center" }}>
                    Activities
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.map((clientsData, index) => {
                  return (
                    <tr
                      key={index}
                      style={{ borderBottom: "1px solid #f1f5f9" }}
                    >
                      <td style={{ textAlign: "center" }}>
                        <div className="form-check">
                          <input
                            className="form-check-input client-checkbox"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                          />
                        </div>
                      </td>
                      <td
                        className="pic-name-in-table"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div className="client-img-in-table ms-3 pt-1">
                          {clientsData?.imageUrl ? (
                            <img
                              className="coach-clients-data-img"
                              src={clientsData?.imageUrl}
                              alt="img"
                            />
                          ) : (
                            <img
                              src="/img/sample_img_2.png"
                              className="coach-clients-data-img"
                              alt="EQWell logo"
                            />
                          )}
                        </div>
                        <div style={{ paddingTop: "20px" }}>
                          <p>
                            {clientsData?.firstName} {clientsData?.lastName}
                          </p>
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          fontSize: "13px",
                        }}
                      >
                        {clientsData?.dueSoon
                          ? moment(clientsData?.dueSoon).format("Do MMM-YY")
                          : "No Activity"}
                      </td>
                      <td
                        style={{
                          paddingLeft: "12px",
                          color: "#1b46b4",
                          textAlign: "center",
                        }}
                      >
                        {clientsData?.engagements
                          ? `${
                              clientsData?.engagements?.sevenDays
                                ?.toString()
                                .split(".")[0]
                            }% | ${
                              clientsData?.engagements?.thirtyDays
                                ?.toString()
                                .split(".")[0]
                            }% | ${
                              clientsData?.engagements?.ninetyDays
                                ?.toString()
                                .split(".")[0]
                            }%`
                          : "0"}
                      </td>
                      {/* <td
                        style={{
                          paddingLeft: "55px",
                          color: "#1b46b4",
                          textAlign: "center",
                        }}
                      >
                        {clientsData?.bonusPoints
                          ? clientsData?.bonusPoints
                          : "0"}
                      </td> */}
                      <td style={{ textAlign: "center", fontSize: "13px" }}>
                        <button
                          style={{
                            margin: "5px",
                            padding: "5px 10px",
                            backgroundColor: "#2a63b3",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleJourneyBtnClick(clientsData);
                          }}
                        >
                          Journey {clientsData?.recentJourney}
                        </button>
                      </td>
                      <td style={{ textAlign: "center", fontSize: "13px" }}>
                        <button
                          style={{
                            margin: "5px",
                            padding: "5px 10px",
                            backgroundColor: "#2a63b3",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewClick(clientsData);
                          }}
                        >
                          View
                        </button>
                        <button
                          style={{
                            margin: "5px",
                            padding: "5px 10px",
                            backgroundColor: "#55bab2",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCreateClick(clientsData);
                          }}
                        >
                          Create
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="no-data-case">No client yet</div>
          )}
        </div>
      </div>
      <>
        {/*User Engagement from Journey modal*/}
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={modalForUserEngagementOfJourney}
          onHide={onUserEngagementOfJourneyModalClose}
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>
              Engagement rates of {selectedUser?.firstName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            {loadingForUserEngagementOfJourney ? (
              <div className="loader-container">
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </div>
            ) : (
              <div>
                {UserEngagementsByJourney?.data?.map((journey, index) => (
                  <div key={index} className="journey-container">
                    {/* Display Journey Attempt */}
                    <div className="journeys-heading">
                      Journey {journey?.attempt}
                    </div>

                    {/* Engagement Rate Container */}
                    <div className="engagement-rate-container">
                      <div className="engagement-rate-param">
                        <p>7 days</p>
                        <p>30 days</p>
                        <p>90 days</p>
                      </div>

                      {/* Engagement Rate Data */}
                      <div className="engagement-rate-data">
                        {/* 7 Days Engagement Rate */}
                        <p>
                          {journey?.userJourneyEngagement?.sevenDays
                            ? journey?.userJourneyEngagement?.sevenDays
                                .toString()
                                .split(".")[0]
                            : "0"}
                          %
                        </p>

                        {/* 30 Days Engagement Rate */}
                        <p>
                          {journey?.userJourneyEngagement?.thirtyDays
                            ? journey?.userJourneyEngagement?.thirtyDays
                                .toString()
                                .split(".")[0]
                            : "0"}
                          %
                        </p>

                        {/* 90 Days Engagement Rate */}
                        <p>
                          {journey?.userJourneyEngagement?.ninetyDays
                            ? journey?.userJourneyEngagement?.ninetyDays
                                .toString()
                                .split(".")[0]
                            : "0"}
                          %
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default CoachClients;
