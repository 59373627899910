import React, { useState, useEffect } from "react";
import SetGoalBox from "../../../components/SetGoalBox";
import SelectOption from "../../../components/SelectOption";

import { useDispatch } from "react-redux";
import { toastify } from "../../../components/Toast";
import {
  populateContentToCalendar,
  postGoalsRequest,
} from "../../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";

const SetGoals = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState(props?.data ? props?.data : []);
  const [data2, setData2] = useState([]); // List of selected goal IDs
  const [apiCallForGoals, setApiCallForGoals] = useState(false); // Loader state
  const [allowSelection, setAllowSelection] = useState(false); // Disable selection flag if 3 goals selected
  const [dbSelected, setDbSelected] = useState([]);
  const [goalsInDB, setGoalsInDB] = useState(null);
  const [hasLowPreviouslySelected, setHasLowPreviouslySelected] =
    useState(false); // Track if any previously selected goal is in the low category
  const [riskClassesCount, setRiskClassesCount] = useState({
    riskClassHighCount: 0,
    riskClassAverageCount: 0,
    riskClassLowCount: 0,
  });

  // Handling the goals count and API logic in a single function
  const callFunn = async () => {
    if (props?.data?.goals !== undefined) {
      let x = props?.data?.goals;
      let selectedLengthInDb = x.filter((obj) => obj.selected === true).length;
      setGoalsInDB(selectedLengthInDb);
      if (selectedLengthInDb === 3) {
        setAllowSelection(true); // If 3 goals are already selected, disable further selections
      }

      let array = [];
      let riskClassHighCount = 0;
      let riskClassAverageCount = 0;
      let riskClassLowCount = 0;
      let lowPreviouslySelected = false; // Track if any low-category goal is previously selected

      x.forEach((element) => {
        if (element.selected) {
          // Add the ID of "selected" goals to dbSelected (which are to be disabled if limit is reached)
          dbSelected.push(element._id);
        }

        if (element.isPreviouslySelected) {
          // Add previously selected goals to data2[] to be sent in the API call
          array.push(element._id);
          // Check if any previously selected goal belongs to the low category
          if (element.riskClass === "low") {
            lowPreviouslySelected = true; // Set flag to true if low-category goal is previously selected
          }
        }

        // Count risk classes
        if (element?.riskClass === "high") {
          riskClassHighCount += 1;
        } else if (element?.riskClass === "average") {
          riskClassAverageCount += 1;
        } else {
          riskClassLowCount += 1;
        }
      });

      setHasLowPreviouslySelected(lowPreviouslySelected); // Set state for low-category check
      setRiskClassesCount({
        riskClassHighCount,
        riskClassAverageCount,
        riskClassLowCount,
      });

      // Update data2 with previously selected goals
      setData2(array);
      setData(x);
    }
  };

  useEffect(() => {
    if (props?.data?.goals.length > 0) {
      callFunn();
    }
  }, [props?.data?.goals]);

  const handleCheck = (option) => {
    let uu = dbSelected.find((ele) => ele === option._id);

    if (!uu) {
      let found = data2.find((ele) => ele === option._id);
      if (!found) {
        // Add new selection
        setData2((prevData) => [...prevData, option._id]);
      } else {
        // Remove from selection
        setData2(data2.filter((ele) => ele !== option._id));
      }
    }
  };

  const onPress = () => {
    if (data2.length === 0) {
      toastify("error", "Please select any goal");
      return;
    }
    saveGoalsResponse(data2); // Call the save function and trigger API
  };

  // API Call for saving goals
  const saveGoalsResponse = (selectedGoals) => {
    if (
      riskClassesCount?.riskClassHighCount > 0 ||
      riskClassesCount?.riskClassAverageCount > 0
    ) {
      const updatedArr2 = selectedGoals.filter((id) => {
        const item = data.find((obj) => obj._id === id);
        return item && item.riskClass !== "low";
      });
      selectedGoals = updatedArr2;
    }
    const selectedGoalsArrayWithCurrentTime = {
      selectedGoals: selectedGoals,
      currentTime: Date.now(),
    };
    if (selectedGoals.length > 3 - goalsInDB) {
      const toastifymessage = `You can select only ${3 - goalsInDB} goal`;
      toastify("error", toastifymessage);
      return;
    }
    if (selectedGoals.length > 3) {
      toastify("error", "You can select a maximum of 3 goals");
      setApiCallForGoals(false);

      return;
    } else {
      setApiCallForGoals(true);
      dispatch(
        postGoalsRequest(
          selectedGoalsArrayWithCurrentTime,
          callBackGoal,
          setApiCallForGoals
        )
      );
    }
  };

  // Callback after API call
  const callBackGoal = (response) => {
    navigate("/dashboard");
    dispatch(populateContentToCalendar());
  };

  const EmptyValue = () => {
    return <h1>Empty</h1>;
  };

  const { riskClassHighCount, riskClassAverageCount, riskClassLowCount } =
    riskClassesCount;

  let tilte =
    riskClassHighCount > 0 && riskClassAverageCount > 0
      ? "Please select 3 goals from the high and average categories."
      : riskClassHighCount > 0
      ? "Please select 3 goals from the high category."
      : riskClassAverageCount > 0
      ? "Please select 3 goals from the average category."
      : riskClassLowCount > 0
      ? "Please select 3 goals from the low category."
      : "";

  return (
    <div className="container-goals">
      <h5 className="mt-3">{tilte}</h5>
      <div>
        {/* Conditionally render High and Average Risk Class Goals */}
        {(riskClassHighCount > 0 || riskClassAverageCount > 0) && (
          <>
            {/* High Risk Class */}
            {riskClassHighCount > 0 && (
              <SetGoalBox
                titleColor={"#ec6666"}
                title={"High"}
                riskClassesCount={riskClassHighCount}
              >
                {props?.data?.goals.length > 0 ? (
                  props?.data?.goals.map((option) => {
                    if (option.riskClass === "high") {
                      return (
                        <SelectOption
                          key={option._id}
                          option={option.title}
                          selected={
                            option.isPreviouslySelected || option.selected // Checked if previously selected or already selected
                          }
                          disabled={
                            // Disable if selected, or if 3 goals have been chosen and the previously selected goal is not from the low category
                            option.selected ||
                            (data2.length >= 3 &&
                              !hasLowPreviouslySelected &&
                              !data2.includes(option._id))
                          }
                          onOptionPress={() => handleCheck(option)}
                        />
                      );
                    }
                  })
                ) : (
                  <EmptyValue />
                )}
              </SetGoalBox>
            )}

            {/* Average Risk Class */}
            {riskClassAverageCount > 0 && (
              <SetGoalBox
                titleColor={"#00bfb2"}
                title={"Average"}
                riskClassesCount={riskClassAverageCount}
              >
                {props?.data?.goals.length > 0 ? (
                  props?.data?.goals.map((option) => {
                    if (option.riskClass === "average") {
                      return (
                        <SelectOption
                          key={option._id}
                          option={option.title}
                          selected={
                            option.isPreviouslySelected || option.selected
                          }
                          disabled={
                            // Disable if selected, or if 3 goals have been chosen and the previously selected goal is not from the low category
                            option.selected ||
                            (data2.length >= 3 &&
                              !hasLowPreviouslySelected &&
                              !data2.includes(option._id))
                          }
                          onOptionPress={() => handleCheck(option)}
                        />
                      );
                    }
                  })
                ) : (
                  <EmptyValue />
                )}
              </SetGoalBox>
            )}
          </>
        )}

        {/* Conditionally render Low Risk Class Goals if no High/Average are available */}
        {riskClassHighCount === 0 &&
          riskClassAverageCount === 0 &&
          riskClassLowCount > 0 && (
            <SetGoalBox
              titleColor={"#e1ec66"}
              title={"Low"}
              riskClassesCount={riskClassLowCount}
            >
              {data.length > 0 ? (
                data.map((option) => {
                  if (option.riskClass === "low") {
                    return (
                      <SelectOption
                        key={option._id}
                        option={option.title}
                        selected={
                          option.isPreviouslySelected || option.selected
                        }
                        disabled={
                          option.selected ||
                          (data2.length >= 3 && !data2.includes(option._id)) // Disable if selected or if 3 goals have been chosen
                        }
                        onOptionPress={() => handleCheck(option)}
                      />
                    );
                  }
                })
              ) : (
                <EmptyValue />
              )}
            </SetGoalBox>
          )}
      </div>

      {/* Save button */}
      {!allowSelection && (
        <button
          className="btn-save-goal my-4"
          onClick={onPress}
          disabled={apiCallForGoals}
        >
          {apiCallForGoals ? "Saving" : "Save"}
        </button>
      )}
    </div>
  );
};

export default SetGoals;
