import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Tab,
  Form,
  Button,
  Spinner,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import * as Yup from "yup";
import "../../../../src/index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toastify } from "../../../components/Toast";
const isValid = (value) => value !== "" && value !== "-";

const Signup = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fname, setFname] = useState();
  const [lname, setLname] = useState();
  const [email, setEmail] = useState();
  const [mob, setMob] = useState("");
  const [newMob, setNewMob] = useState("");
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [code, setCode] = useState();
  const [eye, seteye] = useState(true);
  const [type, settype] = useState("password");
  const [cEye, setCeye] = useState(true);
  const [ctype, setCtype] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [organizationCheck, setOrganizationCheck] = useState(false);
  const [mobileType, setMobType] = useState("US +1");

  useEffect(() => {
    if (mob.startsWith("92")) {
      setNewMob("+" + mob);
    } else {
      setNewMob("+1" + mob);
    }
  }, [mob]);

  var values = {
    firstName: fname,
    lastName: lname,
    email: email,
    password: password,
    phoneNumber: newMob,
    orgAccessCode: code,
    termsAndCond: true,
  };

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("This field is Required")
      .max(30, "The Max length is only 30."),
    // .matches(/^[a-z ,.'-]+$/i, "Please Use the correct format"),
    lastName: Yup.string()
      .required("This field is Required")
      .max(30, "The Max length is only 30."),
    // .matches(/^[a-z ,.'-]+$/i, "Please Use the correct format"),
    email: Yup.string()
      .required("This field is Required")
      .email("Enter a valid email"),

    phoneNumber: Yup.string()
      .required("This field is Required")
      .matches(/^[0-9\b]+$/, "Use only numbers")
      .min(10, "Mobile No. must be at least 10 charaters.")
      .max(12, "Mobile No. be at least 12 charaters."),

    password: Yup.string()
      .required("Password is mandatory")
      .min(8, "Password length must be at least 8 charaters.")
      .max(16, "Password length must at least 16 charaters."),

    confirmPwd: Yup.string()
      .required("Password is mandatory")
      .min(8, "Password length must be at least 8 charaters.")
      .max(16, "Password length must at least 16 charaters.")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = () => {
    if (!code) return toastify("error", "Organization code is mandatory");
    if (checkBoxState) {
      localStorage.setItem("phoneNumber", values.phoneNumber);

      dispatch(authActions.userSignupRequest(values, callbackForOtp));
      setIsLoading(true);
    } else {
      toastify("error", "Please agree the terms and condition");
    }
  };
  const callbackForOtp = (status) => {
    // setIsLoading(false);

    if (status === 200) {
      dispatch(
        authActions.userSendOtpRequest(
          { phoneNumber: values.phoneNumber },
          otpHandler
        )
      );
      // setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  // const onSubmit = (e) => {
  //   const { confirmPwd, ...inputFields } = e;
  //   if (e.phoneNumber.slice(-3) === "999") {
  //     inputFields = { ...e, phoneNumber: "+" + "923008449318" };
  //     setIsLoading(true);
  //     dispatch(authActions.userSignupRequest(inputFields, callback));
  //   } else {
  //     setIsLoading(true);
  //     dispatch(authActions.userSignupRequest(inputFields, callback));
  //   }
  // };

  // const callback = (status) => {
  //   if (status === 200) {
  //     localStorage.setItem("phoneNumber", values.phoneNumber);
  //     dispatch(
  //       authActions.userSendOtpRequest({ phoneNumber: values.phoneNumber },otpHandler)
  //     );
  //   }
  //   setIsLoading(false);
  // };

  const otpHandler = (status) => {
    if (status === 200) {
      setTimeout(() => {
        navigate("/otppassword");
      }, 3000);
    }
    setIsLoading(false);
  };
  const Eye = () => {
    if (type == "password") {
      settype("text");
      seteye(false);
    } else {
      settype("password");
      seteye(true);
    }
  };
  const CEye = () => {
    if (ctype == "password") {
      setCtype("text");
      setCeye(false);
    } else {
      setCtype("password");
      setCeye(true);
    }
  };
  const onCheckboxClick = (e) => {
    setCheckBoxState(!checkBoxState);
  };
  const onTermsAndConditionHandler = () => {
    window.open("/Terms-of-use", "_blank");
  };
  const onPrivacyPolicyHandler = () => {
    window.open("/Privacy-policy", "_blank");
  };

  return (
    <>
      <div className="signup">
        <div className="row">
          <div className="col-md-6 col-lg-8 ">
            <div className="">
              <div className="left-side container ">
                <div>
                  <div className="mb-4">
                    <img src="/img/logo-white.png" className="img-fluid" />
                  </div>
                  <div className="">
                    <h1 className="text-white d-flex">Become the best</h1>
                    <h1 className="text-white d-flex">Version of you</h1>
                    <h1 className="text-white d-flex">Become Eqwell</h1>
                  </div>
                  <div className="my-5">
                    <Link to="/login" className="text-decoration-none">
                      <button className="d-flex btn-signup font-weight-bold">
                        Login
                      </button>
                    </Link>
                  </div>
                  <div>
                    <ul className="d-flex justify-content-start pr-4  font-weight-bold list-unstyled">
                      {/* <li>
                        <a href="#" className="text-white text-decoration-none">
                          Why Eqwell?
                        </a>
                      </li> */}
                      <li>
                        {/* <a href="#" className="text-white text-decoration-none">
                          Pillars of Well-being
                        </a> */}
                        <a
                          style={{ paddingRight: "20px" }}
                          onClick={() => onPrivacyPolicyHandler()}
                          className="text-white text-decoration-none"
                          role="button"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-white text-decoration-none">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 ">
            <div className="right-side container my-4">
              <div className="">
                <h2 className="text-center mb-3 font-weight-bold">
                  Create An Account
                </h2>
              </div>

              <Tabs
                defaultActiveKey="second"
                className="justify-content-around mb-3"
              >
                {/* <Tab eventKey="first" title="Signup for organizarion">
                  <div className="org-signup-form">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label className="float-left d-none">
                          Full Name
                        </Form.Label>
                        <Form.Control type="text" placeholder="Full Name" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="float-left">
                          Email address
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email Address"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label className="float-left">
                          Mobile Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder=" Mobile Number"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="float-left">Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicCode">
                        <Form.Label className="float-left">
                          organization's Code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="organization's Code"
                        />
                      </Form.Group>


                      <Form.Group className="mb-3" controlId="formBasicLogo">
                        <Form.Label class="btn btn-logo">
                        <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-down" /> Upload Logo
                          <Form.Control
                          type="file" 
                          style={{ display: "none" }} 
                        />
                        </Form.Label>
                      
                      </Form.Group> 
                      <div className="">
                        <Button type="submit" className="btn-register">
                          Sign Up
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Tab> */}
                <Tab eventKey="second" title="Sign up for user">
                  <div className="user-signup-form">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="mb-3" controlId="formBasicNmae">
                        <Form.Label className="float-left">
                          First Name
                        </Form.Label>
                        <Form.Control
                          {...register("firstName")}
                          type="text"
                          // placeholder="First Name"
                          required
                          onChange={(e) => setFname(e.target.value)}
                        />
                      </Form.Group>
                      <p className="mb-0" style={{ color: "red" }}>
                        {errors.firstName?.message}
                      </p>
                      <Form.Group className="mb-3" controlId="formBasicNmae">
                        <Form.Label className="float-left">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          {...register("lastName")}
                          type="text"
                          // placeholder="Last Name"
                          required
                          onChange={(e) => setLname(e.target.value)}
                        />
                      </Form.Group>
                      <p className="mb-0" style={{ color: "red" }}>
                        {errors.lastName?.message}
                      </p>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="float-left">
                          Email address
                        </Form.Label>

                        <Form.Control
                          {...register("email")}
                          type="email"
                          // placeholder="Email Address"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                      <p className="mb-0" style={{ color: "red" }}>
                        {errors.email?.message}
                      </p>

                      <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Label className="float-left">
                          Mobile Number
                        </Form.Label>
                        <br />
                        <div className="w-100 d-flex gap-2">
                          <Dropdown size="small">
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              size="small"
                            >
                              {mobileType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => setMobType("US +1")}
                              >
                                US +1
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => setMobType("Other")}
                              >
                                Other
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Form.Control
                            {...register("phoneNumber")}
                            type="text"
                            placeholder={
                              mobileType === "Other"
                                ? "XX XXX XXXXXXX"
                                : "XXX XXX XXXX"
                            }
                            onChange={(e) => setMob(e.target.value)}
                          />
                        </div>
                      </Form.Group>
                      <p className="mb-0" style={{ color: "red" }}>
                        {errors.phoneNumber?.message}
                      </p>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                        required
                      >
                        <Form.Label className="float-left">Password</Form.Label>
                        <Form.Control
                          {...register("password")}
                          type={type}
                          // placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                      <i
                        onClick={Eye}
                        className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                      ></i>

                      <p className="mb-0" style={{ color: "red" }}>
                        {errors.password?.message}
                      </p>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                        required
                      >
                        <Form.Label className="float-left">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          {...register("confirmPwd")}
                          type={ctype}
                          // placeholder="Confirm Password"
                          onChange={(e) => setPassword2(e.target.value)}
                        />
                      </Form.Group>
                      <i
                        onClick={CEye}
                        className={`fa ${cEye ? "fa-eye-slash" : "fa-eye"}`}
                      ></i>
                      <p className="mb-0" style={{ color: "red" }}>
                        {errors.confirmPwd?.message}
                      </p>

                      {/* <Form.Group className="mb-3" controlId="formBasiccode">
                        <Form.Label className="float-left">
                          Do you belong to any Organization?
                        </Form.Label>
                        <br />
                        <div className="w-100 d-flex align-items-center justify-content-start gap-4">
                          <Form.Check // prettier-ignore
                            type={"radio"}
                            id={"organizationYes"}
                            label={"Yes"}
                            checked={organizationCheck === true}
                            onChange={(e) => setOrganizationCheck(true)}
                          />

                          <Form.Check
                            type={"radio"}
                            id={"organizationNo"}
                            label={"No"}
                            checked={organizationCheck === false}
                            onChange={(e) => setOrganizationCheck(false)}
                          />
                        </div>
                      </Form.Group> */}

                      {/* {organizationCheck && ( */}
                      <Form.Group className="mb-3" controlId="formBasiccode">
                        <Form.Label className="float-left">
                          Organization's code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          // maxLength={10}
                          // placeholder="organization's Code"
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </Form.Group>
                      {/* // )} */}
                      <div className="terms-and-condition-container d-flex mb-3">
                        <div>
                          <input
                            type="checkbox"
                            id="checkbox"
                            onChange={(e) => {
                              onCheckboxClick(e.target.value);
                            }}
                          />
                        </div>
                        <div className="ml-1">
                          By checking here, you agree to our
                          <span
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => onTermsAndConditionHandler()}
                          >
                            <u> Terms of Use</u>
                          </span>
                        </div>
                      </div>
                      <div className="text-center">
                        {isLoading ? (
                          <Button className="btn-register">
                            <Spinner
                              className=""
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            className="btn-register font-weight-bold"
                          >
                            Sign up
                          </Button>
                        )}
                      </div>
                    </Form>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default Signup;
