import React, { useEffect, useState } from "react";
import Checkqstn from "./Checkqstn";
import MultiCheckQstn from "./MultiCheckQstn";

const BooleanQstn = (props) => {
  const [selectBtn, setSelectBtn] = useState();
  const [selectBtnId, setSelectBtnId] = useState();
  const [button, setButton] = useState("");
  const [IsChild, setIsChild] = useState(false);
  const [child, setChild] = useState([]);

  const calFun = (str) => {
    var checkingChild = str === "yes" ? true : false;
    setIsChild(checkingChild);
    setChild(props?.data?.childs);
    let obj = {
      questionId: props.questionId,
      selectedOption: str ? str : [],
    };
    props.updateData(obj);
    setSelectBtn(str);
    setSelectBtnId(props.questionId);
    setButton(str);
  };

  const calFunForEmptyOptions = (str) => {
    let obj = {
      questionId: props.questionId,
    };

    props.updateData(obj);
  };

  useEffect(() => {
    if (props?.value?.length === 0) {
      calFunForEmptyOptions();
    }
  }, []);
  return (
    <>
      <div className="question pt-3 mb-5">
        <p className="d-flex">{props.question}</p>
        <div className="option d-flex ps-3">
          {props.value.length === 0
            ? ""
            : props.value.map((val, index) => (
                <button
                  className={
                    button === val ? "btn-yes ms-2" : "btn-yes-no ms-2"
                  }
                  key={index}
                  onClick={() => (val ? calFun(val) : null)}
                >
                  {val}
                </button>
              ))}
        </div>
      </div>
      {IsChild === true && child[0] ? (
        child[0]?.options?.optiontype === "checkBoxes" ? (
          <MultiCheckQstn
            // key={index}
            data={child}
            IsChild={IsChild}
            value={child[0]?.options?.values}
            questionId={child[0]?._id}
            question={child[0]?.questionText}
            updateData={props.updateData}
          />
        ) : (
          <Checkqstn
            // key={index}
            value={child[0]?.options?.value}
            data={child}
            questionId={child[0]?._id}
            question={child[0]?.questionText}
            updateData={props.updateData}
          />
        )
      ) : null}
    </>
  );
};
export default BooleanQstn;
