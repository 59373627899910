import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { socket } from "../constants/socket";
import * as authActions from "../redux/actions/authAction";
import { toastify } from "../components/Toast";
import { toast, ToastContainer } from "react-toastify";

const ChatClient = () => {
  const myRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const chatContainerRef = useRef(null);
  const [loading, setLoading] = useState(null);
  const [targetMessageId, setTargetMessageId] = useState(null);
  const [count, setCount] = useState(0);

  const [chatUIState, setChatUIState] = useState({
    chatDropdownOpen: false,
    addSingleUserCheck: false,
    addMultiUserCheck: false,
    addUserModal: false,
    showGroupMembersModal: false,
    addGroupMembersModal: false,
    singleUser: null,
    multipleUsers: [],
    chatList: [],
    selectedChatUser: {},
    groupName: "",
  });
  const [groupMembers, setGroupMembers] = useState([]);
  const [message, setMessage] = useState("");
  const [scrollUpdater, setScrollUpdater] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [loadingOnSendBtn, setLoadingOnSendBtn] = useState(false);
  const [infoFromNotification] = useState(
    location?.state?.selectedNotificationData
  );
  const [infoFromClientList] = useState(location?.state?.info);

  // const [userId, setUserId] = useState("");
  const { user } = useSelector((state) => state?.authentication);
  const { coachClientsData } = useSelector((state) => state.getClientsData);

  const inputMessageHandler = (e) => {
    setMessage(e.target.value);
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter" && !message?.length !== "") {
      onSendBtnHandler();

      if (!loadingOnSendBtn) {
      }
    }
  };

  const onSendBtnHandler = () => {
    if (!message || message.trim() === "") {
      return;
    }

    // setScrollUpdater(true);
    setLoadingOnSendBtn(true);

    if (
      !chatUIState.selectedChatUser?.chatId?.isGroup &&
      !chatUIState.selectedChatUser?.isGroup
    ) {
      socket?.off("private message");
      socket?.emit("private message", {
        receiverId:
          chatUIState.selectedChatUser?.senderId?._id ||
          chatUIState.selectedChatUser?.chatDetails?._id,
        senderId: user?.data?.userId,
        msg: message,
        createdTimeStamp: Date.now(),
      });
    } else {
      socket?.off("message-to-group");
      socket.emit("message-to-group", {
        chatId:
          chatUIState.selectedChatUser?.chatId?._id ||
          chatUIState.selectedChatUser?._id,
        senderId: user?.data?.userId,
        msg: message,
        createdTimeStamp: Date.now(),
      });
    }

    setMessage("");
  };

  const handleCloseAddUserModal = () => {
    setChatUIState((prevState) => ({
      ...prevState,
      addUserModal: false,
    }));
  };

  const handleCloseAddGroupMembersModal = () => {
    setChatUIState((prevState) => ({
      ...prevState,
      addGroupMembersModal: false,
    }));
  };

  const handleCloseShowGroupMembersModal = () => {
    setChatUIState((prevState) => ({
      ...prevState,
      showGroupMembersModal: false,
    }));
  };

  const handleShowAddUserModal = (key) => {
    fetchUsers();
    setChatUIState((prevState) => ({
      ...prevState,
      addUserModal: true,
      addMultiUserCheck: key === "addMultiUserCheck",
      addSingleUserCheck: key === "addSingleUserCheck",
      singleUser: null,
      multipleUsers: [],
      groupName: "",
    }));
  };

  const fetchUsers = () => {
    dispatch(authActions.getAllCoachClients());
  };

  const createChat = () => {
    if (chatUIState.addSingleUserCheck) {
      // createSingleChat(chatUIState.singleUser);
      const existingChat = chatUIState.chatList.find(
        (chat) => chat._id === chatUIState.singleUser._id
      );
      if (!existingChat) {
        createSingleChat(chatUIState.singleUser);
      }
    } else if (chatUIState.addMultiUserCheck) {
      if (!!!chatUIState.groupName) {
        toastify("error", "Please add the group name");
        return;
      }
      createGroupChat(chatUIState.multipleUsers);
    } else {
      return;
    }
    handleCloseAddUserModal();
  };

  const createGroupChat = (users) => {
    // let CHAT_ID = null;
    socket.emit(
      "create-group-chat",
      {
        groupName:
          chatUIState.groupName || `Group-${new Date().toLocaleString()}`,
        createdBy: user?.data?.userId,
        groupMembers: users,
        createdTimeStamp: Date.now(),
      },
      (response) => {
        if (response.error) {
          // Handle error
          toast.error(`Error: ${response.error}`);
        } else if (response.status == false) {
          // Group already exists
          toast.error(
            response?.message || `A group with the same members already exists.`
          );
        } else if (response.status == true) {
          // New group created
          toast.success(response?.message || `New group created successfully.`);
          socket.emit(
            "load-user-chats",
            {
              loggedInUserId: user?.data?.userId,
            },
            (chats) => {
              setChatUIState((prevState) => ({
                ...prevState,
                chatList: chats,
              }));
            }
          );
          loadChat(response.group);
        }
      }
    );

    // CHAT_ID && addGroupMembers(users, CHAT_ID);
  };

  const createSingleChat = (receiverId) => {
    socket.emit(
      "create-single-chat",
      {
        senderId: user?.data?.userId,
        receiverId: receiverId,
      },
      (response) => {
        if (response.error) {
          // Handle error
          toast.error(`Error: ${response.error}`);
        } else if (response.status === false) {
          toast.error(response?.message);
        } else if (response.status === true) {
          toast.success(response?.message);
          socket.emit(
            "load-user-chats",
            {
              loggedInUserId: user?.data?.userId,
            },
            (chats) => {
              setChatUIState((prevState) => ({
                ...prevState,
                chatList: chats,
              }));
            }
          );
          loadChat(response.group);
        }
      }
    );
  };

  const addGroupMembers = (users, CHAT_ID) => {
    socket.emit(
      "add-group-members",
      {
        users: chatUIState.multipleUsers,
        chatId:
          chatUIState.selectedChatUser?.chatId?._id ||
          chatUIState.selectedChatUser?._id,
      },
      (msg) => {
        if (msg.status === false) {
          toastify("error", msg?.message);
        } else {
          toastify("success", msg?.message);
        }
      }
    );
    socket.emit(
      "load-group-messages",
      chatUIState.selectedChatUser?.chatId?._id ||
        chatUIState.selectedChatUser?._id,
      user?.data?.userId
    );
    setChatUIState((prev) => ({ ...prev, addGroupMembersModal: false }));
  };

  const showGroupMembers = () => {
    // console.table({ chatId: chatUIState.selectedChatUser?._id });
    socket.emit(
      "chat-user-detail",
      {
        chatId:
          chatUIState.selectedChatUser?.chatId?._id ||
          chatUIState.selectedChatUser?._id,
      },
      (data) => {
        setGroupMembers(data || []);
        setChatUIState((prev) => ({ ...prev, showGroupMembersModal: true }));
      }
    );
  };

  const loadChat = (chat) => {
    setMessage("");
    setLoading(true);
    setChatUIState((prev) => ({
      ...prev,
      selectedChatUser: chat,
    }));

    if (!chat?.isGroup) {
      socket?.off("load-messages");
      socket.emit("load-messages", chat?.chatDetails?._id, user?.data?.userId);
    } else {
      socket?.off("load-group-messages");
      socket.emit("load-group-messages", chat._id, user?.data?.userId);
    }
    socket.off("new-messages");

    populateChat(chat?._id);
  };

  const loadChatFromNotificationsState = (chat) => {
    setLoading(true);
    setChatUIState((prev) => ({
      ...prev,
      selectedChatUser: chat,
    }));

    if (!chat?.chatId?.isGroup) {
      socket?.off("load-messages");
      socket.emit("load-messages", chat?.senderId?._id, user?.data?.userId);
    } else {
      socket?.off("load-group-messages");
      socket.emit("load-group-messages", chat?.chatId?._id, user?.data?.userId);
    }
    socket.off("new-messages");

    populateChat(chat?.chatId?._id);

    setTargetMessageId(chat.messageId?._id);
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };
  const populateChat = (idFromChatId) => {
    socket?.off("new-messages");
    socket.on("new-messages", (newMsg) => {
      if (!newMsg[0]?.chatId || newMsg[0]?.chatId === idFromChatId) {
        // Filter and set the messages
        setAllMessages(filterMessages(newMsg) || []);
        setScrollUpdater(!scrollUpdater);
      }
      // }
      setLoading(false);
      setLoadingOnSendBtn(false);
    });
  };

  useEffect(() => {
    // Load Chats
    socket.emit(
      "load-user-chats",
      {
        loggedInUserId: user?.data?.userId,
      },
      (chats) => {
        setChatUIState((prevState) => ({
          ...prevState,
          chatList: chats,
        }));
      }
    );

    // receive chats
    socket.on("chat-list", (chats) => {
      setChatUIState((prevState) => ({
        ...prevState,
        chatList: chats,
      }));
    });

    return () => {};
  }, [user?.data?.userId]);

  useEffect(() => {
    return () => {
      socket.off("new-messages");
      // socket.off("new-notification");
    };
  }, []);

  useEffect(() => {
    if (infoFromNotification) {
      loadChatFromNotificationsState(infoFromNotification);
    }
    // else if(infoFromClientList){

    // }
    return () => {};
  }, [infoFromNotification]);
  const filterMessages = (messages) => {
    const filteredMessages = [];
    const uniqueDates = {};

    messages.forEach((message, index) => {
      const date = new Date(message?.createdAt).toISOString().split("T")[0];

      if (index === 0 || !uniqueDates[date]) {
        uniqueDates[date] = true;
        filteredMessages.push({ dateSeparator: date });
      }

      filteredMessages.push(message);
    });

    return filteredMessages.reverse();
  };
  const formatDate = (date) => {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Adding leading zero if needed
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes} ${ampm}`;
  };
  useEffect(() => {
    if (count !== 0) {
      return;
    }

    if (targetMessageId) {
      const timeoutId = setTimeout(() => {
        const element = document.getElementById(
          `message-bubble-${targetMessageId}`
        );
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          element.classList.add("highlight");

          setTimeout(() => {
            element.classList.remove("highlight");
          }, 5000);
          setCount(1);
        }
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [targetMessageId, allMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollUpdater]);

  return (
    <>
      <div className="chat-coach user-profile">
        <div className="row">
          <div
            className="chat-client-header"
            style={{ justifyContent: "space-between" }}
          >
            <h1>Chats</h1>
            <Dropdown
              isOpen={chatUIState.chatDropdownOpen}
              toggle={() =>
                setChatUIState((prevState) => ({
                  ...prevState,
                  chatDropdownOpen: !prevState.chatDropdownOpen,
                }))
              }
              direction={"down"}
            >
              <Dropdown.Toggle
                style={{
                  marginLeft: "10px",
                  marginTop: "8px",
                  backgroundColor: "#1b47b4 !important",
                }}
              >
                {" "}
                New Chat
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleShowAddUserModal("addMultiUserCheck")}
                >
                  New Group Chat
                </Dropdown.Item>
                {/* <Dropdown.Item
                  onClick={() => handleShowAddUserModal("addSingleUserCheck")}
                >
                  New Chat
                </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Card
              style={{
                // height: "675px",
                height: "550px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {chatUIState.chatList.length === 0 ? (
                <h5
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    width: "100%",
                    top: "40%",
                  }}
                >
                  No Chats Yet.
                </h5>
              ) : (
                <>
                  {/* {chatUIState.chatList.map((chat, idx) => ( */}
                  {chatUIState.chatList
                    ?.sort(
                      (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
                    )
                    ?.map((chat, idx) => (
                      <div
                        key={idx}
                        className="chat-list-card w-100 d-flex align-items-center justify-content-start gap-3 p-3"
                        onClick={() => {
                          loadChat(chat);
                        }}
                        style={{
                          backgroundColor:
                            (chat?._id ===
                              chatUIState.selectedChatUser?.chatId?._id ||
                              chat?._id ===
                                chatUIState.selectedChatUser?._id) &&
                            "#1b47b4",
                          color:
                            (chat?._id ===
                              chatUIState.selectedChatUser?.chatId?._id ||
                              chat?._id ===
                                chatUIState.selectedChatUser?._id) &&
                            "#FFFFFF",
                          cursor: "pointer",
                        }}
                        // style={{ }}
                      >
                        {chat?.chatDetails?.imageUrl ? (
                          <img
                            className="coach-clients-data-img "
                            src={chat?.chatDetails?.imageUrl}
                            alt="img"
                          />
                        ) : (
                          <img
                            // src="/img/sample_img_2.png"
                            src={
                              !chat?.isGroup
                                ? "/img/sample_img_2.png"
                                : "/img/group-logo-placeholder.png"
                            }
                            className="coach-clients-data-img "
                            alt="Me"
                          />
                        )}

                        <p className="m-0 clients-name-chatlist">
                          <strong>
                            {chat?.chatDetails?.firstName}{" "}
                            {chat?.chatDetails?.lastName}
                          </strong>
                        </p>
                      </div>
                    ))}
                </>
              )}
            </Card>
          </div>
          {chatUIState.selectedChatUser?._id && !loading && (
            <div className="col-md-9">
              <Card
                style={{
                  // height: "675px"
                  height: "550px",
                }}
              >
                <Card.Header className="chat-client-username d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center  ">
                    {/* {!chatUIState.selectedChatUser?.chatId?.isGroup ? (
                      chatUIState.selectedChatUser?.senderId?.imageUrl ? (
                        <img
                          className="coach-clients-data-img"
                          src={
                            chatUIState.selectedChatUser?.senderId?.imageUrl
                          }
                          alt="img"
                        />
                      ) : (
                        <img
                          src={
                            !chatUIState.selectedChatUser?.isGroup ||
                            !chatUIState.selectedChatUser?.chatId?.isGroup
                              ? "/img/sample_img_2.png"
                              : "/img/group-logo-placeholder.png"
                          }
                          className="coach-clients-data-img"
                          alt="EQWell logo"
                        />
                      )
                    ) : null} */}
                    <p className="font-weight-bold mt-3 ml-2 ">
                      {chatUIState.selectedChatUser?.chatDetails?.firstName}{" "}
                      {chatUIState.selectedChatUser?.chatDetails?.lastName}
                    </p>
                    {chatUIState.selectedChatUser?.chatId?.isGroup && (
                      <p className="font-weight-bold mt-3 ml-2">
                        {chatUIState.selectedChatUser?.chatId?.name}
                      </p>
                    )}
                    {!chatUIState.selectedChatUser?.chatId?.isGroup && (
                      <p className="font-weight-bold mt-3 ml-2 ">
                        {chatUIState.selectedChatUser?.senderId?.firstName}{" "}
                        {chatUIState.selectedChatUser?.senderId?.lastName}
                      </p>
                    )}
                  </div>

                  {(chatUIState.selectedChatUser?.chatId?.isGroup ||
                    chatUIState.selectedChatUser?.isGroup) && (
                    <Dropdown
                      isOpen={chatUIState.chatDropdownOpen}
                      direction={"down"}
                    >
                      <Dropdown.Toggle variant="transparent"></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            showGroupMembers();
                          }}
                        >
                          Details
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setChatUIState((prev) => ({
                              ...prev,
                              addGroupMembersModal: true,
                              multipleUsers: [],
                            }));
                            fetchUsers();
                          }}
                        >
                          Add Member(s)
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Card.Header>
                <Card.Body className="card-body-chat">
                  <div className="">
                    <div className="chat-conversation ">
                      <ul className="list-unstyled">
                        <div
                          className="scrollbar"
                          style={{
                            height: "400px",
                          }}
                          ref={chatContainerRef}
                        >
                          {allMessages.map((item, index) => {
                            if (item.dateSeparator) {
                              return (
                                <li key={index}>
                                  <div className="separator">
                                    <div className="line"></div>
                                    <span>
                                      {moment(item.dateSeparator).format("ll")}
                                    </span>
                                    <div className="line"></div>
                                  </div>
                                </li>
                              );
                            } else {
                              const messageId = item._id;
                              return (
                                <li key={index} id={`message-${messageId}`}>
                                  <div className="conversation-list">
                                    <div className="">
                                      {item?.user?._id ===
                                      user?.data?.userId ? (
                                        <div
                                          className="sender-message-bubble media ml-auto "
                                          ref={myRef}
                                        >
                                          {chatUIState.selectedChatUser
                                            ?.isGroup && (
                                            <p
                                              style={{
                                                color: "grey",
                                                margin: "0px",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item?.user?.firstName}{" "}
                                              {item?.user?.lastName}
                                            </p>
                                          )}
                                          {chatUIState.selectedChatUser?.chatId
                                            ?._id && (
                                            <p
                                              style={{
                                                color: "grey",
                                                margin: "0px",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {item?.user?.firstName}{" "}
                                              {item?.user?.lastName}
                                            </p>
                                          )}
                                          <div className=" media-body">
                                            <div
                                              className="sender-msg py-2 px-3"
                                              id={`message-bubble-${messageId}`}
                                            >
                                              <p className="text-small mb-0 text-white text-justify">
                                                {item.text}
                                              </p>
                                            </div>
                                            <p className="message-time small text-muted d-flex">
                                              {formatDate(
                                                new Date(item.createdAt)
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="w-50">
                                          {chatUIState.selectedChatUser
                                            ?.isGroup && (
                                            <p
                                              style={{
                                                color: "grey",
                                                margin: "0px",
                                                fontSize: "12px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {item?.user?.firstName}{" "}
                                              {item?.user?.lastName}
                                            </p>
                                          )}
                                          {chatUIState.selectedChatUser?.chatId
                                            ?._id && (
                                            <p
                                              style={{
                                                color: "grey",
                                                margin: "0px",
                                                fontSize: "12px",
                                                textAlign: "left",
                                              }}
                                            >
                                              {item?.user?.firstName}{" "}
                                              {item?.user?.lastName}
                                            </p>
                                          )}
                                          <div className="media mb-3">
                                            <div className="reciever-message-bubble media-body ml-3">
                                              <div
                                                className="rcvr-msg py-2 px-3"
                                                id={`message-bubble-${messageId}`}
                                              >
                                                <p className="text-small mb-0 text-white text-justify">
                                                  {item.text}
                                                </p>
                                              </div>
                                              <p className="message-time small text-muted d-flex">
                                                {formatDate(
                                                  new Date(item.createdAt)
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </li>
                              );
                            }
                          })}
                        </div>
                      </ul>
                      <div className=" chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control chat-input"
                                placeholder="Enter Message..."
                                onChange={inputMessageHandler}
                                value={message}
                                onKeyDown={handleKeypress}
                                autoFocus
                              />
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              style={{ backgroundColor: "#1b47b4 !important" }}
                              className="btn  btn-rounded chat-send w-md "
                              onClick={onSendBtnHandler}
                              disabled={loadingOnSendBtn}
                            >
                              {loadingOnSendBtn ? (
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                <>
                                  <span className="d-none d-sm-inline-block me-2">
                                    Send
                                  </span>
                                  <i className="mdi mdi-send" />
                                </>
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
          {loading && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                height: "90%",
                width: "100%",
                alignItems: "center",
                // flexDirection: "column",
                justifyContent: "center",
                bottom: "10%",
              }}
            >
              <h6 style={{ marginTop: "10px" }}>
                Please wait chat is loading ...{" "}
              </h6>
            </div>
          )}
        </div>
      </div>

      {/* Users Modal For Creating Single Chat and Group Chat */}
      <Modal onHide={handleCloseAddUserModal} show={chatUIState.addUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {chatUIState.addSingleUserCheck
              ? "Create Chat"
              : "Create Group Chat"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {coachClientsData?.data?.length === 0 ? (
            <Spinner animation="grow" variant="primary" />
          ) : (
            <>
              {chatUIState.addSingleUserCheck ? (
                coachClientsData?.data?.map((clientData, idx) => (
                  <div
                    key={idx}
                    className="chat-list-card w-100 d-flex align-items-center justify-content-start gap-3 p-3"
                    style={{
                      ...(clientData?._id === chatUIState.singleUser && {
                        backgroundColor: "#1b47b4",
                        color: "#FFFFFF",
                      }),
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setChatUIState((prevState) => ({
                        ...prevState,
                        singleUser: clientData?._id,
                      }));
                    }}
                  >
                    {clientData?.imageUrl ? (
                      <img
                        className="coach-clients-data-img"
                        src={clientData?.imageUrl}
                        alt="img"
                      />
                    ) : (
                      <img
                        src="/img/sample_img_2.png"
                        className="coach-clients-data-img"
                        alt="EQWell logo"
                      />
                    )}

                    <p className=" clients-name-chatlist m-0">
                      <strong>
                        {clientData?.firstName} {clientData?.lastName}
                      </strong>
                    </p>
                  </div>
                ))
              ) : chatUIState.addMultiUserCheck ? (
                <>
                  <h5>Group Name</h5>
                  <input
                    type="text"
                    value={chatUIState.groupName}
                    onChange={(e) =>
                      setChatUIState((prev) => ({
                        ...prev,
                        groupName: e.target.value,
                      }))
                    }
                    maxLength={40}
                    minLength={1}
                    placeholder="Enter group name here..."
                    style={{
                      width: "100%",
                      border: "2px solid #0D6EFD",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  />
                  <hr />
                  <h5>Users</h5>
                  <div style={{ maxHeight: "60vh", overflowY: "auto" }}>
                    {coachClientsData?.data?.map((clientData, idx) => (
                      <div
                        key={idx}
                        className="chat-list-card w-100 d-flex align-items-center justify-content-start gap-3 p-3"
                        style={{
                          ...(chatUIState.multipleUsers.includes(
                            clientData?._id
                          ) && {
                            backgroundColor: "#1b47b4",
                            color: "#FFFFFF",
                          }),
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          if (
                            chatUIState.multipleUsers.includes(clientData?._id)
                          ) {
                            chatUIState.multipleUsers.splice(
                              chatUIState.multipleUsers.indexOf(
                                clientData?._id
                              ),
                              1
                            );
                            setChatUIState((prevState) => ({
                              ...prevState,
                              multipleUsers: chatUIState.multipleUsers,
                            }));
                          } else {
                            setChatUIState((prevState) => ({
                              ...prevState,
                              multipleUsers: [
                                ...prevState.multipleUsers,
                                clientData?._id,
                              ],
                            }));
                          }
                        }}
                      >
                        {clientData?.imageUrl ? (
                          <img
                            className="coach-clients-data-img"
                            src={clientData?.imageUrl}
                            alt="img"
                          />
                        ) : (
                          <img
                            src="/img/sample_img_2.png"
                            className="coach-clients-data-img"
                            alt="EQWell logo"
                          />
                        )}

                        <p className="clients-name-chatlist m-0">
                          <strong>
                            {clientData?.firstName} {clientData?.lastName}
                          </strong>
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddUserModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={createChat}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Show Group Members Modal */}
      <Modal
        onHide={handleCloseShowGroupMembersModal}
        show={chatUIState.showGroupMembersModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="clients-name-chatlist">
              {chatUIState.selectedChatUser?.name}
            </div>
            Group Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {groupMembers?.length === 0 ? (
            <h5 style={{ textAlign: "center" }}>
              No members have been added yet.
            </h5>
          ) : (
            <>
              {groupMembers?.map((member, idx) => (
                <div
                  key={idx}
                  className="chat-list-card w-100 d-flex align-items-center justify-content-start gap-3 p-3"
                >
                  {member?.imageUrl ? (
                    <img
                      className="coach-clients-data-img"
                      src={member?.imageUrl}
                      alt="img"
                    />
                  ) : (
                    <img
                      src="/img/sample_img_2.png"
                      className="coach-clients-data-img"
                      alt="EQWell logo"
                    />
                  )}

                  <p className="clients-name-chatlist m-0">
                    <strong>
                      {member?.firstName} {member?.lastName}
                    </strong>
                  </p>
                </div>
              ))}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseShowGroupMembersModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Group Members Modal */}
      <Modal
        onHide={handleCloseAddGroupMembersModal}
        show={chatUIState.addGroupMembersModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Members</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {coachClientsData?.data?.length === 0 ? (
            <Spinner animation="grow" variant="primary" />
          ) : (
            <>
              <h5>Users</h5>

              <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                {coachClientsData?.data?.map((clientData, idx) => (
                  <div
                    key={idx}
                    className="chat-list-card w-100 d-flex align-items-center justify-content-start gap-3 p-3"
                    style={{
                      ...(chatUIState.multipleUsers.includes(
                        clientData?._id
                      ) && {
                        backgroundColor: "#1b47b4",
                        color: "#FFFFFF",
                      }),
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      if (chatUIState.multipleUsers.includes(clientData?._id)) {
                        chatUIState.multipleUsers.splice(
                          chatUIState.multipleUsers.indexOf(clientData?._id),
                          1
                        );
                        setChatUIState((prevState) => ({
                          ...prevState,
                          multipleUsers: chatUIState.multipleUsers,
                        }));
                      } else {
                        setChatUIState((prevState) => ({
                          ...prevState,
                          multipleUsers: [
                            ...prevState.multipleUsers,
                            clientData?._id,
                          ],
                        }));
                      }
                    }}
                  >
                    {clientData?.imageUrl ? (
                      <img
                        className="coach-clients-data-img"
                        src={clientData?.imageUrl}
                        alt="img"
                      />
                    ) : (
                      <img
                        src="/img/sample_img_2.png"
                        className="coach-clients-data-img"
                        alt="EQWell logo"
                      />
                    )}

                    <p className="clients-name-chatlist m-0">
                      <strong>
                        {clientData?.firstName} {clientData?.lastName}
                      </strong>
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddGroupMembersModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={addGroupMembers}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChatClient;
