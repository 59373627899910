import React, { useState, useEffect, useRef } from "react";
import { Card, Tabs, Tab, Form, Spinner, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import BooleanQstn from "../../../components/BooleanQstn";
import Checkqstn from "../../../components/Checkqstn";
import * as actionTypes from "../../../redux/actions/actionType";
import { ToastContainer } from "react-toastify";
import Detail from "../../../components/Detail";
import { useLocation, useNavigate } from "react-router-dom";
import ChatCoach from "../../../components/ChatCoach";
import MultiCheckQstn from "../../../components/MultiCheckQstn";
import { toastify } from "../../../components/Toast";
import { socketFun } from "../../../constants/socket";
import { Oval } from "react-loader-spinner";
import { BarChart } from "@mui/x-charts";
import SetGoals from "./SetGoals";
import usePreventDoubleEffect from "../../../constants/hooks/usePreventDoubleEffect";
const Survey = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [key, setKey] = useState("home");
  const [postData, setPostData] = useState([]);
  const [loaderForDetails, setLoaderForDetails] = useState(false);
  const [loaderForAllDetails, setLoaderForAllDetails] = useState(false);
  const [loaderForReport, setLoaderForReport] = useState(false);
  const [loaderForGoals, setLoaderForGoals] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [showDetails, setShowDetails] = useState("");
  const [loaderForNextBtn, setloaderForNextBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderForRetakeBtn, setLoaderForRetakeBtn] = useState(false);
  const [checkedGoals, setCheckedGoals] = useState([]);
  const [loaderForJourneyData, setLoaderForJourneyData] = useState(false);
  const [currentJourney, setCurrentJourney] = useState(null);
  const [selectedQuestionCount, setSelectedQuestionCount] = useState(0);
  const [showButton, setShowButton] = useState(true);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const { user } = useSelector((state) => state?.authentication);
  usePreventDoubleEffect(() => {
    socketFun(user?.data?.userId, setIsLoading);
    setIsLoading(false);
    dispatch(authActions.getSurveySuggestion());
    dispatch(authActions.getSurveyRequest());
  });
  useEffect(() => {
    if (user) {
      dispatch(
        authActions.getJourneysData(callBackForJourneyData, user?.data?.userId)
      );
      setLoaderForJourneyData(true);
    }
  }, [user]);
  const callBackForJourneyData = (res) => {
    if (res) setLoaderForJourneyData(false);
  };
  const { suggestedSurvey, journeysData } = useSelector(
    (state) => state?.getQuestion
  );
  useEffect(() => {
    if (journeysData?.data) {
      const activeAttempt = journeysData?.data?.find(
        (journey) => journey.isActive === true
      );
      setCurrentJourney(activeAttempt);
    }
  }, [journeysData?.data]);

  useEffect(() => {
    if (location?.state?.page === "goalsPage") {
      handleSelectTab("goals");
    }
  }, [location?.state?.page === "goalsPage"]);

  const { questions, reports, score, goals } = useSelector(
    (state) => state.getQuestion
  );

  useEffect(() => {
    if (
      suggestedSurvey?.data?.length > 0 &&
      suggestedSurvey.data[0]?.isFirstAttempt
    ) {
      dispatch(
        authActions.selectSurveyType(
          { surveyType: "Full", journeyAttempt: "1", subsetGoals: [] },
          callBackForFirstSurvey
        )
      );
    }
    if (
      suggestedSurvey?.data?.length > 0 &&
      suggestedSurvey?.data[0]?.surveyType != null
    ) {
      dispatch(authActions.getSurveyRequest());
    }
  }, [suggestedSurvey]);

  useEffect(() => {
    dispatch(authActions.getSurveySuggestion());
    // Update the showButton state based on survey completion on initial render
    if (key === "home") {
      setShowButton(questions?.data?.surveyCompleted === true);
    }
    if (key === "reports" && questions?.data?.surveyCompleted === true) {
      setLoaderForReport(true);
      dispatch(authActions.getReportRequest(setLoaderForReport));
    }
    if (key === "details" && questions?.data?.surveyCompleted === true) {
      setLoaderForAllDetails(true);
      dispatch(authActions.getScoreRequest(setLoaderForAllDetails));
    }
    if (key === "goals" && questions?.data?.surveyCompleted === true) {
      setLoaderForGoals(true);
      dispatch(authActions.getGoalsRequest(setLoaderForGoals));
    }
    // if (questions?.data?.surveyCompleted === true) {
    //   dispatch(authActions.getReportRequest());
    //   dispatch(authActions.getScoreRequest());
    //   dispatch(authActions.getGoalsRequest());
    // }
  }, [key, questions?.data?.surveyCompleted]);
  useEffect(() => {}, [reports, score, goals]);
  useEffect(() => {
    const userSelectedGoals = goals?.data?.goals.reduce((result, goal) => {
      if (goal.selected === true) {
        result = [...result, goal._id];
        return result;
      }
      return result;
    }, []);
    if (userSelectedGoals !== undefined) {
      if (userSelectedGoals.length !== 0) {
        setCheckedGoals(userSelectedGoals);
      }
    }
  }, [goals]);
  const callBackForFirstSurvey = () => {
    dispatch(authActions.getSurveyRequest());
  };
  const handleNextTab = () => {
    const tabOrder = ["home", "reports", "details", "goals"];
    const currentIndex = tabOrder.indexOf(key);

    const nextTab = tabOrder[(currentIndex + 1) % tabOrder.length];

    setShowButton(nextTab !== "goals");

    setKey(nextTab);
  };

  // const handleSelectTab = (selectedKey) => {
  //   // Update the showButton state based on the selected tab
  //   setShowButton(selectedKey !== 'goals');

  //   // Set the selected tab as the active tab
  //   setKey(selectedKey);
  // };

  const handleSelectTab = (selectedKey) => {
    setShowButton(
      selectedKey !== "goals" &&
        (selectedKey !== "home" ||
          (selectedKey === "home" && questions?.data?.surveyCompleted))
    );

    setKey(selectedKey);
  };

  const updateData = (obj) => {
    // let ddd = questions.data.questions.findIndex(
    //   (ele) => ele._id === obj.questionId
    // );
    // if (ddd === -1) {
    //   return;
    // }

    let findexFound = postData.findIndex(
      (ele) => ele.questionId === obj.questionId
    );
    if (findexFound !== -1) {
      postData[findexFound].selectedOption = obj.selectedOption;

      setPostData(postData);
    } else {
      let question = questions.data.questions.find(
        (ele) => ele._id === obj.questionId
      );
      if (question !== undefined) {
        setSelectedQuestionCount(selectedQuestionCount + 1);
      }
      postData.push(obj);
    }
  };
  // const goalApi = () => {
  //   dispatch(authActions.getGoalsRequest());
  //   dispatch(authActions.getReportRequest());
  //   dispatch(authActions.getScoreRequest());
  // };

  const saveQuestions = () => {
    setloaderForNextBtn(true);
    let obj = {
      latestQuestions: postData,
    };
    const totalQuestions = questions.data.questions.filter(
      (item) => item.options.values.length !== 0
    );

    if (selectedQuestionCount === questions.data.questions.length) {
      dispatch(authActions.postSurveyRequest(obj, callbackForSurveyQuestion));
      setPostData([]);
      // setData(true);
    } else {
      toastify("error", "Please answer all of the questions");
      setloaderForNextBtn(false);
    }
  };
  const callbackForSurveyQuestion = (res) => {
    dispatch(authActions.getSurveySuggestion());
    setSelectedQuestionCount(0);
    setPostData([]);

    dispatch({ type: actionTypes.GET_QUESTION_DATA, payload: [] });
    setTimeout(() => {
      setLoaderForGoals(true);
      dispatch(authActions.getSurveyRequest());
      dispatch(authActions.getReportRequest(setLoaderForReport));
      dispatch(authActions.getScoreRequest(setLoaderForAllDetails));
      dispatch(authActions.getGoalsRequest(setLoaderForGoals));
    }, 2000);
    setloaderForNextBtn(false);

    setApiCall(true);
  };

  const DetailCard = (props) => {
    return (
      <div className="col-md-4 pt-3">
        <Card onClick={props.onClick}>
          <div className="text-center py-3 card-container">
            <img src={props.src} />
            <h3 className="py-3 font-weight-bold">{props.label}</h3>
            <div className="d-flex justify-content-center">
              <img className="mb-3" src={props.scoreimg} />
              <span className="mx-2">Score:</span>
              <p className="font-weight-bold">{props.score}%</p>
              <span className="mx-2">Journey:</span>
              <p className="font-weight-bold">{props.journey}</p>
            </div>
          </div>
        </Card>
      </div>
    );
  };
  const getDetail = (detailConst, title, journey) => {
    setShowDetails(title);
    setLoaderForDetails(true);
    dispatch(
      authActions.getDetailRequest(
        detailConst,
        callBackForDetails,
        setLoaderForDetails,
        journey
      )
    );
  };
  const callBackForDetails = (res) => {
    if (res) {
      setLoaderForDetails(false);
    }
  };

  const handleCardSelect = (card, goals) => {
    setSelectedCard(card);
    setSelectedOption({
      surveyType: card,
      journeyAttempt: currentJourney?.attempt,
      subsetGoals: goals,
    });
  };
  const handleRetakeSurveyOnCardSelect = () => {
    if (selectedOption) {
      setLoaderForRetakeBtn(true);
      dispatch(
        authActions.selectSurveyType(
          selectedOption,
          callBackForSelectSurvey,
          setLoaderForRetakeBtn
        )
      );
    } else {
      toastify("error", "Kindly select option to retake survey");
    }
  };
  const callBackForSelectSurvey = (res) => {
    if (res) {
      dispatch(authActions.getSurveySuggestion());

      setTimeout(() => {
        dispatch(authActions.getSurveyRequest(callBackForSurveyRequest));
      }, 2000);
    }
  };
  const callBackForSurveyRequest = (res) => {
    if (res) dispatch(authActions.getSurveySuggestion());
    setLoaderForRetakeBtn(false);
  };
  // Transform the data
  const uData = reports?.data?.result?.map((item) => item.y_axis); // Y-axis data (scores)
  const xLabels = reports?.data?.result?.map(
    (item) => `Journey ${item.x_axis}`
  );
  const baseWidthPerBar = 50; // Width per bar in pixels
  const calculatedWidth = uData?.length * baseWidthPerBar;
  const chartWidth = Math.max(calculatedWidth, 300);
  const scrollRef = useRef(null);

  // Use effect to scroll to the top whenever questions data changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [questions]);
  return (
    <>
      <div className="survey px-5">
        {/* <ChatCoach/> */}

        <Card>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleSelectTab}
            // onClick={goalApi}
          >
            {/* survey */}
            <Tab eventKey="home" title="Take Survey">
              {suggestedSurvey?.data?.length > 0 &&
              suggestedSurvey?.data[0]?.surveyType != null &&
              !suggestedSurvey?.data[0]?.isFirstAttempt ? (
                <div>
                  {loaderForJourneyData ? (
                    <div
                      className="loader-container "
                      style={{ margin: "5% 0 5% 0" }}
                    >
                      <Oval
                        height={30}
                        width={30}
                        color="#1b46b4"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#56bcb2"
                        strokeWidth={6}
                        strokeWidthSecondary={6}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="survey-card-container-heading">
                        Select Survey Type
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {suggestedSurvey?.data[0]?.surveyType === "Full" && (
                          <div
                            className="survey-cards-container"
                            onClick={() =>
                              handleCardSelect(
                                "Full",
                                suggestedSurvey?.data[0]?.userActiveGoals
                              )
                            }
                            style={{
                              borderColor:
                                selectedCard === "Full" ? "#1b47b4" : "#dfe3e6",
                            }}
                          >
                            <div>
                              <img
                                src="/img/retake-survey.svg"
                                alt="retake-survey"
                              />
                            </div>
                            <div className="survey-cards-title">
                              {" "}
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "28px",
                                }}
                              >
                                Full Retake
                              </p>{" "}
                              <p>Complete survey on all topics.</p>{" "}
                            </div>
                          </div>
                        )}
                        {suggestedSurvey?.data[1]?.surveyType === "Sub_Set" && (
                          <div
                            className="survey-cards-container"
                            onClick={() =>
                              handleCardSelect(
                                "Sub_Set",
                                suggestedSurvey?.data[1]?.userActiveGoals
                              )
                            }
                            style={{
                              borderColor:
                                selectedCard === "Sub_Set"
                                  ? "#1b47b4"
                                  : "#dfe3e6",
                            }}
                          >
                            <div>
                              <img
                                src="/img/subset-retake.svg"
                                alt="subset-retake"
                              />
                            </div>
                            <div className="survey-cards-title">
                              {" "}
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "28px",
                                }}
                              >
                                Subset Retake
                              </p>{" "}
                              <p>
                                A focused survey on goals with progress below
                                90%
                              </p>{" "}
                              {suggestedSurvey?.data[1]?.userActiveGoals &&
                                suggestedSurvey?.data[1]?.userActiveGoals.map(
                                  (goals, index) => (
                                    <div key={index}>
                                      <li>{goals?.title}</li>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        )}
                      </div>

                      <Button
                        style={{
                          marginTop: "20px",
                          backgroundColor: "#1b47b4",
                          marginBottom: "5%",
                        }}
                        onClick={handleRetakeSurveyOnCardSelect}
                        disabled={loaderForRetakeBtn}
                      >
                        {loaderForRetakeBtn ? (
                          <Spinner
                            className="mx-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          "Retake"
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              ) : questions?.data?.questions.length > 0 ? (
                <div
                  className="main-content py-2 px-5 take-survey-tab"
                  ref={scrollRef}
                >
                  {questions &&
                    questions?.data?.questions.map((question, index) =>
                      question.options.optiontype === "boolean" ? (
                        <BooleanQstn
                          key={index}
                          data={question}
                          questionId={question._id}
                          value={question.options.values}
                          question={question.questionText}
                          updateData={updateData}
                        />
                      ) : question.options.optiontype === "checkBoxes" ? (
                        <MultiCheckQstn
                          key={index}
                          data={question}
                          questionId={question._id}
                          question={question.questionText}
                          updateData={updateData}
                        />
                      ) : (
                        <Checkqstn
                          key={index}
                          data={question}
                          questionId={question._id}
                          value={question.options.values}
                          question={question.questionText}
                          updateData={updateData}
                        />
                      )
                    )}

                  {questions?.data?.surveyCompleted === false && (
                    <button
                      className="mt-5 mb-5 py-1 px-4 btn-send-question"
                      onClick={() => saveQuestions()}
                      disabled={loaderForNextBtn}
                    >
                      {" "}
                      {loaderForNextBtn ? (
                        <Spinner
                          className=""
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  )}
                </div>
              ) : questions?.data?.questions.length === 0 &&
                questions?.data?.surveyCompleted === true &&
                suggestedSurvey?.data?.[0]?.isSurveyCompleted ? (
                <div className="mt-5 mb-5">
                  <h5>Survey Completed</h5>
                </div>
              ) : (
                <div className="loader-container pt-4">
                  <Oval
                    height={30}
                    width={30}
                    color="#1b46b4"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#00BFB2"
                    strokeWidth={6}
                    strokeWidthSecondary={6}
                  />
                </div>
              )}
            </Tab>
            {/* reports */}
            <Tab eventKey="reports" title="Report">
              <div className="reports py-4 px-4">
                <div>
                  {questions?.data?.surveyCompleted ? (
                    loaderForReport ? (
                      <div className="loader-container pt-4">
                        <Oval
                          height={30}
                          width={30}
                          color="#1b46b4"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#00BFB2"
                          strokeWidth={6}
                          strokeWidthSecondary={6}
                        />
                      </div>
                    ) : (
                      <>
                        {reports?.data ? (
                          <>
                            <h3
                              className="d-flex font-weight-bold"
                              style={{ color: "#0047bb" }}
                            >
                              Report :
                            </h3>
                            <p className="text-justify font-weight-bold">
                              {reports?.data?.reportResult?.riskText}
                            </p>
                            <p className="text-justify">
                              {reports?.data?.reportResult?.Desc}
                            </p>

                            {/*  BarChart  */}
                            <div style={{ width: `${chartWidth}px` }}>
                              <BarChart
                                width={chartWidth}
                                height={300}
                                series={[
                                  {
                                    data: uData,
                                    backgroundColor: "#4CAF50", // Customize the bar color
                                  },
                                ]}
                                xAxis={[
                                  {
                                    data: xLabels,
                                    scaleType: "band",
                                    categoryGapRatio: 0.5,
                                    barGapRatio: 0.8,
                                  },
                                ]}
                                yAxis={[
                                  {
                                    min: 0, // Minimum value on y-axis
                                    max: 100, // Maximum value on y-axis
                                  },
                                ]}
                                options={{
                                  maintainAspectRatio: true,
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <div className="loader-container pt-4">
                            <Oval
                              height={30}
                              width={30}
                              color="#1b46b4"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="#00BFB2"
                              strokeWidth={6}
                              strokeWidthSecondary={6}
                            />
                          </div>
                        )}
                      </>
                    )
                  ) : (
                    <div className="mt-4 mb-5">
                      <p>Please complete your survey </p>
                    </div>
                  )}
                </div>
              </div>
            </Tab>
            {/* details */}
            <Tab eventKey="details" title="Details">
              <div className="details py-5 px-4">
                <div className="row ">
                  {questions?.data?.surveyCompleted ? (
                    <>
                      {loaderForAllDetails ? (
                        <div className="loader-container pt-4">
                          <Oval
                            height={30}
                            width={30}
                            color="#1b46b4"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#00BFB2"
                            strokeWidth={6}
                            strokeWidthSecondary={6}
                          />
                        </div>
                      ) : (
                        <>
                          {showDetails ? (
                            loaderForDetails ? (
                              <>
                                <div className="loader-container pt-4">
                                  <Oval
                                    height={30}
                                    width={30}
                                    color="#1b46b4"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel="oval-loading"
                                    secondaryColor="#00BFB2"
                                    strokeWidth={6}
                                    strokeWidthSecondary={6}
                                  />
                                </div>
                              </>
                            ) : (
                              <Detail
                                title={showDetails}
                                setShowDetails={setShowDetails}
                              />
                            )
                          ) : (
                            <>
                              <DetailCard
                                src="/img/physical.png"
                                label="Physical Health"
                                scoreimg="/img/gscr.svg"
                                score={
                                  score?.data?.recentJourneyDomains?.physical
                                    ?.score
                                }
                                journey={
                                  score?.data?.recentJourneyDomains?.physical
                                    ?.journey
                                }
                                onClick={() =>
                                  getDetail(
                                    "physical",
                                    "Physical Health",
                                    score?.data?.recentJourneyDomains?.physical
                                      ?.journey
                                  )
                                }
                                showDetails={showDetails}
                              />
                              <DetailCard
                                src="/img/Emotional Health.png"
                                label="Emotional Health"
                                scoreimg="/img/gscr.svg"
                                score={
                                  score?.data?.recentJourneyDomains?.emotional
                                    ?.score
                                }
                                journey={
                                  score?.data?.recentJourneyDomains?.emotional
                                    ?.journey
                                }
                                onClick={() =>
                                  getDetail(
                                    "emotional",
                                    "Emotional Health",
                                    score?.data?.recentJourneyDomains?.emotional
                                      ?.journey
                                  )
                                }
                              />
                              <DetailCard
                                src="/img/healthy.png"
                                label="Healthy Behavior"
                                scoreimg="/img/gscr.svg"
                                score={
                                  score?.data?.recentJourneyDomains
                                    ?.healthyBehaviour?.score
                                }
                                journey={
                                  score?.data?.recentJourneyDomains
                                    ?.healthyBehaviour?.journey
                                }
                                onClick={() =>
                                  getDetail(
                                    "healthyBehaviour",
                                    "Healthy Behavior",
                                    score?.data?.recentJourneyDomains
                                      ?.healthyBehaviour?.journey
                                  )
                                }
                              />
                              <DetailCard
                                src="/img/Work Environment.png"
                                label="Work Environment"
                                scoreimg="/img/gscr.svg"
                                score={
                                  score?.data?.recentJourneyDomains
                                    ?.workEnvironment?.score
                                }
                                journey={
                                  score?.data?.recentJourneyDomains
                                    ?.workEnvironment?.journey
                                }
                                onClick={() =>
                                  getDetail(
                                    "workEnvironment",
                                    "Work Environment",
                                    score?.data?.recentJourneyDomains
                                      ?.workEnvironment?.journey
                                  )
                                }
                              />
                              <DetailCard
                                src="/img/Basic Access.png"
                                label="Basic Access"
                                scoreimg="/img/gscr.svg"
                                score={
                                  score?.data?.recentJourneyDomains?.basicAccess
                                    ?.score
                                }
                                journey={
                                  score?.data?.recentJourneyDomains?.basicAccess
                                    ?.journey
                                }
                                onClick={() =>
                                  getDetail(
                                    "basicAccess",
                                    "Basic Access",
                                    score?.data?.recentJourneyDomains
                                      ?.basicAccess?.journey
                                  )
                                }
                              />
                              <DetailCard
                                src="/img/evaluate.png"
                                label="Life Evaluation"
                                scoreimg="/img/gscr.svg"
                                score={
                                  score?.data?.recentJourneyDomains
                                    ?.lifeEvaluation?.score
                                }
                                journey={
                                  score?.data?.recentJourneyDomains
                                    ?.lifeEvaluation?.journey
                                }
                                onClick={() =>
                                  getDetail(
                                    "lifeEvaluation",
                                    "Life Evaluation",
                                    score?.data?.recentJourneyDomains
                                      ?.lifeEvaluation?.journey
                                  )
                                }
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="mt-4 mb-5">
                      {" "}
                      <p>Please complete your survey </p>
                    </div>
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey="goals" title="Set Goals">
              <>
                {questions?.data?.surveyCompleted ? (
                  <>
                    {!loaderForGoals ? (
                      <SetGoals data={goals?.data} />
                    ) : (
                      <div className="loader-container pt-4">
                        <Oval
                          height={30}
                          width={30}
                          color="#1b46b4"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#00BFB2"
                          strokeWidth={6}
                          strokeWidthSecondary={6}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="mt-5">
                    <p>Please complete your survey</p>
                  </div>
                )}
              </>
            </Tab>
          </Tabs>
        </Card>
        {showButton && (
          <Button className="btnfornext" onClick={handleNextTab}>
            Next
          </Button>
        )}
      </div>
    </>
  );
};
export default Survey;
