import * as actionTypes from "../actions/actionType";

const initialState = {
  coachActivityNewsFeedData: [],
};

export const coachActivityNewsFeedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COACH_ACTIVITY_NEWS_FEED:
      return {
        ...state,
        coachActivityNewsFeedData: action.payload,
      };

    default:
      return state;
  }
};
