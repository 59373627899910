import * as actionTypes from "../actions/actionType";

const initialState = {
  user: [],
  token: "",
  toggleChatCoach: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_SIGNUP:
      return {
        ...state,
        user: action.payload,
        token: action.payload.token,
      };
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        token: action.payload.token,
      };
    case actionTypes.USER_LOGOUT:
      return initialState; // Reset the state to its initial values

    case actionTypes.TOGGLE_CHAT_COACH:
      return { ...state, toggleChatCoach: action.payload };

    default:
      return state;
  }
};
