import axios from "axios";
import { store } from "../redux/store/index";
import * as Sentry from "@sentry/react";

const baseURL = process.env.REACT_APP_API_URL;
const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};
const handleErrors = (err) => {
  if (err.response) {
    const { status } = err.response;
    if (![200, 201, 400, 403].includes(status)) {
      Sentry.captureException(err);
    }
  } else {
    Sentry.captureException(err);
  }
};
const makePostRequest = (URL, data, multiPartBody) => {
  return new Promise((resolve, reject) => {
    const globalState = store.getState();
    config.headers[
      "Authorization"
    ] = `Bearer ${globalState?.authentication?.user?.data?.token}`;
    if (multiPartBody) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (!multiPartBody || multiPartBody === undefined) {
      config.headers["Content-Type"] = "application/json";
    }

    axios
      .post(baseURL + URL, multiPartBody ? data : { ...data }, config)

      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // store.dispatch({type: useroCnstants.LOGOUT, user: null})
          return;
        } else {
          handleErrors(err);
          reject(err);
        }
      });

    return baseURL;
  });
};

const makeGetRequest = (URL) => {
  return new Promise((resolve, reject) => {
    const globalState = store.getState();
    config.headers[
      "Authorization"
    ] = `Bearer ${globalState?.authentication?.user?.data?.token}`;
    axios
      .get(baseURL + URL, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // store.dispatch({type: userConstants.LOGOUT, user: null})
          return;
        } else {
          handleErrors(err);
          reject(err);
        }
      });
  });
};

const makePutRequest = (URL, data) => {
  return new Promise((resolve, reject) => {
    const globalState = store.getState();
    config.headers["Authorization"] =
      globalState?.authentication?.user?.data?.token;
    axios
      .put(baseURL + URL, data, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // store.dispatch({type: userConstants.LOGOUT, user: null})
          return;
        } else {
          handleErrors(err);
          reject(err);
        }
      });
  });
};

const makeDeleteRequest = (URL) => {
  return new Promise((resolve, reject) => {
    const globalState = store.getState();
    config.headers[
      "Authorization"
    ] = `Bearer ${globalState?.authentication?.user?.data?.token}`;
    axios
      .delete(baseURL + URL, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // store.dispatch({type: userConstants.LOGOUT, user: null})
          return;
        } else {
          handleErrors(err);
          reject(err);
        }
      });
  });
};
const makePatchRequest = (URL, data) => {
  return new Promise((resolve, reject) => {
    const globalState = store.getState();
    config.headers[
      "Authorization"
    ] = `Bearer ${globalState?.authentication?.user?.data?.token}`;
    config.headers["Content-Type"] = "application/json";
    axios
      .patch(baseURL + URL, { ...data }, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          // store.dispatch({type: userConstants.LOGOUT, user: null})
          return;
        } else {
          handleErrors(err);
          reject(err);
        }
      });
  });
};
export {
  makeGetRequest,
  makePostRequest,
  makeDeleteRequest,
  makePutRequest,
  makePatchRequest,
};
