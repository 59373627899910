import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="terms-and-conditions-navbar ">
        <img src="/img/navbarlog.png" className="align-top" alt="EQWell logo" />
      </div>
      <div className="terms-and-conditions-container row">
        <h1>EqWell™ Terms of Use</h1>
      </div>
      <div className="terms">
        <>
          <p
            style={{
              marginBottom: "0.19in",
              fontSize: "16px",
              lineHeight: "100%",
              textAlign: "left",
              background: "transparent",
            }}
          >
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">Last Updated: October 18, 2022</span>
            </span>
          </p>
          <p
            style={{
              marginBottom: "0.19in",
              fontSize: "16px",
              lineHeight: "100%",
              textAlign: "left",
              background: "transparent",
            }}
          >
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                EqWell, Inc., a Delaware corporation, and its subsidiaries
                (collectively, “
              </span>
            </span>
            <strong>
              <span style={{ color: "rgb(68, 68, 68)" }}>
                <span lang="en">EqWell</span>
              </span>
            </strong>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                ”) welcome you to our website or services, including the
                services of independent service and wellness providers whom you
                can engage and compensate using our web site and by other means
                (collectively, “
              </span>
            </span>
            <strong>
              <span style={{ color: "rgb(68, 68, 68)" }}>
                <span lang="en">Services</span>
              </span>
            </strong>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">”). Please read these Terms of Use (“</span>
            </span>
            <strong>
              <span style={{ color: "rgb(68, 68, 68)" }}>
                <span lang="en">Terms</span>
              </span>
            </strong>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                ”) and our Privacy Policy carefully because they affect your
                legal rights, including your right to resolve any disputes that
                may arise between us. Subject to your compliance with these
                terms, you may access and use the Services. EqWell members may
                access the Services to establish a user account and to access
                other EqWell materials.&nbsp;
              </span>
            </span>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                <span style={{ fontWeight: "normal" }}>Use of the</span>
              </span>
            </span>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                <strong>&nbsp;</strong>
              </span>
            </span>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                <span style={{ fontWeight: "normal" }}>
                  Services, and acceptance of this Agreement, do not, without
                  more, establish a membership with EqWell or entitle you to any
                  goods or services
                </span>
              </span>
            </span>
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                <strong>.</strong>
              </span>
            </span>
          </p>
          <ol>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">How this Agreement Works</span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Terms and Conditions</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . By visiting, accessing, or using any part of our
                        Services, you expressly accept, agree, and consent to
                        the terms and conditions of this Agreement. Your
                        continued use of the Services after any modification to
                        the Services or update to this Agreement constitutes
                        your conclusive acceptance of such change. If you do not
                        agree to these terms and conditions of use, you must not
                        use any portion of the Services, and your sole remedy is
                        to discontinue your use of the Services.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Additional Terms.&nbsp;</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        To the extent EqWell provides you notice of different or
                        additional terms or practices, those additional terms
                        will govern your use of the Services.&nbsp;
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Privacy</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . By visiting, accessing, or using any part of the
                        Services, you accept the practices described in EqWell’s
                        Privacy Policy.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Eligibility</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . You may only use the Services if you are at least
                        eighteen (18) years of age.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Updates</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . EqWell may modify this Agreement at any time. Such
                        modification will become effective immediately upon
                        either posting of the modified agreement or EqWell’s
                        notification of users. You may locate the most current
                        version of this Agreement at EqWell’s web site.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Modification and Termination</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . EqWell operates the Services in its sole and complete
                        discretion. EqWell may modify the Services at any time
                        for any reason, including changes to features, content,
                        functionality, or software. EqWell may suspend all or
                        any portion of the Services, including for periodic
                        maintenance, equipment malfunctions or causes beyond
                        EqWell's control. EqWell may also terminate the Services
                        in entirety. EqWell is not liable for any such
                        modification, suspension, or termination of the
                        Services.
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">Use of Service</span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Copyright and Trademarks</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . All content hosted on the Services is copyrighted
                        under the law as collective work and/or compilation.
                        EqWell holds and owns the copyright, and all other
                        proprietary and intellectual property rights and title,
                        to the collective work, including the pages, graphics,
                        design, text, sound recordings, images, materials, and
                        content of the Services, some of which may be
                        trademarked. You agree not to copy, reproduce, alter,
                        modify, decompile, reverse engineer, distribute,
                        transfer, sell, transmit, translate, publish, perform,
                        license, or create derivative works from the content of
                        the Services, including any information, products, or
                        services, without EqWell's express written consent. The
                        Services are solely for your personal, non-commercial
                        use. Subject to these and any other express restrictions
                        with regard to specific material, you may download
                        information concerning EqWell services or membership
                        from the Services solely for your personal
                        non-commercial use, including information concerning
                        membership type, status, enrollment date, contact
                        information, usage of EqWell facilities, transactions,
                        packages and/or payment accounts.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Health and Wellness Information</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . The Services contain information about leading a
                        healthy way of life, including information on health,
                        wellness, and fitness issues. Such information does not
                        represent medical diagnosis, treatment, or care and
                        should not be used in place of consultation with or
                        advice from your healthcare professionals. You should
                        consult your physician or healthcare professional before
                        beginning or altering your personal exercise, diet,
                        lifestyle, or nutritional supplement program. Any health
                        information you post on a discussion board or forum
                        maintained by EqWell may be viewed by other users of the
                        Services.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Electronic Communications</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . When you use the Services, you communicate
                        electronically with EqWell. You expressly consent to
                        receipt of electronic communications from EqWell through
                        posts on the Services and via the email address and
                        telephone number you provided when setting up a user
                        account. All agreements, notices, disclosures,
                        authorizations, verifications, confirmations, or other
                        electronic communications EqWell provides according to
                        this paragraph satisfy any legal requirement for written
                        communication.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Equipment</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . You are solely responsible for purchasing,
                        maintaining, and ensuring all equipment, programs, and
                        services necessary for, and compatible with, your access
                        to and use of the Services, including hardware and
                        software; electrical, telecommunications and internet
                        access connections and links; and web browsers.
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">User Content</span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Your Content</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . You retain all rights, responsibility, and ownership
                        of your content. You may only post user content that you
                        created or have permission to use. EqWell is not
                        responsible for any User Content posted on the Services,
                        nor does it control the content made available by users.
                        EqWell makes no representations or warranties concerning
                        efforts to review or monitor User Content, including the
                        extent to which it may be considered or construed as
                        personal, private, inappropriate, inaccurate, offensive,
                        profane, misleading or otherwise objectionable. While
                        EqWell does not pre-approve or pre-screen User Content,
                        EqWell may disable, remove, or delete any User Content.
                        EqWell is not liable, directly or indirectly, for any
                        damage or loss caused or alleged to be caused by or in
                        connection with your use of, or reliance upon, any such
                        User Content.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">License to User Content</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . By posting, including on discussion boards or forums,
                        submitting, transmitting, emailing, messaging,
                        uploading, sharing, or otherwise making available
                        (hereinafter “
                      </span>
                    </span>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">post</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">” or “</span>
                    </span>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">posting</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        ”) any messages, text, files, images, video, photos,
                        sounds, or other materials on or through the Services (“
                      </span>
                    </span>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">User Content</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        ”), you grant EqWell an irrevocable, perpetual, fully
                        paid, non-exclusive worldwide right to use, reproduce,
                        display, distribute, adapt, perform and prepare
                        derivative works of the User Content on the Services.
                        EqWell does not need any further approval for its uses
                        of your User Content.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          Third Party Content, Services, Applications, and Tools
                        </span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . The Services may include links to, or content from,
                        third-party websites, resources, services, applications,
                        or tools (“
                      </span>
                    </span>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Third Party Content</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        ”). If you choose to use or view such Third Party
                        Content, your Personal Information may be disclosed to
                        third parties. These third-party providers may request
                        that you agree to separate privacy policies and terms of
                        use that are different than EqWell’s terms. You should
                        read any such policies before using or viewing Third
                        Party Content. EqWell does not control and is not
                        responsible or liable for Third Party Content. EqWell
                        makes no representations or warranties concerning Third
                        Party Content, including the extent to which it may be
                        considered or construed as inappropriate, inaccurate,
                        offensive, misleading, or otherwise objectionable.
                        EqWell is not liable, directly or indirectly, for any
                        damage or loss caused or alleged to be caused by or in
                        connection with the use of, or reliance upon, any such
                        Third Party Content.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          Copyright Infringement Notification
                        </span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . Pursuant to the Digital Millennium Copyright Act (“
                      </span>
                    </span>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">DMCA</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">”), EqWell’s designated agent (“</span>
                    </span>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Copyright Agent</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        ”) receives notices of claimed copyright infringement.
                        If you, or an agent authorized to act on your behalf,
                        believe in good faith that your work is on the Services
                        in a way that constitutes copyright infringement, please
                        provide written notice to EqWell's Copyright Agent by
                        email at the address specified below, Attn: Copyright
                        Agent, with the following information:
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            A physical or electronic signature of a person
                            authorized to act on the copyright owner's behalf;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            A description of the copyrighted work claimed to
                            have been infringed;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            A description of where the copyrighted work is
                            located on the Services, including if possible a
                            URL;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            Your address, telephone number, and email address;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            A statement that you believe in good faith that use
                            of the work is not authorized by the copyright
                            owner, its agent, or the law; and
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            A statement, under penalty of perjury, that the
                            above information in your notice is accurate, and
                            that you are the copyright owner or authorized to
                            act on the copyright owner's behalf.
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        In accordance with the DMCA, EqWell may delete, remove,
                        or disable any infringing work, and may forward to the
                        alleged infringer your written notice and a notice that
                        the work has been deleted, removed, or disabled. EqWell
                        reserves the right to terminate members who post
                        infringing work.
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">Account Information</span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Passwords and Security</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . Registering for the Services may require you to create
                        a username and a password. You agree to maintain the
                        security, confidentiality, and integrity of your
                        username, password, and any other security measures
                        employed on the Services. You are responsible for all
                        access to and use of the Services, including all
                        financial transactions, under your username and
                        password, including access or use by any partner,
                        secondary, or additional member, if applicable, whether
                        or not authorized by you. You agree to notify EqWell
                        immediately of any breach of security, including
                        unauthorized use of your username or password, and to
                        take immediately all reasonable steps to prevent its
                        further occurrence, including changing your password.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Accuracy of Personal Information</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . You represent and warrant that all information
                        provided to EqWell through the Services is current,
                        accurate, complete and truthful, including all initial
                        or updated registration or membership information, such
                        as the legal name, street address, email address,
                        telephone number, and financial transaction account
                        information related to you or anyone on your membership.
                        You further represent and warrant that you are an
                        authorized account holder of any financial transaction
                        account which you provide to EqWell through the Services
                        for payment of fees, dues, or other transactions.
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">User Conduct</span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Termination of Use</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . EqWell may block, restrict, disable, suspend, or
                        terminate your access to or use of all or any parts of
                        the Services without prior notice or liability to you.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">Inappropriate Use</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . You will not post, submit, transmit, email, upload,
                        share, store or otherwise make available any User
                        Content that, in EqWell’s judgment:
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            Is unlawful, illegal, threatening, abusive,
                            harassing, stalking, intimidating, libelous, false,
                            defamatory, invasive of another's privacy or
                            publicity rights, violent, vulgar, obscene, profane,
                            sexual in nature, harmful to individuals, including
                            minors, or is otherwise objectionable, offensive or
                            improper; Is hateful toward an individual or group
                            of individuals on the basis of gender, sexual
                            orientation, race, ethnicity, age, disability, or
                            religion;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            Violates any contract or agreement you have with
                            EqWell;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            Includes personal or identifying information about
                            another person without that person's affirmative
                            consent;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            Is false, fraudulent, misleading, or deceptive;
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            Constitutes or contains any form of commercial
                            advertising, marketing, solicitation or promotion,
                            including messages and links for commercial purpose
                            or benefit and any affiliate marketing, link
                            referral code, junk mail, spam, chain letters, or
                            pyramid or Ponzi schemes; or
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <span style={{ color: "rgb(68, 68, 68)" }}>
                          <span lang="en">
                            May interrupt, limit, overburden, damage, impair,
                            destroy or otherwise negatively impact the
                            functionality or use of the Services, including User
                            Content with a software virus or User Content in an
                            excessive amount (e.g., flooding attack).
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                    }}
                  >
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        If you believe any User Content does not comply with
                        these guidelines, please contact us at the address
                        below.
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">
                      Your Warranty and Indemnification Obligations
                    </span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          ASSUMPTION OF RISK AND WAIVER OF LIABILITY
                        </span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . THERE ARE RISKS INHERENT IN ACCESSING AND USING THE
                        SERVICES, INCLUDING LOSS, THEFT, BREACH, MISUSE, OR
                        UNAUTHORIZED USE OF PERSONAL INFORMATION. YOU EXPRESSLY
                        ASSUME THESE RISKS, WILL ACCESS AND USE THE SERVICES AT
                        YOUR OWN RISK, AND WAIVE ANY AND ALL CLAIMS AGAINST
                        EQWELL, AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                        PARTNERS AND LICENSORS, RELATED TO SUCH ACCESS OR USE
                        (INCLUDING CLAIMS ON BEHALF OF MINORS ON YOUR
                        MEMBERSHIP) FOR ANY DAMAGE, LOSS, OR INJURY OF ANY KIND
                        SUSTAINED BY YOU OR ANY PERSON ON YOUR MEMBERSHIP CAUSED
                        AS A RESULT OF EQWELL'S NEGLIGENCE. THIS DOES NOT WAIVE
                        CLAIMS RESULTING FROM GROSS NEGLIGENCE, WILLFUL
                        MISCONDUCT, OR RECKLESS DISREGARD, UNLESS PROHIBITED BY
                        APPLICABLE PRIVACY LAW.
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">INDEMNIFICATION</span>
                      </span>
                    </strong>
                    <span style={{ color: "rgb(68, 68, 68)" }}>
                      <span lang="en">
                        . &nbsp;YOU AGREE TO INDEMNIFY AND HOLD HARMLESS EQWELL
                        AND ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
                        PARTNERS AND LICENSORS, FROM AND AGAINST ANY AND ALL
                        LOSS, EXPENSES, DAMAGES, AND COSTS, INCLUDING WITHOUT
                        LIMITATION REASONABLE ATTORNEYS’ FEES, RESULTING,
                        WHETHER DIRECTLY OR INDIRECTLY, FROM YOUR VIOLATION OF
                        THIS AGREEMENT. YOU ALSO AGREE TO INDEMNIFY AND HOLD
                        HARMLESS EQWELL AND ITS OFFICERS, EMPLOYEES, AGENTS,
                        PARTNERS AND LICENSORS, FROM AND AGAINST ANY AND ALL
                        CLAIMS BROUGHT BY THIRD PARTIES ARISING OUT OF YOUR USE
                        OF THE SERVICES IN BREACH OF THIS AGREEMENT OR THE USER
                        CONTENT YOU MAKE AVAILABLE VIA THE SERVICES BY ANY
                        MEANS, INCLUDING WITHOUT LIMITATION THROUGH A POSTING, A
                        LINK, REFERENCE TO USER CONTENT, OR OTHERWISE, UNLESS
                        PROHIBITED BY APPLICABLE LAW.
                      </span>
                    </span>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">DISCLAIMER OF WARRANTIES</span>
                  </span>
                </strong>
                <span style={{ color: "rgb(68, 68, 68)" }}>
                  <span lang="en">
                    . YOU ARE ACCESSING THE SERVICES ON AN “AS IS, WHERE IS, AND
                    AS AVAILABLE” BASIS. EQWELL IS NOT RESPONSIBLE FOR PROBLEMS
                    ARISING FROM, OR INADEQUACIES IN THE CONTENT OF THE SERVICES
                    OR ANY PARTICULAR FEATURES OR SERVICES OFFERED. EQWELL DOES
                    NOT REPRESENT OR WARRANT THE ACCURACY, ADEQUACY, OR
                    COMPLETENESS OF THE INFORMATION, MATERIALS, AND SERVICES ON
                    THE SERVICES OR THE ERROR-FREE USE OF THE SERVICES. EQWELL
                    IS NOT RESPONSIBLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION
                    OF ANY NETWORK OR LINES, COMPUTER ONLINE SYSTEMS, SERVERS OR
                    PROVIDERS, COMPUTER EQUIPMENT, SOFTWARE, PROBLEMS OR TRAFFIC
                    CONGESTION ON THE INTERNET, INCLUDING INJURY OR DAMAGE TO
                    USERS AND/OR MEMBERS OR TO ANY OTHER PERSON'S COMPUTER
                    RELATED TO OR RESULTING FROM ACCESS TO OR USE OF THE
                    SERVICES. EQWELL IS PROVIDING THE SERVICES WITHOUT WARRANTY
                    OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING THE
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, NON-INFRINGEMENT, AND FREEDOM FROM A COMPUTER
                    VIRUS. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
                    IMPLIED WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL
                    OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT
                    APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">LIMITATION OF LIABILITY</span>
                  </span>
                </strong>
                <span style={{ color: "rgb(68, 68, 68)" }}>
                  <span lang="en">
                    . IN NO EVENT WILL EQWELL AND ITS AFFILIATES, OFFICERS,
                    EMPLOYEES, AGENTS, PARTNERS AND LICENSORS BE LIABLE TO YOU
                    OR TO ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL,
                    EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
                    INCLUDING, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA
                    OR OTHER INTANGIBLE LOSSES RESULTING FROM YOUR ACCESS TO OR
                    USE OF THE SERVICES, YOUR INABILITY TO USE OR MALFUNCTIONS
                    OF THE SERVICES, STATEMENTS OR CONDUCT OF ANY THIRD PARTY
                    RELATING TO THE SERVICES. WITHOUT LIMITING THE GENERALITY OF
                    THE FOREGOING, EQWELL SHALL NOT HAVE ANY LIABILITY TO YOU
                    FOR THE SERVICES OF ANY THIRD PARTY, INCLUDING THE FAILURE
                    OF ANY THIRD-PARTY HEALTH, WELLNESS OR OTHER SERVICE
                    PROVIDER, WHETHER OR NOT SUCH PROVIDER IS COMPENSATED
                    THROUGH THE EQWELL WEBSITE OR PLATFORM, TO PROVIDE SUCH
                    SERVICES AT ALL, OR IN A SAFE AND PROFESSIONAL MANNER, OR IN
                    ACCORDANCE WITH ANY APPLICABLE STANDARD OF CARE. UNDER NO
                    CIRCUMSTANCES SHALL YOU BE ENTITLED TO RECOVER DAMAGES FROM
                    EQWELL GREATER THAN THE AGGREGATE AMOUNT OF YOUR PAYMENTS TO
                    EQWELL.
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                  marginTop: "0.19in",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">
                      <u>ARBITRATION AGREEMENT WITH CLASS ACTION WAIVER</u>
                    </span>
                  </span>
                </strong>
              </p>
              <ul>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Mandatory Binding Individual Arbitration</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . Except as expressly provided below, EqWell and you
                          (each a “party” or, together, “us,” “we” or “parties”)
                          agree that any dispute, claim, case, or controversy
                          (whether based in tort, contract, statute, regulation,
                          ordinance, equity or any other legal theory) between
                          us (whether arising out of or relating to past,
                          present or future acts or omissions) (“Claims”) shall
                          be exclusively resolved by binding arbitration on an
                          individual basis, rather than in court (“Arbitration
                          Agreement”).
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Waiver of Class Actions</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . We each agree that we will assert Claims in
                          arbitration only in our individual capacity, and not
                          as a representative or member of any purported class.
                          We each agree that we will not participate in any
                          class, mass, collective, consolidated, private
                          attorney general or other representative arbitration
                          proceeding. Each party agrees that the arbitrator has
                          no authority to arbitrate Claims on a class-wide basis
                          and shall not consolidate, combine, or jointly
                          arbitrate Claims of more than one person in a single
                          arbitration.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Waiver of Jury Trial</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . We waive our constitutional and statutory rights to
                          go to court and have a trial in front of a judge or a
                          jury, electing instead to resolve by binding
                          arbitration all Claims.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Claims Not Subject to Arbitration</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . There are only two exceptions to this Arbitration
                          Agreement:
                        </span>
                      </span>
                    </strong>
                  </p>
                  <ol>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <strong>
                          <span style={{ color: "rgb(68, 68, 68)" }}>
                            <span lang="en">
                              <u>Small Claims</u>
                            </span>
                          </span>
                        </strong>
                        <strong>
                          <span style={{ color: "rgb(68, 68, 68)" }}>
                            <span lang="en">
                              . Either party may bring individual Claims in
                              small claims court.
                            </span>
                          </span>
                        </strong>
                      </p>
                    </li>
                    <li>
                      <p
                        style={{
                          marginBottom: "0in",
                          fontSize: "16px",
                          lineHeight: "100%",
                          textAlign: "left",
                          background: "transparent",
                          marginTop: "0.19in",
                        }}
                      >
                        <strong>
                          <span style={{ color: "rgb(68, 68, 68)" }}>
                            <span lang="en">
                              <u>Emergency Equitable Relief</u>
                            </span>
                          </span>
                        </strong>
                        <strong>
                          <span style={{ color: "rgb(68, 68, 68)" }}>
                            <span lang="en">
                              . Either party may seek temporary injunctive
                              relief or other equitable relief in court pending
                              arbitration. A request for interim measures will
                              not be deemed a waiver of any other rights or
                              obligations under this Arbitration Agreement.
                            </span>
                          </span>
                        </strong>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Arbitration Procedures</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . Either party may initiate an arbitration proceeding,
                          which will be conducted by a single neutral
                          arbitrator.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Arbitration Providers</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . Each party has a choice of initiating arbitration
                          before either the American Arbitration Association
                          (“AAA”) or JAMS, which are both established
                          alternative dispute resolution providers (“ADR
                          Providers”). If neither AAA nor JAMS is available to
                          arbitrate, we will agree to select an alternative ADR
                          Provider to administer the arbitration in this
                          Arbitration Agreement.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Arbitration Rules</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . The arbitration will be conducted under the AAA
                          Consumer Arbitration Rules (available at www.adr.org)
                          or JAMS Streamlined Arbitration Rules and Procedures
                          (available at www.jamsadr.com) (“Arbitration Rules”).
                          The rules of the ADR Provider will govern all aspects
                          of the arbitration, except to the extent such rules
                          are in conflict with this Arbitration Agreement.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Arbitration Location</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . The arbitration hearing will be held at the ADR
                          Provider’s location that is closest to the claimant’s
                          primary residence, unless the claimant primarily
                          resides outside the United States (in which case the
                          arbitrator will give the parties reasonable notice of
                          the date, time and place of any oral hearings.)
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Fees</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . Payment of all filing, administrative, and
                          arbitrator fees will be governed by the Arbitration
                          Rules.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Arbitrator’s Authority and Award</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . The arbitrator will decide the rights and
                          liabilities, if any, of the parties, and the dispute
                          will not be consolidated with any other matters or
                          joined with any other cases or parties. The arbitrator
                          will have the authority to grant motions dispositive
                          of all or part of any Claim. The arbitrator will have
                          the authority to award monetary damages, and to grant
                          any non-monetary remedy or relief available to an
                          individual under applicable law, the ADR Provider’s
                          Rules, and this Arbitration Agreement. The arbitrator
                          will issue a written award and reasoned statement of
                          decision describing the essential findings and
                          conclusions on which the award is based, including the
                          calculation of any damages awarded. The arbitrator has
                          the same authority to award relief on an individual
                          basis that a judge in a court of law would have. Any
                          award will be subject to judicial confirmation or
                          entry in any court having jurisdiction.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Governing Law</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . These terms and conditions shall be governed and
                          construed in accordance with the substantive law of
                          the State of Delaware, without regard to principles of
                          conflicts of law; provided, however, that this
                          Arbitration Agreement is governed solely by the
                          Federal Arbitration Act and federal arbitration law.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
                <li>
                  <p
                    style={{
                      marginBottom: "0in",
                      fontSize: "16px",
                      lineHeight: "100%",
                      textAlign: "left",
                      background: "transparent",
                      marginTop: "0.19in",
                    }}
                  >
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          <u>Confidentiality</u>
                        </span>
                      </span>
                    </strong>
                    <strong>
                      <span style={{ color: "rgb(68, 68, 68)" }}>
                        <span lang="en">
                          . All aspects of the arbitration proceeding, including
                          but not limited to the award of the arbitrator and
                          compliance therewith, will be strictly confidential.
                          The parties agree to maintain confidentiality unless
                          otherwise required by law. This paragraph will not
                          prevent a party from submitting to a court of law any
                          information necessary to enforce this Arbitration
                          Agreement or to enforce an arbitration award.
                        </span>
                      </span>
                    </strong>
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p
                style={{
                  marginBottom: "0.19in",
                  fontSize: "16px",
                  lineHeight: "100%",
                  textAlign: "left",
                  background: "transparent",
                }}
              >
                <strong>
                  <span style={{ color: "rgb(68, 68, 68)" }}>
                    <span lang="en">Waiver and Severability.</span>
                  </span>
                </strong>
                <span style={{ color: "rgb(68, 68, 68)" }}>
                  <span lang="en">
                    &nbsp;To the extent that an arbitrator or court of competent
                    jurisdiction determines any part of the terms and conditions
                    in this Agreement to be invalid or unenforceable, that part
                    will be modified by the court solely to the extent necessary
                    to cause that part to be enforceable, and the remainder of
                    the Agreement will remain in full force and effect. EqWell’s
                    failure to exercise or enforce a legal right, remedy or
                    benefit which is contained in the Agreement or any
                    applicable law does not constitute waiver of its right to do
                    so later.
                  </span>
                </span>
              </p>
            </li>
          </ol>
          <p
            style={{
              marginBottom: "0.19in",
              fontSize: "16px",
              lineHeight: "100%",
              textAlign: "left",
              background: "transparent",
            }}
          >
            <span style={{ color: "rgb(68, 68, 68)" }}>
              <span lang="en">
                YOU HAVE READ, UNDERSTAND, AND VOLUNTARILY AGREE TO ALL OF THE
                TERMS AND CONDITIONS OF THIS USE AGREEMENT.
              </span>
            </span>
          </p>
        </>
      </div>
    </>
  );
};

export default TermsAndConditions;
