import { BarChart } from "@mui/x-charts";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const Detail = ({ detail, setShowDetails, title }) => {
  const { details } = useSelector((state) => state.getQuestion);
  const uData = details?.data?.detailGraphData?.map((item) => item.y_axis); // Y-axis data (scores)
  const xLabels = details?.data?.detailGraphData?.map(
    (item) => `Journey ${item.x_axis}`
  );
  const baseWidthPerBar = 50; // Width per bar in pixels
  const calculatedWidth = uData?.length * baseWidthPerBar;
  const chartWidth = Math.max(calculatedWidth, 300);
  return (
    <>
      <div className="row">
        <div className="col-md-1">
          <div className=" "></div>
        </div>
        <div className="col-md-10">
          <div className="mb-4">
            <h3 className="d-flex font-weight-bold">
              {/* {details?.data?.userDetail?.firstName}{" "}
              {details?.data?.userDetail?.lastName} */}
              {title}
            </h3>
            <p className="text-justify">{details?.data?.reportResult?.Desc}</p>{" "}
            <p className="text-justify">
              {details?.data?.reportResult?.riskText?.replace(
                /\.([^\s\d])/g,
                ". $1"
              )}
            </p>
          </div>
          <div>
            {details?.data?.reportResult?.narrativeRes.length > 0 ? (
              <h4 className="d-flex font-weight-bold mt-3">
                What is keeping you from 100%
              </h4>
            ) : (
              <div />
            )}
            <div className="d-flex detail-list ">
              <ul className="list-unstyled  text-justify">
                {details?.data?.reportResult?.narrativeRes.map((res, index) => {
                  return (
                    <div className="narrResss" key={index}>
                      <div className="narrKey">
                        <p>
                          {res.narrativeKey} {res.narrativeRes}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
          {/*  BarChart  */}
          <div style={{ width: `${chartWidth}px` }}>
            <BarChart
              width={chartWidth}
              height={300}
              series={[
                {
                  data: uData,
                  backgroundColor: "#4CAF50", // Customize the bar color
                },
              ]}
              xAxis={[
                {
                  data: xLabels,
                  scaleType: "band",
                  categoryGapRatio: 0.5,
                  barGapRatio: 0.8,
                },
              ]}
              yAxis={[
                {
                  min: 0, // Minimum value on y-axis
                  max: 100, // Maximum value on y-axis
                },
              ]}
              options={{
                maintainAspectRatio: true,
              }}
            />
          </div>
        </div>
      </div>
      <button
        style={{ width: "auto", backgroundColor: "#1b46b4" }}
        className="btn btn-secondary btn-sm d-flex ms-4"
        onClick={() => setShowDetails("")}
      >
        Back
      </button>
    </>
  );
};
export default Detail;
