import React, { useState } from "react";
import { Form } from "react-bootstrap";

const MultiCheckQstn = (props) => {
  const [selectCheckBox, setSelectCheckBox] = useState([]);
  const [selectCheckBoxId, setSelectCheckBoxId] = useState("");

  const calFun = async (str, questionId) => {
    let found = await selectCheckBox.filter((ele) => ele === str);
    if (found.length === 0) {
      let obj = {
        questionId: questionId,
        selectedOption: [...selectCheckBox, str],
      };
      props.updateData(obj);
      setSelectCheckBox([...selectCheckBox, str]);
      setSelectCheckBoxId(questionId);
      setSelectCheckBox([...selectCheckBox, str]);
    } else {
      let removeOption = await selectCheckBox.filter((ele) => ele !== str);
      setSelectCheckBox(removeOption);

      let obj = {
        questionId: questionId,
        selectedOption: removeOption,
      };
      props.updateData(obj);
      setSelectCheckBox(removeOption);
      setSelectCheckBoxId(questionId);
    }
  };

  const selectedOptionChecker = (str) => {
    let found = selectCheckBox.filter((ele) => ele === str);
    if (found.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      -{" "}
      <div className="question pt-3">
        <p className="d-flex">{props.question}</p>
        <div className="option d-flex ps-4">
          <Form>
            {!props?.IsChild
              ? props?.data?.options?.values.map((val, index) => {
                  return (
                    <Form.Check
                      id="mulit-box"
                      key={index}
                      aria-label="option 1"
                      inline
                      checked={selectedOptionChecker(val)}
                      label={val}
                      onClick={() => calFun(val, props.data._id)}
                    />
                  );
                })
              : props?.data[0]?.options?.values.map((val, index) => {
                  return (
                    <Form.Check
                      id="mulit-box"
                      key={index}
                      aria-label="option 1"
                      inline
                      checked={selectedOptionChecker(val)}
                      label={val}
                      onClick={() => calFun(val, props?.data[0]?._id)}
                    />
                  );
                })}
          </Form>
        </div>
      </div>
    </>
  );
};

export default MultiCheckQstn;
