import React from 'react'
import CoachHeader from './CoachHeader';
import CoachNavbar from './CoachNavbar';
import CoachSidebar from './CoachSidebar';

const CoachLayout = ({component}) => {
    const ComponentToRender = component;
  return (
    <div>
      <div className="dashboard-main wrap">
        <CoachNavbar />
        <div className="row">
          <div className="col-md-auto p-0">
            <CoachSidebar />
          </div>
          <div className="col-md p-0 coach-layout">
          {/* <CoachHeader /> */}
            {/* {window.location.pathname === "/health-kit"||window.location.pathname ==="/userprofile" ? null :<CoachHeader />} */}
            <ComponentToRender />
            {/* {toggleChatCoach ? (
              <ChatCoach   />
            ) : (
              <ComponentToRender />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoachLayout