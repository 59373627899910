import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authActions from "../../../redux/actions/authAction";
import { toastify } from "../../../components/Toast";
import { ToastContainer } from "react-toastify";
import { Oval } from "react-loader-spinner";
const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [filteredNotification, setFilteredNotification] = useState([]);
  // const { notificationData } = useSelector((state) => state.getNotifications);

  const [notifications, setNotifications] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [loader, setloader] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;
  const observer = useRef();

  useEffect(() => {
    if (notifications?.length === 0) {
      setloader(true);
    }
    dispatch(
      authActions.getAllNotifications(
        page,
        limit,
        setNotificationData,
        setloader
      )
    );
  }, [page]);

  useEffect(() => {
    if (notificationData?.data?.length) {
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...notificationData.data,
      ]);
    }
  }, [notificationData]);

  const lastNotificationElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const onNavigateFromNotifcation = (selectedNotificationData) => {
    dispatch(
      authActions.markNotificationSeen(selectedNotificationData?.chatId?._id)
    );
    const selectedNotificationId = {
      notificationId: selectedNotificationData?._id,
    };
    dispatch(
      authActions.markSingleNotificationSeen(
        selectedNotificationId,
        callBackOfUnseenNotification
      )
    );

    if (selectedNotificationData?.NotificationType === "groupCreated") {
      navigate("/chat-coach", {
        state: { selectedNotificationData },
      });
    } else if (selectedNotificationData?.NotificationType === "message") {
      navigate("/chat-coach", {
        state: { selectedNotificationData },
      });
    } else if (
      selectedNotificationData?.NotificationType === "groupMemberAdded"
    ) {
      navigate("/chat-coach", {
        state: { selectedNotificationData },
      });
    } else if (selectedNotificationData?.NotificationType === "comment") {
      if (selectedNotificationData?.activityId === null) {
        // setNotifications([]);
        // setPage(1);
        // dispatch(
        //   authActions.getAllNotifications(page, limit, setNotificationData)
        // );

        toastify("error", "This activity has been deleted");
      } else {
        navigate("/activities", {
          state: { dataFromNotification: selectedNotificationData },
        });
      }
    } else if (selectedNotificationData?.NotificationType === "assignCoach") {
      navigate("/chat-coach");
    }
  };
  const callBackOfUnseenNotification = () => {
    dispatch(authActions.getAllNotifications());
  };
  const formatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const dateObj = new Date(date);

    if (dateObj.toDateString() === today.toDateString()) {
      return `Today at ${dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`;
    } else if (dateObj.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`;
    } else {
      return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`;
    }
  };

  return (
    <>
      <>
        <div className="notification-page">
          <div className="notification-page-header">
            <h1>Notifications</h1>
          </div>

          <div className="notification-page-main-container">
            {loader ? (
              <div className="loader-container">
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </div>
            ) : notifications.length === 0 ? (
              <div className="no-data-case"> No notifications yet</div>
            ) : (
              <div className="notification-page-data">
                {notifications.map((notificationMappedData, index) => {
                  const isLastElement = notifications.length === index + 1;
                  return (
                    <div
                      key={notificationMappedData.id}
                      ref={isLastElement ? lastNotificationElementRef : null}
                      className={
                        notificationMappedData.isRead
                          ? "notification-page-userData"
                          : "notification-page-unseen"
                      }
                      onClick={() =>
                        onNavigateFromNotifcation(notificationMappedData)
                      }
                    >
                      <div className="notification-page-name-time">
                        <div className="notification-page-userName">
                          <div className="notification-page-cross-btn"></div>
                          {notificationMappedData.title}
                        </div>
                        <div className="notification-page-time">
                          {/* {formatDate(
                            new Date(notificationMappedData.createdAt)
                          )} */}
                          {moment(notificationMappedData.createdAt).fromNow()}
                        </div>
                      </div>
                      <div className="notification-page-userMsg">
                        {notificationMappedData.body}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </>{" "}
      <ToastContainer />
    </>
  );
};

export default Notifications;
