const SetGoalBox = ({ title, children, titleColor, riskClassesCount }) => {
  return (
    <div className="container-survey-goals-riskfactors">
      {riskClassesCount > 0 && (
        <div
          className="title-survey-goals-riskfactors"
          style={{ backgroundColor: titleColor, color: "white" }}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default SetGoalBox;
