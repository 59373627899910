import { useRef, useEffect } from "react";

const usePreventDoubleEffect = (effect) => {
  const effectRan = useRef(false); // This ref tracks whether the effect has already run

  useEffect(() => {
    if (effectRan.current) return; // If effect has already run, skip it
    
    effect(); // Run the effect function (your logic or API call)
    
    effectRan.current = true; // Mark the effect as executed

  }, [effect]); // Depend on the effect function itself
};

export default usePreventDoubleEffect;
