import React, { useCallback, useEffect, useMemo, useRef } from "react";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "html-react-parser";

import startOfWeek from "date-fns/startOfWeek";
import { useState } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  Views,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as authActions from "../../../redux/actions/authAction";
import { io } from "socket.io-client";
import "../../../../src/index.css";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import { toastify } from "../../../components/Toast";
import { socket } from "../../../constants/socket";
import "moment-timezone";
import { Oval } from "react-loader-spinner";
import { Chip, MenuItem, Select } from "@mui/material";
import usePreventDoubleEffect from "../../../constants/hooks/usePreventDoubleEffect";
moment.tz.setDefault("Europe/London");

const ActivityPlanner = () => {
  const location = useLocation();
  const myRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allEvents, setAllEvents] = useState("");
  const [updt, setupdt] = useState(false);
  const [show, setShow] = useState(false);
  const [eventDetail, setEventDetail] = useState(null);
  const [currentComment, setCurrentComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  let [count, setCount] = useState(0);
  const [selectedJourneyDate, setSelectedJourneyDate] = useState(null);
  const [loaderForSingleActivity, setLoaderForSingleActivity] = useState(false);
  const [titleState, setTitleState] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [goalState, setGoalState] = useState("");
  const [addActivtiyModal, setAddActivtiyModal] = useState(false);
  const [arrayOfGoals, setArrayOfGoals] = useState();
  const [startDateState, setStartDateState] = useState(new Date());
  const [scrollUpdater, setScrollUpdater] = useState(false);
  const [loaderForMarkCompleted, setLoaderForMarkCompleted] = useState(false);
  const [loaderForCreateActivity, setLoaderForCreateActivity] = useState(false);
  const [loaderForActivity, setLoaderForActivity] = useState(false);
  const [clientDataFromNavigation] = useState(
    location?.state?.dataFromNotification
  );
  const [clientDataFromDashboard] = useState(
    location?.state?.dataFromDashboard
  );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      navigate("/activities", { replace: true, state: {} });
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const { journeysData } = useSelector((state) => state.getQuestion);
  const { user } = useSelector((state) => state?.authentication);
  const [activeAttempt, setActiveAttempt] = useState(
    journeysData?.data?.find((journey) => journey.isActive === true)
  );
  useEffect(() => {
    if (journeysData?.data && selectedJourneyDate) {
      const attempt = journeysData.data.find(
        (journey) => journey.attempt === selectedJourneyDate
      );

      if (attempt) {
        setSelectedJourneyDate(attempt);
      }
    }
  }, [selectedJourneyDate, journeysData]);
  useEffect(() => {
    if (user?.data?.userId) {
      dispatch(
        authActions.getJourneysData(callBackForJourneyData, user?.data?.userId)
      );
      setLoaderForActivity(true);
    }
  }, [user]);
  const callBackForJourneyData = (res) => {
    if (res) {
      if (activeAttempt?.attempt) {
        dispatch(
          authActions.getActivityData(
            callBackForActivities,
            activeAttempt?.attempt,
            setSelectedJourneyDate
          )
        );
        dispatch(authActions.getUserGoals(activeAttempt?.attempt));
      }
    } else {
      setLoaderForActivity(false);
    }
  };
  const [DateTime] = useState(new Date());
  const { userActivityData, userGoalsData } = useSelector(
    (state) => state.getActivityData
  );
  useEffect(() => {
    if (journeysData?.data) {
    }
  }, [journeysData]);

  const userIDFromState = user?.data?.userId;
  useEffect(() => {
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1500);
  }, [scrollUpdater]);

  const callBackForActivities = (res) => {
    if (res) {
      setLoaderForActivity(false);
    }
  };
  useEffect(() => {
    const eventsData = userActivityData?.data?.map((item) => {
      item.start = new Date(item.startDate);
      // item.start = item.startDate;
      // item.end = item.endDate;
      item.end = new Date(item.endDate);
      return item;
    });
    setAllEvents(eventsData);
    setupdt(true);
  }, [userActivityData]);
  useEffect(() => {
    if (clientDataFromNavigation) {
      handleSelectEvent(clientDataFromNavigation?.activityId);
    }
    if (clientDataFromDashboard) {
      handleSelectEvent(clientDataFromDashboard);
    }
  }, [clientDataFromNavigation, clientDataFromDashboard]);
  const handleClose = () => {
    setShow(false);
  };
  const localizer = momentLocalizer(moment);

  const socketConnectionFun = (activityId) => {
    // Capture the current activityId in a closure
    const currentActivityId = activityId;

    socket?.emit("load-comments", userIDFromState, currentActivityId);

    // Remove any previous listeners to avoid duplication
    socket?.off("new-comments");

    socket?.on("new-comments", (comments) => {
      setCount((count) => count + 1);

      // Check if the activity ID of the incoming comments matches the current activity ID
      if (comments?.activityId === currentActivityId) {
        // Update the comment list if the IDs match
        setCommentList(comments?.messages);
      }
    });

    // Update the scroll state
    setScrollUpdater(true);
  };

  const handleSelectEvent = (event) => {
    setEventDetail(null);
    setLoaderForSingleActivity(true);
    dispatch(
      authActions.getSingleActivityData(event?._id, callBackForSingleActivity)
    );
    setShow(true);
    setScrollUpdater(true);
    socketConnectionFun(event?._id);
    setCurrentComment("");
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1500);
  };

  const callBackForSingleActivity = (res) => {
    if (res) {
      setEventDetail(res?.data);
      setLoaderForSingleActivity(false);
    }
  };
  const onCrossClick = () => {
    setShow(false);
    setEventDetail(null);
    setCommentList([]);
  };
  const onCheckboxClick = (e) => {
    setLoaderForMarkCompleted(true);
    dispatch(
      authActions.completeUserActivity(
        e?._id,
        callbackForUpdatedActivity,
        setLoaderForMarkCompleted,
        { currentTime: Date.now() }
      )
    );
  };
  const callbackForUpdatedActivity = (status) => {
    dispatch(authActions.getBonusPoints());
    const callBackForActivityList = (res) => {
      setLoaderForMarkCompleted(false);
      onCrossClick();
    };
    dispatch(
      authActions.getActivityData(
        callBackForActivityList,
        selectedValue,
        setSelectedJourneyDate
      )
    );

    setAddActivtiyModal(false);
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    setStartDateState(new Date());
  };
  const commentsInputHandler = (e) => {
    setCurrentComment(e.target.value);
  };
  const commentsSender = (e) => {
    e.preventDefault();
    socket?.emit(
      "activity-comments",
      {
        senderId: userIDFromState,
        msg: currentComment,
        activityId: eventDetail?._id,
        createdTimeStamp: Date.now(),
      },
      (res) => {
        if (res.error === 404) {
          toastify("error", res.message);
        }
      }
    );
    setCurrentComment("");
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1000);
  };
  const { views } = useMemo(
    () => ({
      views: [Views.MONTH],
    }),
    []
  );

  const onAddEventBtn = () => {
    if (userGoalsData?.data?.length) {
      handleShow();
      setArrayOfGoals(userGoalsData);
    } else {
      toastify("error", "Select goals from survey to add activities");
    }
  };
  const handleCloseAddActivityModal = () => {
    setAddActivtiyModal(false);
  };
  const onSaveEventData = () => {
    setLoaderForCreateActivity(true);
    const startDate = new Date(startDateState);
    const isoStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    ).toISOString();

    const userData = {
      userId: userIDFromState,
      endDate: isoStartDate,
      startDate: isoStartDate,
      title: titleState,
      description: descriptionState,
      goalId: goalState,
    };
    if (userData.title !== "") {
      dispatch(
        authActions.createUserActivity(
          userData,
          callbackForNewActivity,
          setLoaderForCreateActivity
        )
      );
    } else {
      toastify("error", "Title is mandatory");
      setLoaderForCreateActivity(false);
    }
  };
  const callbackForNewActivity = () => {
    dispatch(
      authActions.getActivityData(
        callBackForActivities,
        selectedValue,
        setSelectedJourneyDate
      )
    );
    setLoaderForActivity(true);
    setAddActivtiyModal(false);
    setTitleState("");
    setDescriptionState("");
    setGoalState("");
    setStartDateState(new Date());
  };
  const handleShow = () => setAddActivtiyModal(true);
  // const options = journeysData?.data
  //   ?.filter((attempt) => attempt.isJourneyStarted)
  //   .map((attempt) => ({
  //     value: attempt.attempt,
  //     label: `Journey ${attempt.attempt}`,
  //   }));

  // const [selectedValue, setSelectedValue] = useState(() => {
  //   return activeAttempt?.isActive && activeAttempt.isJourneyStarted
  //     ? activeAttempt.attempt
  //     : options?.[0]?.value || "";
  // });
  // console.log("454545", activeAttempt);
  // useEffect(() => {
  //   if (!activeAttempt && options?.length > 0) {
  //     setSelectedValue(options[0].value);
  //   }
  // }, [options, activeAttempt]);

  const options = journeysData?.data?.map((attempt) => ({
    value: attempt.attempt,
    label: `Journey ${attempt.attempt}`,
  }));

  const [selectedValue, setSelectedValue] = useState(
    activeAttempt ? activeAttempt.attempt : ""
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    dispatch(
      authActions.getActivityData(
        callBackForActivities,
        event.target.value,
        setSelectedJourneyDate
      )
    );
    setLoaderForActivity(true);
  };
  const [modalForJourneyInfoOfUser, setModalForJourneyInfoOfUser] =
    useState(false);
  const onJourneyInfoBtn = () => {
    setModalForJourneyInfoOfUser(true);
  };
  const onJourneyInfoModalClose = () => {
    setModalForJourneyInfoOfUser(false);
  };
  const [loadingForJourneyInfo, setLoadingForJourneyInfo] = useState(false);
  return (
    <>
      {/* ACTIVITY DETAILS AND COMMENT SECTION */}
      <>
        <Modal
          show={show}
          onHide={handleClose}
          style={{ paddingRight: "0px !important" }}
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
        >
          <div className="activity-popup-cross">
            <img
              src="/img/CompositeLayer.png"
              alt="crosss"
              onClick={!loaderForMarkCompleted ? onCrossClick : null}
              style={{ cursor: loaderForMarkCompleted ? "default" : "pointer" }}
            />
          </div>
          {loaderForSingleActivity ? (
            <div className="loader-container " style={{ margin: "5% 0 5% 0" }}>
              <Oval
                height={30}
                width={30}
                color="#1b46b4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#56bcb2"
                strokeWidth={6}
                strokeWidthSecondary={6}
              />
            </div>
          ) : (
            <div>
              <div className="activity-popup-header">
                <div className="event-info">
                  <>
                    <div className="event-info-and-button">
                      <div className="event-title">
                        {eventDetail?.title}
                        <div className="event-date-time">
                          {moment(eventDetail?.startDate).format("dddd ")},
                          {moment(eventDetail?.startDate).format(" MMM Do ")}
                          {eventDetail?.time?.from}
                          <div style={{ fontSize: "14px" }}>
                            <Chip
                              label={`Goal: ${eventDetail?.goalName}`}
                              style={{
                                backgroundColor: "#1b46b4",
                                color: "white",
                                marginTop: "8px",
                              }}
                            />
                          </div>{" "}
                          <div style={{ fontSize: "14px", display: "flex" }}>
                            {" "}
                            <Chip
                              label={` Journey: ${eventDetail?.attempt}`}
                              style={{
                                backgroundColor: "#56bcb2",
                                color: "white",
                                marginTop: "8px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        {eventDetail?.isCompleted === true ? (
                          <>
                            <Button variant="success" size="sm">
                              Completed
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={(e) => onCheckboxClick(eventDetail)}
                              disabled={loaderForMarkCompleted}
                            >
                              {loaderForMarkCompleted ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : (
                                "Mark as completed"
                              )}
                            </Button>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="description-in-the-activity">
                      {eventDetail?.description ? (
                        <>{parse(eventDetail?.description)}</>
                      ) : null}
                    </div>
                  </>
                </div>
                {/* <div className="activity-checkbox">
              <div className="container">
                <div className="round">
                  {eventDetail?.isCompleted === true ||
                  checkBoxDisable === true ? (
                    <input
                      type="checkbox"
                      id="checkbox"
                      defaultChecked
                      disabled
                      onChange={(e) => {
                        onCheckboxClick(userActivityData?.data, e);
                      }}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id="checkbox"
                      onChange={(e) => {
                        onCheckboxClick(eventDetail);
                      }}
                    />
                  )}

                  <label htmlFor="checkbox"></label>
                </div>
              </div>
            </div> */}
              </div>
              <Modal.Body>
                <Form className="popup-search-bar">
                  <Form.Control
                    type="text"
                    value={currentComment}
                    className="popup-inputfield"
                    placeholder="add a comment"
                    onChange={commentsInputHandler}
                    autoFocus
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  />
                  <img
                    src="/img/send-button-icon-se.png"
                    className="send-btn-img"
                    alt="send"
                    onClick={(e) => commentsSender(e)}
                  />
                </Form>
              </Modal.Body>

              {commentList !== null ? (
                commentList?.length ? (
                  <div className="comment-container">
                    <>
                      <div className="comments-title">
                        <p>Comments ({commentList?.length})</p>
                      </div>
                      <div className="comments-section-container">
                        {commentList?.map((comments, index) => {
                          return (
                            <div
                              key={index}
                              ref={myRef}
                              className="comments-section"
                            >
                              <div className="comments-body">
                                <div className="comments-pic-container">
                                  {comments?.imageUrl ? (
                                    <img
                                      className="profile-pic"
                                      src={comments?.imageUrl}
                                      alt="img"
                                    />
                                  ) : (
                                    <img
                                      className="profile-pic"
                                      src="/img/sample_img_2.png"
                                      alt="img"
                                    />
                                  )}
                                </div>
                                <div className="comments-userinfo">
                                  <p className="comments-username">
                                    {comments?.firstName}
                                  </p>
                                  <p className="comments-user-activitytime">
                                    {moment(comments?.createdAt).fromNow()}
                                  </p>
                                </div>
                              </div>
                              <div className="comments-text">
                                <p>{comments?.comment}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  </div>
                ) : (
                  <div style={{ margin: "40px 0px 40px 190px" }}>
                    No comments
                  </div>
                )
              ) : (
                <div style={{ margin: "40px 0px 40px 190px" }}>
                  Please wait comments are loading ...{" "}
                </div>
              )}
            </div>
          )}
        </Modal>
      </>
      {/* Create Activity */}
      <>
        <Modal
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
          show={addActivtiyModal}
          onHide={handleCloseAddActivityModal}
          centered
        >
          <Modal.Header
            className="modal-header-workout"
            closeButton={!loaderForCreateActivity}
          >
            <Modal.Title>Add an Activity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Write a title for your activity"
                    onChange={(e) => setTitleState(e.target.value)}
                    value={titleState}
                  />
                </Form.Group>{" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write a description for your activity"
                    onChange={(e) => setDescriptionState(e.target.value)}
                    value={descriptionState}
                  />
                </Form.Group>
                {arrayOfGoals?.data?.length ? (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Select goals</Form.Label>

                    <>
                      <form className="goalSelectorFieldForm">
                        <select
                          className="goalSelectorField"
                          onChange={(event) => setGoalState(event.target.value)}
                          value={goalState}
                        >
                          <option hidden className="first-options">
                            Select your goals
                          </option>
                          {arrayOfGoals?.data?.map((ele) => {
                            return (
                              <>
                                <option value={ele?._id}>{ele?.title}</option>
                              </>
                            );
                          })}
                        </select>
                      </form>
                    </>
                  </Form.Group>
                ) : (
                  <option value="">
                    Select goals from survey first to add an activity
                  </option>
                )}
                <Form.Label>Date</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <DatePicker
                    onChange={setStartDateState}
                    value={startDateState}
                    minDate={new Date()}
                  />
                </Form.Group>
              </Form>
            </>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleCloseAddActivityModal}
              disabled={loaderForCreateActivity}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onSaveEventData}
              disabled={loaderForCreateActivity}
            >
              {loaderForCreateActivity ? (
                <Spinner
                  className=""
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <div className="activity-main">
        <div className="row">
          <div className="chat-and-event-btn">
            <div className="add-event-btn">
              <div className="activity-journey-dropdown">
                <div>
                  <Select value={selectedValue} onChange={handleChange}>
                    {options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="activity-journey-dates">
                  {selectedJourneyDate?.startDate ? (
                    <>
                      <div>
                        Start date :{" "}
                        {moment(selectedJourneyDate?.startDate).format(
                          "MMM Do YY"
                        )}
                      </div>
                      <div>
                        End date :{" "}
                        {moment(selectedJourneyDate?.endDate).format(
                          "MMM Do YY"
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>{" "}
              <div className="activity-btn-container">
                <Button className="btn-srvc mr-4" onClick={onJourneyInfoBtn}>
                  Journeys info
                </Button>
                <Button className="btn-srvc" onClick={onAddEventBtn}>
                  Add an Activity
                </Button>
              </div>
            </div>
            {/* <div className="chat-client-btn">
              <Button
                variant="success"
                className="btn-srvc"
                onClick={onChatClientBtn}
              > 
                Chat coach
              </Button>
            </div> */}
          </div>
        </div>
        {loaderForActivity ? (
          <div className="loader-container " style={{ margin: "5% 0 5% 0" }}>
            <Oval
              height={30}
              width={30}
              color="#1b46b4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#56bcb2"
              strokeWidth={6}
              strokeWidthSecondary={6}
            />
          </div>
        ) : allEvents?.length ? (
          <div className="calendar">
            <Calendar
              localizer={localizer}
              events={allEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "60vh", margin: "30px" }}
              onSelectEvent={(e) => handleSelectEvent(e)}
              eventPropGetter={(event) => {
                const today = new Date();
                // Check if the event is in the past
                if (event.start < today) {
                  if (event.isCompleted) {
                    // Completed past activity - set background color to green
                    return {
                      style: {
                        backgroundColor: "#17AD76",
                        color: "white",
                      },
                    };
                  } else {
                    // Incomplete past activity - set background color to red
                    return {
                      style: {
                        backgroundColor: "#DB7D76",
                        color: "white",
                      },
                    };
                  }
                }
                // Default for future or ongoing events
                return {};
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              height: "50vh", // Take full viewport height
              margin: "0", // Remove margin
            }}
          >
            Journey is not started yet
          </div>
        )}
      </div>
      <>
        {/*User Engagement from Journey modal*/}
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={modalForJourneyInfoOfUser}
          onHide={onJourneyInfoModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Journey's info</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            {loadingForJourneyInfo ? (
              <div className="loader-container">
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </div>
            ) : (
              <div>
                {journeysData?.data?.map((journey, index) => (
                  <div key={index} className="journey-container">
                    <div className="journeys-heading">
                      Journey {journey?.attempt}
                    </div>

                    <div className="Journeys-dates-container">
                      {journey?.startDate ? (
                        <>
                          {" "}
                          <div className="Journeys-dates-param">
                            <p>Start date</p>
                            <p>End date</p>
                          </div>
                          <div className="Journeys-dates-data">
                            <p>
                              {moment(journey?.startDate).format("MMM Do YY")}
                            </p>

                            <p>
                              {moment(journey?.endDate).format("MMM Do YY")}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="Journeys-dates-data">
                          {" "}
                          Journey is not started yet
                        </div>
                      )}{" "}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

export default ActivityPlanner;
