import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import * as authActions from "../redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "../constants/socket";

const CoachSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [counterHide, setCounterHide] = useState(true);
  const { notificationData } = useSelector((state) => state.getNotifications);
  const [badge, setBadge] = useState(0);

  const { userprofileData } = useSelector((state) => state.getProfileData);
  useEffect(() => {
    dispatch(authActions.getAllCoachClients());
  }, []);
  let userId = userprofileData?.data?._id;
  const handleLogout = () => {
    socket.disconnect();
    dispatch(authActions.userLogoutRequest(callback));
  };
  const callback = () => {
    navigate("/login");
  };
  const onCounterHide = () => {
    socket.emit("mark-notification-seen", userId);
    setCounterHide(false);
    // setBadge(0);
  };

  useEffect(() => {
    socket.on("notification-bages", (badges) => {
      setBadge(badges);
    });
  }, []);

  return (
    <>
      <div className="sidebar-outer">
        <div className="main-sidebar">
          <div className="sidebar-menu">
            <ul className="list-style-type-none">
              <li className="text-decoration-none">
                <NavLink
                  to="/coach-dashboard"
                  className="nav-link text-decoration-none"
                  id="sidebar-dashboard"
                >
                  <span className="menu-item-icon">
                    <img src="/img/dashboard.png" />
                  </span>
                  <span className="menu-item-name">Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/coach-clients"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  <span className="menu-item-icon">
                    <img src="/img/clock.png" />
                  </span>
                  <span className="menu-item-name">Users</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/chat-client"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  <span className="menu-item-icon">
                    <img src="/img/user.png" />
                  </span>
                  <span className="menu-item-name">Chat with users</span>
                </NavLink>
              </li>
              <li onClick={onCounterHide}>
                <NavLink
                  to="/coach-notification"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  <span className="menu-item-icon">
                    <img src="/img/notify.png" />
                  </span>
                  <span className="menu-item-name d-flex">
                    Notifications
                    {/* {badge ? (
                      <div className="notification-counter">
                        
                        {badge}
                        </div>
                    ) : null} */}
                    <div className="notification-header-on-sidebar"></div>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/coach-profile"
                  className="nav-link"
                  id="sidebar-dashboard"
                >
                  <span className="menu-item-icon">
                    <img src="/img/user.png" />
                  </span>
                  <span className="menu-item-name">My Profile</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to=" "
                  className="nav-link"
                  id="sidebar-dashboard"
                  onClick={handleLogout}
                >
                  <span className="menu-item-icon">
                    <img src="/img/logout.png" />
                  </span>
                  <span className="menu-item-name">Logout</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoachSidebar;
