import React, { useState } from "react";
import { Form, Card, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  var value = {
    email: email,
  };
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    setIsLoading(true);
    dispatch(authActions.userForgetPasswordRequest(value, callback));
  };
  const callback = (status) => {
    setIsLoading(false);
  };

  return (
    <>
      <div className="forget-password">
        <div className="text-center my-3">
          <img src="/img/logo@2x.png" className="img-fluid" />
        </div>
        <div className="background-screen">
          <div className="d-grid justify-content-center align-items-center h-100">
            <Card className="">
              <Card.Body style={{ margin: "5%" }}>
                <div>
                  <h3 className="text-center mb-4 font-weight-bold">
                    Forgot Password
                  </h3>
                  <p>
                    Please enter your email address to receive a verification
                    code
                  </p>
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ margin: "0" }}
                  >
                    <Form.Group className="mb-5" controlId="formBasicEmail">
                      <Form.Label className="float-left">
                        Email address
                      </Form.Label>
                      <Form.Control
                        type="email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                    <div className="text-center">
                      {isLoading ? (
                        <button className="btn-login btn-lg">
                          <Spinner
                            className=""
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </button>
                      ) : (
                        <button
                          variant="primary"
                          type="submit"
                          className="btn-login btn-lg"
                        >
                          Send
                        </button>
                      )}
                    </div>
                  </Form>
                </div>
              </Card.Body>
              <p className="d-flex m-auto mb-4">
                <Link to="/login" className="text-decoration-none">
                  Back to login
                </Link>
              </p>{" "}
            </Card>
            <div>
              <p className="text-center text-white">
                {" "}
                &copy; 2024 EQWELL ~ All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default ForgetPassword;
