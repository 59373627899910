import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, ListGroup, Modal, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { socket } from "../../../constants/socket";
import moment from "moment";
import { toastify } from "../../../components/Toast";
import { Box, Chip, IconButton, Tab, Tabs, Typography } from "@mui/material";
import usePreventDoubleEffect from "../../../constants/hooks/usePreventDoubleEffect";
import { Oval } from "react-loader-spinner";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state?.authentication);
  const { userprofileData } = useSelector((state) => state.getProfileData);
  const { journeysData } = useSelector((state) => state.getQuestion);
  const [dailyContentModal, setDailyContentModal] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [upcomingContentModal, setUpcomingContentModal] = useState(false);
  const [contentData, setContentData] = useState("");
  const [loaderForJourneyData, setloaderForJourneyData] = useState(true);
  const [loaderForSelectedJourney, setloaderForSelectedJourney] =
    useState(false);
  // Activity modal states
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [loaderForMarkCompleted, setLoaderForMarkCompleted] = useState(false);
  const [commentList, setCommentList] = useState([]);
  let [count, setCount] = useState(0);
  const [scrollUpdater, setScrollUpdater] = useState(false);
  const [eventDetail, setEventDetail] = useState("");
  const [currentComment, setCurrentComment] = useState("");
  const myRef = useRef();
  const [activeJourney, setActiveJourney] = useState(0);
  const [currentJourney, setCurrentJourney] = useState(0);
  const [selectedJourneyDate, setSelectedJourneyDate] = useState(null);
  usePreventDoubleEffect(() => {
    dispatch(
      authActions.getActivityData(
        callBackForActivityList,
        currentJourney?.attempt,
        setSelectedJourneyDate
      )
    );
    dispatch(authActions.getUserAssignedCoach());
    dispatch(authActions.getDailyContent({ currentTime: Date.now() }));
    dispatch(authActions.getReminderData(callBackForDailyReminder));
    dispatch(authActions.getLastDoneActivties());
    dispatch(authActions.getUserDetails());
    dispatch(authActions.getBonusPoints());
  });
  useEffect(() => {
    if (user) {
      dispatch(
        authActions.getJourneysData(callBackForJourneyData, user?.data?.userId)
      );
      setloaderForJourneyData(true);
    }
  }, [user]);

  useEffect(() => {
    if (journeysData?.data) {
      // Find the index of the active journey
      const activeIndex = journeysData.data.findIndex((journey) => {
        return journey.isActive;
      });

      // If an active journey is found, update the state
      if (activeIndex !== -1) {
        setActiveJourney(activeIndex);
        dispatch(
          authActions.getDashboardData(
            activeIndex + 1,
            callBackForTheDashboardData
          )
        );
        setloaderForSelectedJourney(true);
      }
      const activeAttempt = journeysData?.data?.find(
        (journey) => journey.isActive === true
      );
      setCurrentJourney(activeAttempt);

      dispatch(authActions.getUserGoals(activeAttempt?.attempt));
    }
  }, [journeysData?.data]);

  const handleChange = (event, newValue) => {
    setActiveJourney(newValue);
    dispatch(
      authActions.getDashboardData(newValue + 1, callBackForTheDashboardData)
    );
    setloaderForSelectedJourney(true);
  };
  const callBackForTheDashboardData = (res) => {
    if (res) {
      setloaderForSelectedJourney(false);
    }
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div style={{ width: "100%" }}>
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      </div>
    );
  }
  const callBackForJourneyData = (res) => {
    if (res) {
      setloaderForJourneyData(false);
    }
  };
  useEffect(() => {
    socket.emit("notification-count", userprofileData?.data?._id);
  }, [userprofileData]);

  const {
    userDashboardData,
    reminderDataInReducer,
    DailyContentData,
    lastDoneActivities,
  } = useSelector((state) => state.getDashboardData);

  const callBackForDailyReminder = (res) => {
    if (res?.data) {
      setReminderModal(true);
    }
  };
  const onAwarenessContentModal = (data) => {
    if (data?.category === "Activity") {
      dispatch(
        authActions.getSingleActivityData(data?._id, callBackForSingleActivity)
      );
    } else {
      setDailyContentModal(true);
      dispatch(
        authActions.markContentSeen(data._id, callBackForMarkSeenContent)
      );

      setContentData(data);
    }
  };
  const callBackForSingleActivity = (res) => {
    setShowActivityModal(true);
    setEventDetail(res?.data);
    setScrollUpdater(true);
    socketConnectionFun(res?.data?._id);
    setCurrentComment("");
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1500);
  };
  const callBackForMarkSeenContent = (res) => {
    if (res) {
      dispatch(authActions.getDashboardData(activeJourney + 1));
    }
  };
  const ondailyContentModalClose = () => {
    setDailyContentModal(false);
  };
  const onUpcomingContentModal = (data) => {
    if (data?.category === "Activity") {
      dispatch(
        authActions.getSingleActivityData(data?._id, callBackForSingleActivity)
      );
    } else {
      setUpcomingContentModal(true);
      dispatch(
        authActions.markContentSeen(data._id, callBackForMarkSeenContent)
      );
      setContentData(data);
    }
  };
  const onUpcomingContentModalClose = () => {
    setUpcomingContentModal(false);
  };
  const onReminderModalClose = () => {
    setReminderModal(false);
    const seenReminderData = {
      reminderSeen: true,
    };
    dispatch(authActions.reminderCloser(seenReminderData));
  };
  // Selected activity modal functionalities
  const handleClose = () => {
    setShowActivityModal(false);
  };
  const onCrossClick = () => {
    setShowActivityModal(false);

    setCommentList([]);
  };
  const socketConnectionFun = async (activityId) => {
    socket?.emit("load-comments", userprofileData?.data?._id, activityId);
    socket?.on("new-comments", (comments) => {
      setCount((count += 1));
      setCommentList(comments?.messages);
    });
    setScrollUpdater(true);
  };
  const onCheckboxClick = (e) => {
    setLoaderForMarkCompleted(true);
    dispatch(
      authActions.completeUserActivity(
        e?._id,
        callbackForUpdatedActivity,
        setLoaderForMarkCompleted,
        { currentTime: Date.now() }
      )
    );
  };
  const callbackForUpdatedActivity = (status) => {
    dispatch(authActions.getBonusPoints());
    callBackForActivityList();
    dispatch(
      authActions.getActivityData(
        callBackForActivityList,
        currentJourney?.attempt,
        setSelectedJourneyDate
      )
    );
    dispatch(authActions.getDashboardData(activeJourney + 1));
  };
  const callBackForActivityList = () => {
    setLoaderForMarkCompleted(false);
    onCrossClick();
  };
  const commentsInputHandler = (e) => {
    setCurrentComment(e.target.value);
  };
  const commentsSender = (e) => {
    e.preventDefault();
    socket?.emit(
      "activity-comments",
      {
        senderId: userprofileData?.data?._id,
        msg: currentComment,
        activityId: eventDetail?._id,
        createdTimeStamp: Date.now(),
      },
      (res) => {
        if (res.error === 404) {
          toastify("error", res.message);
        }
      }
    );
    setCurrentComment("");
    setTimeout(() => {
      myRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 1000);
  };
  return (
    <>
      {/* ACTIVITY DETAILS AND COMMENT SECTION */}
      <>
        <Modal
          show={showActivityModal}
          onHide={handleClose}
          style={{ paddingRight: "0px !important" }}
          backdrop="static"
          keyboard={false}
          data-dismiss="modal"
        >
          <div className="activity-popup-cross">
            <img
              src="/img/CompositeLayer.png"
              alt="crosss"
              onClick={!loaderForMarkCompleted ? onCrossClick : null}
              style={{ cursor: loaderForMarkCompleted ? "default" : "pointer" }}
            />
          </div>
          <div className="activity-popup-header">
            <div className="event-info">
              <>
                <div className="event-info-and-button">
                  <div className="event-title">
                    {eventDetail?.title}
                    <div className="event-date-time">
                      {moment(eventDetail?.startDate).format("dddd ")},
                      {moment(eventDetail?.startDate).format(" MMM Do ")}
                      {eventDetail?.time?.from}
                      <div style={{ fontSize: "14px" }}>
                        <Chip
                          label={`Goal: ${eventDetail?.goalName}`}
                          style={{
                            backgroundColor: "#1b46b4",
                            color: "white",
                            marginTop: "8px",
                          }}
                        />
                      </div>{" "}
                      <div style={{ fontSize: "14px", display: "flex" }}>
                        {" "}
                        <Chip
                          label={` Journey: ${eventDetail?.attempt}`}
                          style={{
                            backgroundColor: "#56bcb2",
                            color: "white",
                            marginTop: "8px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    {eventDetail?.isCompleted === true ? (
                      <>
                        <Button variant="success" size="sm">
                          Completed
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={(e) => onCheckboxClick(eventDetail)}
                          disabled={loaderForMarkCompleted}
                        >
                          {loaderForMarkCompleted ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            "Mark as completed"
                          )}
                        </Button>
                      </>
                    )}
                  </div>
                </div>

                <div className="description-in-the-activity">
                  {eventDetail?.description ? (
                    <>{parse(eventDetail?.description)}</>
                  ) : null}
                </div>
              </>
            </div>
          </div>
          <Modal.Body>
            <Form className="popup-search-bar">
              <Form.Control
                type="text"
                value={currentComment}
                className="popup-inputfield"
                placeholder="add a comment"
                onChange={commentsInputHandler}
                autoFocus
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <img
                src="/img/send-button-icon-se.png"
                className="send-btn-img"
                alt="send"
                onClick={(e) => commentsSender(e)}
              />
            </Form>
          </Modal.Body>

          {commentList !== null ? (
            commentList?.length ? (
              <div className="comment-container">
                <>
                  <div className="comments-title">
                    <p>Comments ({commentList?.length})</p>
                  </div>
                  <div className="comments-section-container">
                    {commentList?.map((comments, index) => {
                      return (
                        <div
                          key={index}
                          ref={myRef}
                          className="comments-section"
                        >
                          <div className="comments-body">
                            <div className="comments-pic-container">
                              {comments?.imageUrl ? (
                                <img
                                  className="profile-pic"
                                  src={comments?.imageUrl}
                                  alt="img"
                                />
                              ) : (
                                <img
                                  className="profile-pic"
                                  src="/img/sample_img_2.png"
                                  alt="img"
                                />
                              )}
                            </div>
                            <div className="comments-userinfo">
                              <p className="comments-username">
                                {comments?.firstName}
                              </p>
                              <p className="comments-user-activitytime">
                                {moment(comments?.createdAt).fromNow()}
                              </p>
                            </div>
                          </div>
                          <div className="comments-text">
                            <p>{comments?.comment}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              </div>
            ) : (
              <div style={{ margin: "40px 0px 40px 190px" }}>No comments</div>
            )
          ) : (
            <div style={{ margin: "40px 0px 40px 190px" }}>
              Please wait comments are loading ...{" "}
            </div>
          )}
        </Modal>
      </>
      <>
        {/* Awareness content modal*/}
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={dailyContentModal}
          onHide={ondailyContentModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>{contentData?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            {contentData ? <>{parse(contentData?.content)}</> : null}
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={onAddQuestionModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onAddUserhandler}>
                Save
              </Button>
            </Modal.Footer> */}
        </Modal>
      </>
      <>
        {/*Reminder modal*/}
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={reminderModal}
          onHide={onReminderModalClose}
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>Daily reminder</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            {reminderDataInReducer?.data?.p1 && (
              <p>{reminderDataInReducer.data.p1}</p>
            )}
            {reminderDataInReducer?.data?.p2 && (
              <p>{reminderDataInReducer.data.p2}</p>
            )}
            {reminderDataInReducer?.data?.p3 && (
              <p>{reminderDataInReducer.data.p3}</p>
            )}
            {reminderDataInReducer?.data?.activitiesList && (
              <ul>
                {reminderDataInReducer.data.activitiesList.map(
                  (activity, index) => (
                    <li key={index}>{activity}</li>
                  )
                )}
              </ul>
            )}
          </Modal.Body>
        </Modal>
      </>
      <>
        {/* Upcoming content modal*/}
        <Modal
          size="lg"
          backdrop="static"
          className="p-0"
          keyboard={false}
          data-dismiss="modal"
          show={upcomingContentModal}
          onHide={onUpcomingContentModalClose}
          centered
        >
          <Modal.Header className="modal-header-workout" closeButton>
            <Modal.Title>{contentData?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="add-question-modal-body">
            {contentData ? <>{parse(contentData?.content)}</> : null}
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={onAddQuestionModalClose}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onAddUserhandler}>
                Save
              </Button>
            </Modal.Footer> */}
        </Modal>
      </>
      <div className="dashboard pb-5 px-5">
        <div className="goals">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <IconButton></IconButton>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div style={{ width: "90%" }}>
                <Tabs
                  value={activeJourney}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                  sx={{ width: "100%", fontWeight: "bold !important" }}
                >
                  {journeysData?.data?.map((journey, index) => (
                    <Tab
                      key={index}
                      sx={{ fontWeight: "bold", textTransform: "none" }}
                      label={`Journey ${journey?.attempt}`}
                      value={index} // Ensure each Tab has the correct index value
                    />
                  ))}
                </Tabs>
              </div>
              <div style={{ width: "10%" }}>
                <NavLink to="/chat-coach">
                  <Button
                    variant="success"
                    className="btn-coach me-4"
                    onClick={() =>
                      dispatch(authActions.showCoachContainer(true))
                    }
                  >
                    Chat Coach
                  </Button>
                </NavLink>
              </div>
            </div>
            {loaderForJourneyData ? (
              <div
                className="loader-container "
                style={{ margin: "5% 0 5% 0" }}
              >
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </div>
            ) : (
              journeysData?.data?.map((journey, index) => (
                <TabPanel key={index} value={activeJourney} index={index}>
                  <Card className="new-journey-card">
                    {loaderForSelectedJourney ? (
                      <div className="loader-container">
                        <Oval
                          height={30}
                          width={30}
                          color="#1b46b4"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#56bcb2"
                          strokeWidth={6}
                          strokeWidthSecondary={6}
                        />
                      </div>
                    ) : (
                      <>
                        {journey.isGoalSelected === false &&
                          journey.isSurveyCompleted === true && (
                            <>
                              <p>Please select your goals</p>
                              <Button
                                className="survey-button"
                                onClick={() =>
                                  navigate("/survey", {
                                    state: { page: "goalsPage" },
                                  })
                                }
                              >
                                Select Goals
                              </Button>
                            </>
                          )}
                        {journey.isSurveyCompleted === false && (
                          <>
                            <p>Please complete your survey</p>
                            <Button
                              className="survey-button"
                              onClick={() => navigate("/survey")}
                            >
                              {currentJourney?.attempt === 1
                                ? "Take Survey"
                                : "Retake survey"}
                            </Button>
                          </>
                        )}
                        {journey?.isSurveyCompleted === true &&
                          journey.isGoalSelected === true && (
                            <div style={{ width: "100%" }}>
                              <h4 className="d-flex font-weight-bold">
                                {" "}
                                Your Goals
                              </h4>
                              <Card.Body className="dashboard-goals-container p-0">
                                {userDashboardData?.data?.length && (
                                  <>
                                    {userDashboardData?.data?.map(
                                      (val, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="row dashboard-goals-card py-3"
                                          >
                                            <div className="col-1">
                                              <div className="goal-img-rested">
                                                <img
                                                  src="/img/CheckboxTick.png"
                                                  alt="img"
                                                  className="goalDefaultImg"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-11">
                                              <div className="progress-bar-and-goal d-flex justify-content-between mb-1">
                                                <div className="dashboardProgressTitle ">
                                                  {val.title}
                                                </div>
                                                <div className="dashboardProgressDays">
                                                  <span
                                                    style={{
                                                      fontSize: "18px",
                                                      fontWeight: "bolder",
                                                      color: "#1b46b4",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      val.progress
                                                        .toString()
                                                        .split(".")[0]
                                                    }{" "}
                                                    %
                                                  </span>
                                                  {"  "}
                                                  progress in 90 Days
                                                </div>
                                              </div>
                                              <div className="progress">
                                                <div
                                                  className="progress-bar"
                                                  role="progressbar"
                                                  style={{
                                                    width: val.progress + "%",
                                                  }}
                                                  aria-valuenow="25"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </Card.Body>
                            </div>
                          )}
                      </>
                    )}
                  </Card>
                </TabPanel>
              ))
            )}

            <IconButton></IconButton>
          </Box>
        </div>
        {!loaderForJourneyData && (
          <div className="row ">
            <div className="col-md-4">
              <Card className="downcard-main-container">
                <div className="awareness dashboard-downcards">
                  <div className="d-flex cards-header">
                    <img src="/img/aware.png" alt="img" />
                    <h5 className="content-name ms-4 font-weight-bold">
                      Today's Content
                    </h5>
                  </div>
                  {DailyContentData?.data?.todayContents?.length ? (
                    <div>
                      {DailyContentData?.data?.todayContents.map(
                        (data, index) => {
                          return (
                            <h6
                              className="border-bottom daily-content-title d-flex "
                              onClick={() => {
                                if (data?.contentDetail?.title !== undefined) {
                                  onAwarenessContentModal(data?.contentDetail);
                                }
                              }}
                            >
                              {data?.contentDetail?.title
                                ? data?.contentDetail?.title
                                : "No content yet"}
                            </h6>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="mb-5 mt-5"> No content yet</div>
                  )}
                </div>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="downcard-main-container">
                <div className="upcoming dashboard-downcards">
                  <div className="d-flex cards-header">
                    <img src="/img/calendar.png" alt="img" />
                    <h5 className="content-name ms-4 font-weight-bold ">
                      Tomorrow's content
                    </h5>
                  </div>
                  {DailyContentData?.data?.upCommingContents?.length ? (
                    <div>
                      {DailyContentData?.data?.upCommingContents?.map(
                        (data, index) => {
                          return (
                            <h6
                              className="border-bottom daily-content-title d-flex "
                              onClick={() => {
                                if (data?.contentDetail?.title !== undefined) {
                                  onUpcomingContentModal(data?.contentDetail);
                                }
                              }}
                            >
                              {data?.contentDetail?.title
                                ? data?.contentDetail?.title
                                : "No content yet"}
                            </h6>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div className="mb-5 mt-5"> No content yet</div>
                  )}
                </div>
              </Card>
            </div>
            <div className="col-md-4 activities">
              <Card className="downcard-main-container">
                <div className="dashboard-downcards">
                  <div className="d-flex cards-header">
                    <img src="/img/tick.png" alt="img" />
                    <h5 className="content-name ms-4 font-weight-bold">
                      Last Activities
                    </h5>
                  </div>
                  {lastDoneActivities?.data?.length ? (
                    <div>
                      {lastDoneActivities?.data?.map((data, index) => {
                        return (
                          <h6 className="border-bottom daily-content-title d-flex ">
                            {data?.title ? data?.title : "No content yet"}
                          </h6>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="mb-5 mt-5"> No content yet</div>
                  )}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Dashboard;
