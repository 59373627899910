import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { socket } from "../constants/socket";
import * as authActions from "../redux/actions/authAction";
import { toastify } from "../components/Toast";
import { ToastContainer } from "react-toastify";
import { Oval } from "react-loader-spinner";
const CoachNavbar = () => {
  const navigate = useNavigate();

  const [badge, setBadge] = useState(0);
  const [chatBadge, setChatBadge] = useState(0);

  const dispatch = useDispatch();
  const location = useLocation();
  // const { notificationData } = useSelector((state) => state.getNotifications);
  const { userprofileData } = useSelector((state) => state.getProfileData);
  const { user } = useSelector((state) => state.authentication);
  const [counterHide, setCounterHide] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [page, setPage] = useState(1);
  const [loader, setloader] = useState(true);
  const notificationRef = useRef(null);
  const bellButtonRef = useRef(null);
  const limit = 10;
  const observer = useRef();

  useEffect(() => {
    if (page !== 1) {
      dispatch(
        authActions.getAllNotifications(
          page,
          limit,
          setNotificationData,
          callBackForloader
        )
      );
    }
  }, [page]);

  useEffect(() => {
    if (notificationData?.data?.length) {
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...notificationData.data,
      ]);
    }
  }, [notificationData]);
  const callBackForloader = () => {
    setloader(false);
  };
  const lastNotificationElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  let userId = userprofileData?.data?._id;
  useEffect(() => {
    dispatch(authActions.getUserDetails());
  }, []);

  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const bellNotificationHandler = () => {
    const newPage = 1;
    setPage(newPage);
    setloader(true);
    setNotifications([]);
    dispatch(
      authActions.getAllNotifications(
        newPage,
        limit,
        setNotificationData,
        callBackForloader
      )
    );
    let userId = user?.data?.userId;
    socket.emit("mark-notification-seen", userId);
    setNotificationDropDown((prevState) => !prevState);
    setNotifications([]);
    setBadge(0);
  };
  useEffect(() => {}, [notificationDropDown]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const currentPath = location.pathname;
  const handleLogout = () => {
    socket.disconnect();
    handleClose();
    dispatch(authActions.userLogoutRequest(callback));
  };

  const callback = () => {
    navigate("/login");
    socket.removeAllListeners();
  };
  let selectedNotiData;
  var path;
  const onNavigateFromNotifcation = (selectedNotificationData) => {
    dispatch(
      authActions.markNotificationSeen(selectedNotificationData?.chatId?._id)
    );
    if (selectedNotificationData?.NotificationType === "assignCoach") {
      setNotificationDropDown(false);
      setNotifications([]);
      navigate("/chat-client");
      dispatch(
        authActions.markSingleNotificationSeen(
          { notificationId: selectedNotificationData?._id },
          callBackOfUnseenNotification
        )
      );

      return;
    }
    let targetRoute = null;
    if (
      selectedNotificationData.NotificationType === "comment" &&
      selectedNotificationData?.activityId === null
    ) {
      toastify("error", "This activity has been deleted");
      setNotificationDropDown(false);
      setNotifications([]);
      return;
    } else {
      const targetRoute =
        selectedNotificationData.NotificationType === "message"
          ? "/chat-client"
          : selectedNotificationData.NotificationType === "comment"
          ? "/coach-activities"
          : null;

      if (targetRoute) {
        path = targetRoute;
      }
    }

    setNotificationDropDown(false);
    setNotifications([]);
    const selectedNotificationId = {
      notificationId: selectedNotificationData?._id,
    };
    dispatch(
      authActions.markSingleNotificationSeen(
        selectedNotificationId,
        callBackOfUnseenNotification
      )
    );

    if (targetRoute && targetRoute !== currentPath) {
      if (selectedNotificationData?.NotificationType === "message") {
        navigate("/chat-client", {
          state: { selectedNotificationData },
          replace: true,
        });
      } else if (selectedNotificationData?.NotificationType === "comment") {
        // dispatch(
        //   authActions.markNotificationSeen(
        //     selectedNotificationData?.chatId?._id
        //   )
        // );
        if (selectedNotificationData?.activityId === null) {
          toastify("error", "This activity has been deleted");
          setNotifications([]);
          setPage(1);
          dispatch(
            authActions.getAllNotifications(
              page,
              limit,
              setNotificationData,
              callBackForloader
            )
          );
        } else {
          selectedNotiData = selectedNotificationData;
          dispatch(
            authActions.getActivityListByUserId(
              selectedNotificationData?.senderId?._id,
              callBack2
            )
          );
        }
      }
    } else {
      if (selectedNotificationData?.NotificationType === "comment") {
        selectedNotiData = selectedNotificationData;
        if (path && path == currentPath) {
          navigate("/coach-navigator", {
            state: {
              currentPath,
              senderDataFromNotiPage: selectedNotificationData,
              dataFromNav: selectedNotiData,
            },
          });
        } else {
          navigate("/coach-activities", {
            state: {
              senderDataFromNotiPage: selectedNotificationData,
              dataFromNav: selectedNotiData,
            },
          });
        }
      } else {
        navigate("/coach-navigator", {
          state: { currentPath, selectedNotificationData },
        });
      }
    }
  };

  const callBackOfUnseenNotification = () => {
    // dispatch(authActions.getAllNotifications());
  };
  const callBack2 = (resData) => {
    // if (path && path == currentPath) {
    //   navigate("/coach-navigator", {
    //     state: {
    //       currentPath,
    //       dataFromRes: resData,
    //       dataFromNav: selectedNotiData,
    //     },
    //   });
    // } else {
    //   navigate("/coach-activities", {
    //     state: {
    //       dataFromRes: resData,
    //       dataFromNav: selectedNotiData,
    //     },
    //   });
    // }
    // dispatch(authActions.getAllNotifications());
  };

  useEffect(() => {
    socketListnersSubscribe();
    // return () => {
    //   socket.removeAllListeners();
    // };
  }, [socket]);

  async function socketListnersSubscribe() {
    let userId = user?.data?.userId;

    socket.on("notification-bages", (badges) => {
      setBadge(badges);
    });

    socket.on("message-badges", (msgBadges) => {
      setChatBadge(msgBadges);
    });

    socket.on("assign-coach", (data) => {
      // Update store
      // setBadge((prevBadge) => prevBadge + 1);
    });
  }

  const handleClickOutside = (event) => {
    if (
      notificationDropDown &&
      notificationRef.current &&
      !notificationRef.current.contains(event.target) &&
      bellButtonRef.current &&
      !bellButtonRef.current.contains(event.target)
    ) {
      setNotificationDropDown(false);
      setNotifications([]);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [notificationDropDown]);

  const handleNotificationClick = (notificationMappedData) => {
    onNavigateFromNotifcation(notificationMappedData);
  };
  const onCounterHide = () => {
    socket.emit("mark-notification-seen", userId);
    setCounterHide(false);
  };
  const formatDate = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const dateObj = new Date(date);

    if (dateObj.toDateString() === today.toDateString()) {
      return `Today at ${dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`;
    } else if (dateObj.toDateString() === yesterday.toDateString()) {
      return `Yesterday at ${dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`;
    } else {
      return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })}`;
    }
  };
  return (
    <>
      <>
        <ToastContainer />
      </>
      <>
        <div className="nav-top my-3 mx-3 navbar-main-container">
          <div className="row ">
            <div className="col-md-6 d-flex ">
              <div className="main-container-sidebar-btn mr-3 mt-2">
                <Button variant="light" onClick={handleShow}>
                  <i className="fas fa-bars"></i>
                </Button>
                <Offcanvas show={show} onHide={handleClose}>
                  {/* <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header> */}
                  <Offcanvas.Body>
                    <div className="small-size-sidebar-outer">
                      <div className="small-size-main-sidebar">
                        <div className="sidebar-menu">
                          <ul className="list-style-type-none">
                            <li className="text-decoration-none">
                              <NavLink
                                to="/coach-dashboard"
                                className="nav-link text-decoration-none"
                                id="sidebar-dashboard"
                                onClick={handleClose}
                              >
                                <span className="menu-item-icon">
                                  <img src="/img/dashboard.png" />
                                </span>
                                <span className="menu-item-name">
                                  Dashboard
                                </span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/coach-clients"
                                className="nav-link"
                                id="sidebar-dashboard"
                                onClick={handleClose}
                              >
                                <span className="menu-item-icon">
                                  <img src="/img/clock.png" />
                                </span>
                                <span className="menu-item-name">Users</span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/chat-client"
                                className="nav-link"
                                id="sidebar-dashboard"
                                onClick={handleClose}
                              >
                                <span className="menu-item-icon">
                                  <img src="/img/user.png" />
                                </span>
                                <span className="menu-item-name">
                                  Chat with users
                                </span>
                              </NavLink>
                            </li>
                            <li onClick={onCounterHide}>
                              <NavLink
                                to="/coach-notification"
                                className="nav-link"
                                id="sidebar-dashboard"
                                onClick={handleClose}
                              >
                                <span className="menu-item-icon">
                                  <img src="/img/notify.png" />
                                </span>
                                <span className="menu-item-name d-flex">
                                  Notifications
                                  {/* {badge ? (
<div className="notification-counter">

{badge}
</div>
) : null} */}
                                  <div className="notification-header-on-sidebar"></div>
                                </span>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="/coach-profile"
                                className="nav-link"
                                id="sidebar-dashboard"
                                onClick={handleClose}
                              >
                                <span className="menu-item-icon">
                                  <img src="/img/user.png" />
                                </span>
                                <span className="menu-item-name">
                                  My Profile
                                </span>
                              </NavLink>
                            </li>

                            <li>
                              <NavLink
                                to=" "
                                className="nav-link"
                                id="sidebar-dashboard"
                                onClick={handleLogout}
                              >
                                <span className="menu-item-icon">
                                  <img src="/img/logout.png" />
                                </span>
                                <span className="menu-item-name">Logout</span>
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>

              <div className=" ">
                <img
                  src="/img/navbarlog.png"
                  className="align-top"
                  alt="EQWell logo"
                />
              </div>
              {/* <div className="ms-5 mt-3">
      <Form className="searchBarMain">
        <FormControl
          type="search"
          placeholder="Search History"
          className="me-2"
          aria-label="Search"
        />
        <img
          src="/img/Icon feather-se.png"
          className="navbarbarSearchIcon"
          alt="searchImg"
        />
      </Form>
    </div> */}
            </div>
            <div className="col-md-6 d-flex justify-content-end px-5">
              <div className="bell-on-navbar">
                <img
                  ref={bellButtonRef}
                  onClick={bellNotificationHandler}
                  src="/img/bell.png"
                  className="mt-3"
                  alt="img"
                />
                {badge ? (
                  <>
                    {badge > 99 ? (
                      <div className="counter-in-bell-with-99">
                        {" "}
                        <span style={{ fontSize: "15px" }}>99+ </span>
                      </div>
                    ) : (
                      <div className="counter-in-bell"> {badge} </div>
                    )}
                  </>
                ) : null}
              </div>

              <div className="user-img-navbar ms-3">
                {userprofileData?.data?.imageUrl ? (
                  <img
                    className="image"
                    src={userprofileData?.data?.imageUrl}
                    alt="img"
                  />
                ) : (
                  <img
                    className="image"
                    src="/img/sample_img_2.png"
                    alt="img"
                  />
                )}
              </div>
              <div className="welcome-user ms-3">
                <h4 className="font-weight-bold mt-3">
                  Welcome {userprofileData?.data?.firstName}
                </h4>
              </div>
            </div>
          </div>
        </div>

        {notificationDropDown && (
          <div ref={notificationRef} className="notification-container">
            <div className="notification-header">
              <h5>Notifications</h5>
            </div>
            {loader ? (
              <div className="loader-container">
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </div>
            ) : notifications.length === 0 ? (
              <div className="no-data-case">No notifications yet</div>
            ) : (
              <div className="notification-body">
                {notifications?.map((notificationMappedData, index) => {
                  const isLastElement = notifications.length === index + 1;
                  return (
                    <div
                      key={notificationMappedData.id}
                      ref={isLastElement ? lastNotificationElementRef : null}
                      className={
                        notificationMappedData?.isRead
                          ? "notification-userData"
                          : "notification-unseen"
                      }
                      onClick={() =>
                        handleNotificationClick(notificationMappedData)
                      }
                    >
                      <div className="notification-name-time">
                        <div className="notification-userName">
                          {notificationMappedData?.title}
                        </div>
                        <div className="notification-time">
                          {/* {formatDate(
                            new Date(notificationMappedData?.createdAt)
                          )} */}
                          {moment(notificationMappedData.createdAt).fromNow()}
                        </div>
                      </div>
                      <div className="notification-userMsg">
                        {notificationMappedData?.body}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </>
    </>
  );
};
export default CoachNavbar;
