export const USER_SIGNUP = "USER_SIGNUP";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const EMAIL_UNSUBSCRIBE = "EMAIL_UNSUBSCRIBE";
export const GET_BONUS_POINTS = "GET_BONUS_POINTS";
// survey
export const GET_QUESTION_DATA = "GET_QUESTION_DATA";
export const NEXT_QUESTION_DATA = "NEXT_QUESTION_DATA";
export const NEXT_QUESTION_DATA_EMPTY = "NEXT_QUESTION_DATA_EMPTY";
export const GET_JOURNEYS_DATA = "GET_JOURNEYS_DATA";
export const GET_SURVEY_SUGGESTION = "GET_SURVEY_SUGGESTION";
export const SELECT_SURVEY_TYPE = "SELECT_SURVEY_TYPE";
// report
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const GET_DETAIL_DATA = "GET_DETAIL_DATA";
export const GET_SCORE_DATA = "GET_SCORE_DATA";
export const GET_GOALS_DATA = "GET_GOALS_DATA";
// health kit
export const GET_HEALTHKIT_DATA = "GET_HEALTHKIT_DATA";
// dashboard data
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_LAST_DONE_ACTIVITIES = "GET_LAST_DONE_ACTIVITIES";

// show and hide

export const TOGGLE_CHAT_COACH = "TOGGLE_CHAT_COACH";

// activity
export const GET_ACTIVITY_DATA = "GET_ACTIVITY_DATA";
export const GET_SINGLE_ACTIVITY_DATA = "GET_SINGLE_ACTIVITY_DATA";
export const UPDATE_USER_ACTIVITY = "UPDATE_USER_ACTIVITY";
export const GET_USER_GOALS_DATA = "GET_USER_GOALS_DATA";

// profile
export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
//get user assigned coach
export const GET_USER_ASSIGNED_COACH = "GET_USER_ASSIGNED_COACH";
// subscription plan
export const PAYMENT_CHARGE = "PAYMENT_CHARGE";
// subscription resources
export const SUBSCRIPTION_RESOURCES = "SUBSCRIPTION_RESOURCES";
export const TRANSACTION_DETAILS = "TRANSACTION_DETAILS";
export const RESOURCE_GOALS_LIST = "RESOURCE_GOALS_LIST";
// notification
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const MARK_SINGLE_NOTIFICATION_SEEN = "MARK_SINGLE_NOTIFICATION_SEEN";
export const MARK_NOTIFICATION_SEEN = "MARK_NOTIFICATION_SEEN";
//coach
//coach clients
export const GET_COACH_CLIENTS = "GET_COACH_CLIENTS";
export const GET_USER_ENGAGEMENT_BY_JOURNEY = "GET_USER_ENGAGEMENT_BY_JOURNEY";
//coach activity news feed
export const GET_COACH_ACTIVITY_NEWS_FEED = "GET_COACH_ACTIVITY_NEWS_FEED";
// show the coach activities
export const TOGGLE_COACH_ACTIVITIES = "TOGGLE_COACH_ACTIVITIES";
//selected activity data of user
export const GET_SELECTED_ACTIVITY_DATA_OF_USER =
  "GET_SELECTED_ACTIVITY_DATA_OF_USER";
export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
// specific activity list by user(CLIENT) id
export const GET_ACTIVITY_LIST_BY_USERID = "GET_ACTIVITY_LIST_BY_USERID";
// create user activity
export const CREATE_USER_ACTIVITY = "CREATE_USER_ACTIVITY";
// create coach activity
export const CREATE_COACH_ACTIVITY = "CREATE_COACH_ACTIVITY";
// get user goals list
export const USER_GOALS_LIST = "USER_GOALS_LIST";
// get due soon client list
export const DUE_SOON_CLIENT_LIST = "DUE_SOON_CLIENT_LIST";
// get today activity client list
export const TODAY_ACTIVITY_CLIENT_LIST = "TODAY_ACTIVITY_CLIENT_LIST";
// get need attention client list
export const NEED_ATTENTION_CLIENT_LIST = "NEED_ATTENTION_CLIENT_LIST";
// socket reducer
export const SOCKET_SAVER = "SOCKET_SAVER";
// dashboard daily content
export const DAILY_CONTENT = "DAILY_CONTENT";
export const MARK_CONTENT_SEEN = "MARK_CONTENT_SEEN";
// coach engagement
export const GET_COACH_ENGAGEMENTS = "GET_COACH_ENGAGEMENTS";
//user Organization
export const GET_USER_ORGANIZATION = "GET_USER_ORGANIZATION";
// reminder
export const GET_REMINDER_DATA = "GET_REMINDER_DATA";
export const REMINDER_CLOSER = "REMINDER_CLOSER";
export const PERSIST_FILTER_IN_COACH_DASHBOARD =
  "PERSIST_FILTER_IN_COACH_DASHBOARD";
