import React, { useState, useEffect } from "react";
import { Form, Spinner, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import * as authActions from "../../redux/actions/authAction";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer } from "react-toastify";
const NewPassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [password2, setPassword2] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [eye, seteye] = useState(true);
  const [type, settype] = useState("password");
  const [cEye, setCeye] = useState(true);
  const [ctype, setCtype] = useState("password");

  useEffect(() => {
    if (token) {
      dispatch(authActions.userverifyRestRequest(token));
    }
  }, []);
  const values = {
    password: password,
    token: token,
  };
  const onSubmit = () => {
    if (values) {
      dispatch(authActions.userResetPasswordRequest(values, callback));
      setIsLoading(true);
    }
  };
  const callback = (status) => {
    if (status === 200) {
      navigate("/login");
    }
    setIsLoading(false);
  };
  const formSchema = Yup.object().shape({
    password: Yup.string().required("Password is mandatory"),
    confirmPwd: Yup.string()
      .required("Password is mandatory")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);
  const Eye = () => {
    if (type == "password") {
      settype("text");
      seteye(false);
    } else {
      settype("password");
      seteye(true);
    }
  };
  const CEye = () => {
    if (ctype == "password") {
      setCtype("text");
      setCeye(false);
    } else {
      setCtype("password");
      setCeye(true);
    }
  };

  return (
    <>
      <div className="new-password">
        <div className="text-center my-3">
          <img src="/img/logo@2x.png" className="img-fluid" />
        </div>
        <div className="background-screen">
          <div className="d-grid justify-content-center align-items-center h-100">
            {/* {isLoading ? 'null': 'loading'} */}
            <Card className="">
              <Card.Body className="new-password-card-body">
                <div>
                  <h3 className="text-center mb-4 font-weight-bold">
                    Create New Password
                  </h3>
                  <p>
                    Your new password must be different from previously used
                    password.
                  </p>
                  <Form
                    className="new-password-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPassword"
                      required
                    >
                      <Form.Label className="float-left">
                        New Password
                      </Form.Label>
                      <Form.Control
                        {...register("password")}
                        type={type}
                        placeholder="Password"
                        minLength={8}
                        maxLength={30}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <i
                      onClick={Eye}
                      className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    ></i>

                    <p className="mb-0" style={{ color: "red" }}>
                      {errors.password?.message}
                    </p>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPassword"
                      required
                    >
                      <Form.Label className="float-left">
                        Confirm Password
                      </Form.Label>
                      <Form.Control
                        {...register("confirmPwd")}
                        type={ctype}
                        placeholder="Confirm Password"
                        minLength={8}
                        maxLength={30}
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                    </Form.Group>
                    <i
                      onClick={CEye}
                      className={`fa ${cEye ? "fa-eye-slash" : "fa-eye"}`}
                    ></i>
                    <p className="mb-0" style={{ color: "red" }}>
                      {errors.confirmPwd?.message}
                    </p>
                    <div className="text-center new-password-send-btn">
                      {isLoading ? (
                        <button className="btn-login btn-lg">
                          <Spinner
                            className="ps-2"
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </button>
                      ) : (
                        <button
                          variant="primary"
                          type="submit"
                          className="btn-login btn-lg"
                        >
                          Send
                        </button>
                      )}
                    </div>
                  </Form>
                </div>
              </Card.Body>
            </Card>
            <div>
              <p className="text-center text-white">
                {" "}
                &copy; 2024 EQWELL ~ All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default NewPassword;
