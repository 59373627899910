import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { useDispatch } from "react-redux";
import * as authActions from "../redux/actions/authAction";

const PUBLIC_KEY =
  "pk_test_51LGPs7KubzErxkwwNHYtkkiVW3SIoPISCC4R2jJmR9sb09OQvitKaNQ1Lg5UHkW8vSt6rzVwSF50GOX405S9eTFl00vTetkwmf";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.paymentCharge(props?.selectedResource._id));
  }, []);

  return (
    <Elements stripe={stripeTestPromise}>
      <div className="mt-5">
        <PaymentForm
          resourceId={props?.selectedResource._id}
          backBtnHandler={props?.backBtnHandler}
        />
      </div>
    </Elements>
  );
};

export default StripeContainer;
