import * as actionTypes from "../actions/actionType";
const initialState = {
  notificationData: [],
};
export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        notificationData: action.payload,
      };
    
    default:
      return state;
  }
};
