import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Navigator = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedNotificationData] = useState(
    location?.state?.selectedNotificationData
  );

  const [NavigateTo] = useState(location?.state?.currentPath);

  useEffect(() => {
    if (selectedNotificationData && NavigateTo) {
      if (selectedNotificationData?.NotificationType === "comment") {
        navigate("/activities", {
          state: { dataFromNotification: selectedNotificationData },
        });
      } else {
        navigate("/chat-coach", {
          state: { selectedNotificationData },
        });
      }
    }
  }, []);

  return <p>Redirecting...</p>;
};

export default Navigator;
