import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="terms-and-conditions-navbar ">
        <img src="/img/navbarlog.png" className="align-top" alt="EQWell logo" />
      </div>
      <div className="terms-and-conditions-container row">
        <h1>EqWell™ Privacy Policy</h1>
      </div>
      <div className="terms">
        <>
          <p>Last Updated: October 18, 2022</p>
          <p>
            This Privacy Policy (“<strong>Privacy Policy</strong>”) describes
            the way EqWell, Inc., and its affiliates and subsidiaries
            (collectively, “<strong>EqWell</strong>”, “<strong>we</strong>”, “
            <strong>us</strong>” or “<strong>our(s)</strong>”), collects, uses,
            stores, discloses, and shares information about you when you
            interact with EqWell through this website, however accessed (“
            <strong>Website</strong>”), or any mobile applications that we may
            develop (collectively, the “<strong>Site</strong>”).
          </p>
          <p>
            If a separate or different privacy policy applies to a specific app
            or website operated by EqWell rather than this Privacy Policy, we
            will indicate that by posting that separate specific privacy policy
            on that app or other website (each, a “
            <strong>Specific Privacy Policy</strong>”). With respect to that
            other app or website, that Specific Privacy Policy, rather than this
            Privacy Policy, will apply and describe our privacy practices on
            that other app or website. To the extent there is a conflict between
            the terms of this Privacy Policy and the Specific Privacy Policy,
            the terms of the Specific Privacy Policy shall apply to your use of
            that other app or website.
          </p>
          <p>
            <strong>
              By interacting with EqWell through your use of our Site, or your
              use of our services or those of our affiliated and non-affiliated
              wellness providers after having created an account using our Site,
              you expressly and directly consent and agree to the practices set
              forth below.
            </strong>
          </p>
          <h2>For California Consumers</h2>
          <p>
            EqWell does not presently offer its services in California or,
            knowingly, to California Consumers. If you are a California
            Consumer, please contact us so that we may provide you with a
            specific privacy notice for California Consumers.&nbsp;
          </p>
          <h4>
            1.<strong>&nbsp;Information We Collect</strong>
          </h4>
          <p>
            The information we collect from you varies, depending on the way you
            use our Site. We collect information from or about you in the ways
            described below.
          </p>
          <p>
            <em>
              <strong>Personal Information</strong>
            </em>
          </p>
          <p>
            <em>
              <strong>Information You Provide to EqWell</strong>
            </em>
          </p>
          <p>
            You may provide EqWell with information that can identify you as an
            individual (“<strong>Personal Information</strong>”) when using our
            Site. This Personal Information may include, without limitation:
          </p>
          <ul>
            <li>
              <p>
                Contact information (such as your first and last name, email
                address, postal address, and phone number(s));
              </p>
            </li>
            <li>
              <p>Username and password;</p>
            </li>
            <li>
              <p>Age or date of birth;</p>
            </li>
            <li>
              <p>Gender;</p>
            </li>
            <li>
              <p>Social media or other third-party account information;</p>
              <ul>
                <li>
                  <p>
                    You may be able to link your social media or other
                    third-party account information from certain third-party
                    applications, such as Google, when using our Site.
                  </p>
                </li>
                <li>
                  <p>
                    When you link your account to these third-party
                    applications, EqWell requests permission to access your
                    information, including, without limitation, your name,
                    profile picture, gender, networks, user ID, list of friends,
                    and any other public information linked to your account on
                    that application. For more information regarding the
                    connection of your account(s) with third-party applications,
                    please see Section 10 below (Links to Third-Party Websites
                    and Services).
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>Credit or debit card information;</p>
            </li>
            <li>
              <p>Emergency contact information;</p>
            </li>
            <li>
              <p>Information from your contact list, with your permission:</p>
              <ul>
                <li>
                  <p>
                    EqWell may request permission to access your contacts list
                    or email addresses saved on your mobile device for the
                    purposes of enabling you to easily share guest invitations,
                    event invitations and more.
                  </p>
                </li>
                <li>
                  <p>
                    In the “Settings” function on your mobile device, you have
                    the ability to manually permit or preclude EqWell from
                    accessing your contacts and email addresses. You may opt-in
                    or opt-out through the “Settings” function on your mobile
                    device at any time.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Health and wellness information (including, without limitation,
                health conditions, labs and lab results, metabolic conditions,
                and information shared with your personal trainer or
                nutritionist or other wellness provider referred by EqWell);
                fitness and exercise activity data (such as heart rate, watts or
                power, RPM or cadence, and similar metrics);
              </p>
            </li>
            <li>
              <p>Fitness assessments;</p>
            </li>
            <li>
              <p>
                Images (including photographs and video), audio recordings, and
                sounds;
              </p>
            </li>
            <li>
              <p>
                Account, registration or participation information (such as
                information related to your membership or your participation in
                classes, programs, events, and activities);
              </p>
            </li>
            <li>
              <p>
                Your responses to our marketing or operational communications
                (such as your interests, or responses to surveys,
                questionnaires, sweepstakes, or contests);
              </p>
            </li>
            <li>
              <p>
                Information you disclose in public forums or submit, transmit,
                message, upload, share, or otherwise make available, publicly or
                privately, including in online classes, discussion boards or
                forums, training sessions or events, messages, comments,
                searches, or queries, including, without limitation, through the
                use of our Site.
              </p>
            </li>
          </ul>
          <p>
            <em>
              <strong>Employment Applications</strong>
            </em>
          </p>
          <p>
            We collect Personal Information such as your name, address, e-mail,
            and phone number when you apply for employment with us through the
            Site. In addition, we may collect information relating to your
            education and employment history when you submit that information as
            part of an employment application. Not all requested information is
            required, however; and we specifically indicate what information is
            required in order to submit your employment application.
          </p>
          <p>
            <em>
              <strong>
                Other Information We Collect Through Your Technologies
              </strong>
            </em>
          </p>
          <p>
            In addition, EqWell may collect some other information from you when
            using our Site that your technologies may automatically provide to
            EqWell. This other information may not necessarily identify you and
            may include certain information about your interactions with our
            Site or information about your device (collectively “
            <strong>Other Information</strong>”). This Other Information may
            include, without limitation:
          </p>
          <ul>
            <li>
              <p>Internet protocol addresses;</p>
            </li>
            <li>
              <p>Device and browser type;</p>
            </li>
            <li>
              <p>Internet service providers;</p>
            </li>
            <li>
              <p>Operating systems;</p>
            </li>
            <li>
              <p>
                Statistics and other metadata on your activities when using our
                Site;
              </p>
            </li>
            <li>
              <p>
                Information about the pages you visited before or after
                navigating to our Site;
              </p>
            </li>
            <li>
              <p>Information about when you visited our Site;</p>
            </li>
            <li>
              <p>
                Information collected through cookies, web beacons, and other
                technologies as described under “How We Use Cookies and Other
                Tracking Technologies and Interest-Based Advertising” below.
              </p>
            </li>
          </ul>
          <p>
            <em>
              <strong>How We Use Geolocation</strong>
            </em>
          </p>
          <p>
            When you are using our Site, EqWell will request permission to
            obtain your location to better provide you with location-specific
            information, including, but not limited to, facility hours,
            schedules and provider updates, or promotions. In that case, the
            Site will utilize location services on your mobile device to collect
            and use location data, including the real-time geographic location
            of your device. If you consent to the Site’s utilization of location
            services, we may use GPS, Bluetooth, your device’s unique
            identifier, and/or your IP address, along with Wi-Fi and cell tower
            locations, and other technologies to determine your device’s
            geolocation. We maintain location data records for as long as
            necessary to deliver the services you request. We will collect
            geolocation data only if you consent, and you may choose in your
            settings whether the location services will be always running or
            only when the Site is in use. You may also choose to disable
            location services through the “Settings” function of your mobile
            device, but if you do so, you will not receive any notifications or
            services that require a geolocation. You can also contact your
            mobile service carrier or your device manufacturer for more
            information on disabling location services.
          </p>
          <h4>
            2.&nbsp;
            <strong>Purposes for Collection and Use of Information</strong>
          </h4>
          <p>
            EqWell uses your Information to operate and improve its business and
            to provide you goods and services. Generally, we collect and process
            information to serve the operational purpose for which it was
            collected or for other purposes that are compatible with the context
            in which it was collected. The following are the business purposes
            for which we may use your information.
          </p>
          <ul>
            <li>
              <p>
                Providing and delivering our goods and services to you,
                including:
              </p>
              <ul>
                <li>
                  <p>
                    creating and managing your membership or other services
                    (such as the services of wellness professionals to whom we
                    refer you);
                  </p>
                </li>
                <li>
                  <p>completing and processing your transactions with us;</p>
                </li>
                <li>
                  <p>
                    registering you for certain services, such as events,
                    classes, programs or wellness appointments;
                  </p>
                </li>
                <li>
                  <p>
                    sending you service-related notifications and scheduling
                    confirmations;
                  </p>
                </li>
                <li>
                  <p>fulfilling subscriptions and orders;</p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                Improving the Site, goods, and services we offer (including
                through statistical and other analysis), including:
              </p>
              <ul>
                <li>
                  <p>
                    identifying and repairing errors that impair the
                    functionality of our services;
                  </p>
                </li>
                <li>
                  <p>
                    improving our premises by providing personalized or
                    customized experiences, locations, and instructions or
                    assistance;
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>Providing you with customer service;</p>
            </li>
            <li>
              <p>
                Informing you of goods or services you may be interested in;
              </p>
            </li>
            <li>
              <p>
                Informing you of events, special offers, or sweepstakes or
                contests relating to your membership or interactions with us;
              </p>
            </li>
            <li>
              <p>Consider you for employment;</p>
            </li>
            <li>
              <p>
                Improving our security, detecting fraud and auditing our
                processes;
              </p>
            </li>
            <li>
              <p>Complying with legal obligations;</p>
            </li>
            <li>
              <p>
                Communicating with you about our goods and services, including
                your membership, as well as responding to your customer service
                requests or any other inquiry or correspondence you send us; or,
              </p>
            </li>
            <li>
              <p>
                Fulfilling any other purpose for which you provide it or consent
                to its use.
              </p>
            </li>
          </ul>
          <h4>
            3.&nbsp;
            <strong>How we may disclose and/or share your information</strong>
          </h4>
          <p>
            There are some circumstances where we may disclose or share your
            Information with a third party, as described below:
          </p>
          <ul>
            <li>
              <p>
                We may share your information with service providers that
                perform services or functions on behalf of EqWell.
              </p>
              <ul>
                <li>
                  <p>
                    These service providers may include, without limitation,
                    entities that help us with our marketing, analytic service
                    providers, cloud-hosting services, account-related services
                    (including updating of financial information and
                    communications related to collections), database service
                    providers, and backup and disaster recovery service
                    providers.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                We may share your information between and among our affiliated
                entities and with service and wellness providers that perform
                services for you.
              </p>
              <ul>
                <li>
                  <p>
                    EqWell shares your information between and among its
                    affiliated entities and with affiliated and unaffiliated
                    service and wellness providers to facilitate any services
                    you may obtain from EqWell or from the service or wellness
                    providers, to allow them to offer you and let you know about
                    products and services that may interest you, and for any
                    other purpose set forth above.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>With third parties for legal or security reasons:</p>
              <ul>
                <li>
                  <p>
                    We may share information about you if we reasonably believe
                    that disclosing the information is needed to
                  </p>
                  <ul>
                    <li>
                      <p>
                        comply with any valid legal process, governmental
                        request, or applicable law, rule, or regulation;
                      </p>
                    </li>
                    <li>
                      <p>
                        investigate, remedy, or enforce potential violations of
                        our Terms of Use or other agreements or policies;
                      </p>
                    </li>
                    <li>
                      <p>
                        protect the rights, property, and safety of us, our
                        users, or others; or,
                      </p>
                    </li>
                    <li>
                      <p>Detect and resolve any fraud or security concerns.</p>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <p>
                With third parties as part of an acquisition or liquidation:
              </p>
              <ul>
                <li>
                  <p>
                    If we are involved in a merger, asset sale, financing,
                    corporate divestiture, reorganization, or acquisition of all
                    or some portion of our business to another company or if we
                    undergo liquidation or bankruptcy proceedings, we may share
                    your information in connection with such transaction or
                    proceeding before and/or after the transaction closes or the
                    proceedings are completed.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                We may disclose or share your personal information in connection
                with an employment application to verify the information you
                provide or in accordance with any relevant notice we provide
                relating to your employment application.
              </p>
            </li>
            <li>
              <p>
                We may share information in other ways if you give us consent or
                direct us to do so.
              </p>
            </li>
            <li>
              <p>
                We do not share personal information with third parties for
                their marketing purposes.
              </p>
            </li>
          </ul>
          <h4>
            4.&nbsp;<strong>How we protect your information</strong>
          </h4>
          <p>
            We have implemented reasonable security measures with respect to
            your information. Please be aware that, despite our efforts, no data
            security measures can guarantee security and confidentiality in all
            instances. There are also steps you can take to protect your
            information by, for example, keeping your account passwords secure.
            To the extent that you provide us with protected health information
            (within the meaning of the HIPAA Security Rule), we protect that
            information as required by HIPAA.
          </p>
          <h4>
            5.&nbsp;
            <strong>
              Your access to and choices regarding your personal information
            </strong>
          </h4>
          <p>
            You can access and update certain of your account information on our
            Site by clicking on “Account.” If you need to access or update other
            personal information that we may have, you can make a request by
            calling us at [*] to speak with an account services representative.
            To protect your privacy, before we give you access or let you update
            your information, we may we may ask you to verify your identity or
            provide additional information. We will try to update and allow you
            to access your information for free, but if it would require a
            disproportionate effort on our part, we may charge a fee. We will
            disclose the fee before we comply with your request. We may reject a
            request for a number of reasons, including, for example, that the
            request risks the privacy of other users, requires technical efforts
            that are disproportionate to the request, is repetitive, or is
            unlawful.
          </p>
          <h4>
            6.&nbsp;<strong>Retention of your personal information</strong>
          </h4>
          <p>
            We may retain Personal Information for as long as necessary to
            fulfill our business purposes and as is required or permitted under
            applicable laws.
          </p>
          <h4>
            7.&nbsp;
            <strong>
              How we use cookies and other tracking technologies and
              interest-based advertising
            </strong>
          </h4>
          <p>
            <em>
              <strong>What are Cookies and Web Beacons?</strong>
            </em>
          </p>
          <p>
            Cookies are small data files placed onto your device when you visit
            a site which allows our services to distinguish you from other
            users, allow the services to work properly, as well as to help
            monitor advertising effectiveness. Web beacons work similarly to
            cookies; however, instead of a file stored on your computer, web
            beacons are embedded invisibly on web pages. We use cookies, web
            beacons, tags, and other similar tracking technologies to track when
            you visit our websites and mobile applications (collectively, “
            <strong>Cookies</strong>”). We also may allow third parties to place
            Cookies on our services to assist with advertising.
          </p>
          <p>
            <em>
              <strong>How We Use Cookies</strong>
            </em>
          </p>
          <p>
            There are several reasons we use Cookies. Some Cookies are necessary
            for technical reasons and help our services to operate as intended
            for your benefit (“<strong>Essential Cookies</strong>”), such as
            access to secure areas of the services. Other types of Cookies are
            used for analytical purposes such as evaluating the way our services
            are used, or the effectiveness of our advertising, and to customize
            or personalize advertising to you (“
            <strong>Analytical Cookies</strong>”). While these types of Cookies
            help us to create new features, improve on our existing services,
            and provide advertisements of interest to you, our services can
            function without them.
          </p>
          <p>
            <em>
              <strong>
                Controlling Cookies and Interest-Based Advertising
              </strong>
            </em>
          </p>
          <p>
            You have the right to accept or reject Cookies. You can exercise
            your Cookie preferences by activating the setting on your web
            browser that allows you to refuse the setting of all or some
            Cookies. However, if you use your browser settings to block all
            Cookies, including Essential Cookies, you may not be able to access
            or use all or parts of our services. If you have not adjusted your
            browser setting to refuse cookies, our system will issue Cookies as
            soon as you visit our services. Blocking or deleting Cookies will
            not prevent device identification and related data collection from
            occurring when you access our services. Turning off Analytical
            Cookies will prevent the ability to measure the relevance and
            effectiveness of our services, emails, and marketing as well as to
            show you tailored marketing. Other marketing will still be displayed
            when you use our services.
          </p>
          <p>
            <em>
              <strong>How We Use Google Analytics</strong>
            </em>
          </p>
          <p>
            We work with service providers (including Google Analytics) who
            conduct analytics to help us track and understand how visitors use
            our Site. &nbsp;Google Analytics is a web analytics service provided
            by Google that uses cookies to help us analyze how users use our
            Site. The information generated by the cookies about your use of the
            services will be transmitted to and stored by Google on servers in
            the United States. If you access our Site through different devices,
            Google may associate your devices with one another. Google has
            developed the Google Analytics opt-out browser add-on which you may
            download. Please note that the add-on does not prevent information
            from being sent to EqWell.
          </p>
          <p>
            <em>
              <strong>Interest-Based Advertising</strong>
            </em>
          </p>
          <p>
            Some companies may use information collected on our Site to deliver
            advertisements on our behalf or on behalf of other companies,
            targeted to your interests and preferences, on our Site or on other
            websites or applications you may visit, or on your devices, or on
            your social media feed and to gauge effectiveness.&nbsp;
          </p>
          <h4>
            8.&nbsp;
            <strong>
              No collection of personal information from children under 13
            </strong>
          </h4>
          <p>
            EqWell’s Site is not targeted to children under 13 years old, and it
            does not intend to collect Personal Information from children under
            the age of thirteen (13). If you are aware of a user under the age
            of thirteen (13) who has provided Personal Information to EqWell,
            please contact us. Parents and legal guardians may choose to provide
            information about their children, even if under the age of thirteen
            (13), for the purposes of using our products and services,
            including, without limitation, the Site.
          </p>
          <h4>
            9.&nbsp;<strong>Do not track mechanisms</strong>
          </h4>
          <p>
            Our Site does not support Do Not Track (“DNT”) at this time, and so,
            we currently do not honor DNT signals.
          </p>
          <h4>
            10.&nbsp;<strong>Links to third-party websites and services</strong>
          </h4>
          <p>
            By using our Site, you may be able to link to other websites,
            including social media platforms, or apps (e.g., Facebook,
            Instagram, twitter, etc.) (“<strong>Third-Party Sites</strong>”). We
            do not control any Third-Party Sites and are not responsible for the
            contents, nature, or privacy practices of any Third-Party Sites. Any
            access to and use of the Third-Party Sites is not governed by this
            Privacy Policy, but instead is governed by the privacy policies of
            those Third-Party Sites. If you choose to interact with the
            Third-Party Sites, you should review the applicable privacy policy,
            as the Third-Party Sites’ privacy practices may differ from
            ours.&nbsp;
            <strong>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ARE NOT RESPONSIBLE FOR THE PRIVACY PRACTICES OF ANY THIRD-PARTY SITES.
            </strong>
          </p>
          <h4>
            11.&nbsp;<strong>Changes to this privacy policy</strong>
          </h4>
          <p>
            EqWell reserves the right to update or modify this Privacy Policy at
            any time. If we make changes, we will post the revised Privacy
            Policy to our website with a “Last Updated” date at the top of this
            Privacy Policy reflecting the date of the change. All changes are
            effective as of the time of posting. You should periodically check
            our website to review any updated Privacy Policy. Without limitation
            to the effectiveness of the changes, your use of our Site following
            the posting of the revised Privacy Policy constitutes acceptance of
            those changes.
          </p>
          <h4>
            12.&nbsp;<strong>Other privacy-related policies</strong>
          </h4>
          <p>
            As our business grows and we add customers in additional states, we
            will provide links to state-specific privacy notices.&nbsp;
          </p>
          <h4>
            13.&nbsp;<strong>Contacting Us</strong>
          </h4>
          <p>
            If you have concerns, questions or comments about this Privacy
            Policy, please contact us at EqWell, Inc., [address].
          </p>
        </>
      </div>
    </>
  );
};

export default PrivacyPolicy;
