import * as actionTypes from "../actions/actionType";
const initialState = {
  paymentCharge: {},
  getAllResources: [],
  userOrganizationInfo: [],
  transactionDetailsData: [],
  getResourcesGoals: [],
};
export const paymentChargeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_CHARGE:
      return {
        ...state,
        paymentCharge: action.payload,
      };
    case actionTypes.TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetailsData: action.payload,
      };
    case actionTypes.SUBSCRIPTION_RESOURCES:
      return {
        ...state,
        getAllResources: action?.payload,
      };
    case actionTypes.GET_USER_ORGANIZATION:
      return {
        ...state,
        userOrganizationInfo: action.payload,
      };
    case actionTypes.RESOURCE_GOALS_LIST:
      return {
        ...state,
        getResourcesGoals: action.payload,
      };
    default:
      return state;
  }
};
