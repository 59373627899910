import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { surveyReducer } from "../reducers/surveyReducer";
import { healthKitReducer } from "./healthKitReducer";
import { dashboardReducer } from "./dashboardReducer";
import { activityReducer } from "./activityReducer";
import { profileReducer } from "./profileReducer";
import { paymentChargeReducer } from "./subscriptionReducer";
import { notificationReducer } from "./notificationReducer";
import { coachClientsReducer } from "./coachClientsReducer";
import { coachActivityNewsFeedReducer } from "./coachActivityNewsFeedReducer";
import { coachDashboardReducer } from "./coachDashboardReducer";
import { socketSaverReducer } from "./socketSaverReducer";

const appReducer = combineReducers({
  getSocketData: socketSaverReducer,
  authentication: authReducer,
  getQuestion: surveyReducer,
  gethealthData: healthKitReducer,
  getDashboardData: dashboardReducer,
  getActivityData: activityReducer,
  getProfileData: profileReducer,
  getPaymentdata: paymentChargeReducer,
  getNotifications: notificationReducer,
  getClientsData: coachClientsReducer,
  getCoachActivityNewsFeed: coachActivityNewsFeedReducer,
  getCoachDashboardData: coachDashboardReducer,

});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
