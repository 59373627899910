import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { socket } from "../constants/socket";
import * as authActions from "../redux/actions/authAction";

const ChatCoach = () => {
  const myRef = useRef();
  // const dispatch = useDispatch();
  const location = useLocation();
  const [selectedChatUserId, setSelectedChatUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatUIState, setChatUIState] = useState({
    chatDropdownOpen: false,
    addSingleUserCheck: false,
    addMultiUserCheck: false,
    addUserModal: false,
    singleUser: null,
    multipleUsers: [],
    chatList: [],
    selectedChatUser: {},
    groupName: "",
  });
  const chatContainerRef = useRef(null);

  const [message, setMessage] = useState("");
  const [scrollUpdater, setScrollUpdater] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [loadingOnSendBtn, setLoadingOnSendBtn] = useState(false);
  const [targetMessageId, setTargetMessageId] = useState(null);
  const [infoFromNotification] = useState(
    location?.state?.selectedNotificationData
  );
  const [count, setCount] = useState(0);
  // const [userId, setUserId] = useState("");
  const { userAssignedCoach } = useSelector((state) => state.getDashboardData);
  const { user } = useSelector((state) => state?.authentication);

  const inputMessageHandler = (e) => {
    setMessage(e.target.value);
  };

  const handleKeypress = (e) => {
    if (!loadingOnSendBtn) {
      if (e.key === "Enter") {
        onSendBtnHandler();
      }
    }
  };

  const onSendBtnHandler = () => {
    if (!message || message.trim() === "") {
      return;
    }
    setLoadingOnSendBtn(true);

    if (
      !chatUIState.selectedChatUser?.chatId?.isGroup &&
      !chatUIState.selectedChatUser?.isGroup
    ) {
      socket?.off("private message");

      socket?.emit("private message", {
        receiverId:
          chatUIState.selectedChatUser?.senderId?._id ||
          chatUIState.selectedChatUser?.chatDetails?._id,
        senderId: user?.data?.userId,
        msg: message,
        createdTimeStamp: Date.now(),
      });
    } else {
      socket?.off("message-to-group");
      socket.emit("message-to-group", {
        chatId:
          chatUIState.selectedChatUser?.chatId?._id ||
          chatUIState.selectedChatUser?._id,
        senderId: user?.data?.userId,
        msg: message,
        createdTimeStamp: Date.now(),
      });
    }
    setMessage("");
  };

  useEffect(() => {
    if (infoFromNotification) {
      loadChatFromNotificationsState(infoFromNotification);
    }
    return () => {};
  }, [infoFromNotification]);
  const loadChat = (chat) => {
    setMessage("");

    setSelectedChatUserId(chat._id);

    setChatUIState((prev) => ({
      ...prev,
      selectedChatUser: chat,
    }));

    if (!chat.isGroup) {
      socket?.off("load-messages");
      socket.emit(
        "load-messages",
        chat?.receiverId?._id || chat?.chatDetails?._id,
        user?.data?.userId
      );
    } else {
      socket?.off("load-group-messages");
      socket.emit(
        "load-group-messages",
        chat.chatId || chat._id,
        user?.data?.userId
      );
    }
    socket.off("new-messages");
    populateChat(chat._id);
  };

  const loadChatFromNotificationsState = (chat) => {
    setLoading(true);
    setChatUIState((prev) => ({
      ...prev,
      selectedChatUser: chat,
    }));

    if (!chat?.chatId?.isGroup) {
      socket?.off("load-messages");
      socket.emit("load-messages", chat?.senderId?._id, user?.data?.userId);
    } else {
      socket?.off("load-group-messages");
      socket.emit("load-group-messages", chat?.chatId?._id, user?.data?.userId);
    }
    socket.off("new-messages");

    populateChat(chat.chatId?._id);
    setTargetMessageId(chat.messageId?._id);
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const populateChat = (idFromChatId) => {
    socket?.off("new-messages");
    socket.on("new-messages", (newMsg) => {
      if (!newMsg[0]?.chatId || newMsg[0]?.chatId === idFromChatId) {
        setAllMessages(filterMessages(newMsg) || []);
        setScrollUpdater(!scrollUpdater);
      }
      setLoading(false);
      setLoadingOnSendBtn(false);
    });
  };

  useEffect(() => {
    // Load Chats
    socket.emit(
      "load-user-chats",
      {
        loggedInUserId: user?.data?.userId,
      },
      (chats) => {
        setChatUIState((prevState) => ({
          ...prevState,
          chatList: chats,
        }));
      }
    );

    // receive chats
    socket.on("chat-list", (chats) => {
      setChatUIState((prevState) => ({
        ...prevState,
        chatList: chats,
      }));
    });
    return () => {};
  }, [user?.data?.userId]);

  useEffect(() => {
    return () => {
      socket.off("new-messages");
      // socket.off("new-notification");
    };
  }, []);

  const filterMessages = (messages) => {
    const filteredMessages = [];
    const uniqueDates = {};

    messages.forEach((message, index) => {
      const date = new Date(message.createdAt).toISOString().split("T")[0];

      if (index === 0 || !uniqueDates[date]) {
        uniqueDates[date] = true;
        filteredMessages.push({ dateSeparator: date });
      }

      filteredMessages.push(message);
    });

    return filteredMessages.reverse();
  };

  const formatDate = (date) => {
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Adding leading zero if needed
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    if (count !== 0) {
      // myRef?.current?.scrollIntoView({
      //     behavior: "smooth",
      //     block: "end",
      //     inline: "nearest",
      //   });
      return;
    }

    if (targetMessageId) {
      const timeoutId = setTimeout(() => {
        const element = document.getElementById(
          `message-bubble-${targetMessageId}`
        );
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          element.classList.add("highlight");

          setTimeout(() => {
            element.classList.remove("highlight");
          }, 5000);
          setCount(1);
        }
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, [targetMessageId, allMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [scrollUpdater]);

  return (
    <div className="chat-coach user-profile">
      <div className="row">
        <div className="chat-client-header">
          <h1>Chats</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <Card
            style={{
              // height: "675px",
              height: "550px",

              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            {chatUIState.chatList.length === 0 ? (
              <h5
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  width: "100%",
                  top: "40%",
                }}
              >
                No Chats Yet.
              </h5>
            ) : (
              <>
                {/* {chatUIState.chatList.map((chat, idx) => ( */}
                {chatUIState.chatList
                  .sort(
                    (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
                  )
                  .map((chat, idx) => (
                    <div
                      key={idx}
                      className="chat-list-card w-100 d-flex align-items-center justify-content-start gap-3 p-3"
                      onClick={() => {
                        loadChat(chat);
                      }}
                      style={{
                        backgroundColor:
                          (chat?._id ===
                            chatUIState.selectedChatUser?.chatId?._id ||
                            chat?._id === chatUIState.selectedChatUser?._id) &&
                          "#1b46b4",
                        color:
                          (chat?._id ===
                            chatUIState.selectedChatUser?.chatId?._id ||
                            chat?._id === chatUIState.selectedChatUser?._id) &&
                          "#FFFFFF",
                        cursor: "pointer",
                      }}
                    >
                      {chat?.chatDetails?.imageUrl ? (
                        <img
                          className="coach-clients-data-img "
                          src={chat?.chatDetails?.imageUrl}
                          alt="img"
                        />
                      ) : (
                        <img
                          src={
                            !chat?.isGroup
                              ? "/img/sample_img_2.png"
                              : "/img/group-logo-placeholder.png"
                          }
                          className="coach-clients-data-img "
                          alt="EQWell logo"
                        />
                      )}

                      <p className="m-0 clients-name-chatlist">
                        <strong>
                          {chat?.chatDetails?.firstName}{" "}
                          {chat?.chatDetails?.lastName}
                        </strong>
                      </p>
                    </div>
                  ))}
              </>
            )}
          </Card>
        </div>

        {chatUIState.selectedChatUser?._id && (
          <div className="col-md-9">
            <Card
              style={{
                // height: "675px",
                height: "550px",
              }}
            >
              <Card.Header className="chat-client-username  d-flex">
                <p className="font-weight-bold mt-3 ml-2">
                  {chatUIState.selectedChatUser?.chatDetails?.firstName}{" "}
                  {chatUIState.selectedChatUser?.chatDetails?.lastName}
                </p>
                {chatUIState.selectedChatUser?.chatId?.isGroup && (
                  <p className="font-weight-bold mt-3 ml-2">
                    {chatUIState.selectedChatUser?.chatId?.name}
                  </p>
                )}
                {!chatUIState.selectedChatUser?.chatId?.isGroup && (
                  <p className="font-weight-bold mt-3 ml-2">
                    {chatUIState.selectedChatUser?.senderId?.firstName}{" "}
                    {chatUIState.selectedChatUser?.senderId?.lastName}
                  </p>
                )}
              </Card.Header>
              <Card.Body className="card-body-chat">
                <div className="">
                  <div className="chat-conversation ">
                    <ul className="list-unstyled">
                      <div
                        className="scrollbar"
                        style={{ height: "400px" }}
                        ref={chatContainerRef}
                      >
                        {" "}
                        {allMessages.map((item, index) => {
                          if (item.dateSeparator) {
                            return (
                              <li key={index}>
                                <div className="separator">
                                  <div className="line"></div>
                                  <span>
                                    {moment(item.dateSeparator).format("ll")}
                                  </span>
                                  <div className="line"></div>
                                </div>
                              </li>
                            );
                          } else {
                            const messageId = item._id;
                            return (
                              <li key={index} id={`message-${messageId}`}>
                                <div className="conversation-list">
                                  <div className="">
                                    {item?.user?._id === user?.data?.userId ? (
                                      <div className="sender-message-bubble media ml-auto">
                                        {chatUIState.selectedChatUser
                                          ?.isGroup && (
                                          <p
                                            style={{
                                              color: "grey",
                                              margin: "0px",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {item?.user?.firstName}{" "}
                                            {item?.user?.lastName}
                                          </p>
                                        )}
                                        <div className="media-body">
                                          <div
                                            className="sender-msg py-2 px-3"
                                            id={`message-bubble-${messageId}`}
                                          >
                                            <p className="text-small mb-0 text-white">
                                              {item.text}
                                            </p>
                                          </div>
                                          <p className="message-time small text-muted d-flex">
                                            {formatDate(
                                              new Date(item.createdAt)
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="w-50">
                                        {chatUIState.selectedChatUser
                                          ?.isGroup && (
                                          <p
                                            style={{
                                              color: "grey",
                                              margin: "0px",
                                              fontSize: "12px",
                                              textAlign: "left",
                                            }}
                                          >
                                            {item?.user?.firstName}{" "}
                                            {item?.user?.lastName}
                                          </p>
                                        )}
                                        <div className="media mb-3">
                                          <div className="reciever-message-bubble media-body ml-3">
                                            <div
                                              className="rcvr-msg py-2 px-3"
                                              id={`message-bubble-${messageId}`}
                                            >
                                              <p className="text-small mb-0 text-white text-justify">
                                                {item.text}
                                              </p>
                                            </div>
                                            <p className="message-time small text-muted d-flex">
                                              {formatDate(
                                                new Date(item.createdAt)
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        })}
                      </div>
                    </ul>
                    <div className=" chat-input-section">
                      <Row>
                        <Col>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control chat-input"
                              placeholder="Enter Message..."
                              onChange={inputMessageHandler}
                              value={message}
                              onKeyDown={handleKeypress}
                              autoFocus
                              // disabled={loadingOnSendBtn}
                            />
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            type="button"
                            style={{ backgroundColor: "#1b46b4 !important" }}
                            className="btn  btn-rounded chat-send w-md "
                            onClick={onSendBtnHandler}
                            disabled={loadingOnSendBtn}
                          >
                            {loadingOnSendBtn ? (
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              <>
                                <span className="d-none d-sm-inline-block me-2">
                                  Send
                                </span>
                                <i className="mdi mdi-send" />
                              </>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatCoach;
