import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
const HealthKit = () => {
  const dispatch = useDispatch();
  const { userdata } = useSelector((state) => state.gethealthData);
  useEffect(() => {
    dispatch(authActions.gethealthKitData());
  }, []);
  const convertMinutesToHoursAndMinutes = (data) => {
    const hours = Math.floor(parseFloat(data));
    const minutes = Math.round((parseFloat(data) - hours) * 60);
    return `${isNaN(hours) ? 0 : hours} hrs ${
      isNaN(minutes) ? 0 : minutes
    } min`;
  };
  return (
    <>
      <div className="health-kit py-5 px-5">
        <div className="row mb-3">
          <div className="col-md-6">
            <h1 className="healthKitUpdateHeaderFont d-flex font-weight-bold">
              Health Insights
            </h1>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            {/* <div>
              <img src="/img/your-logo.png" alt="img" />
            </div> */}
          </div>
        </div>

        <div className="row main-health-container">
          <div className="col-md-4 pt-3">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Steps
                  </h5>
                </div>
                <img
                  className="Steps-icon mx-0 mt-3"
                  src="/img/footsteps.png"
                  alt="img"
                />

                <h5 className="text-center mt-2  font-weight-bold">
                  {userdata?.data?.stepCount === 0
                    ? ""
                    : userdata?.data?.stepCount?.toString().split(".")[0]}{" "}
                  {userdata?.data?.stepCount ? "" : "0"}
                </h5>
                {/* <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "40%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div> */}
                {/* <p className="text-center pt-3">50% of your goals</p> */}
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Weight
                  </h5>
                </div>
                <img
                  className="weight-icon mx-0 py-3"
                  src="/img/measure.png"
                  alt="img"
                />

                <h5 className="user-weight font-weight-bold">
                  {isNaN(userdata?.data?.weight)
                    ? "0 lbs"
                    : Math.round(userdata?.data?.weight)}
                  <span>{isNaN(userdata?.data?.weight) ? "" : " lbs"}</span>
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Heart rate
                  </h5>
                </div>
                <img
                  className="Heart-rate-icon mx-0 mt-2"
                  src="/img/heart.png"
                  alt="img"
                />
                <h5 className="text-center pt-3 font-weight-bold">
                  {userdata?.data?.heartRate?.toString().split(".")[0]}{" "}
                  {userdata?.data?.heartRate ? "bpm" : "0 bpm"}
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Blood Pressure
                  </h5>
                </div>
                <img
                  className="Bloodpressure-icon mx-0 py-2"
                  src="/img/bp.png"
                  alt="img"
                />
                <h5 className="text-center pt-2 mb-2 font-weight-bold">
                  {userdata?.data?.bloodPressure?.systolic
                    ?.toString()
                    .split(".")[0]
                    ? userdata?.data?.bloodPressure?.systolic
                        ?.toString()
                        .split(".")[0]
                    : "0"}
                  {" / " }
                  {userdata?.data?.bloodPressure?.diastolic
                    ?.toString()
                    .split(".")[0]
                    ? userdata?.data?.bloodPressure?.diastolic
                        ?.toString()
                        .split(".")[0]
                    : "0"}{" "}
                  mmHg
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Active energy
                  </h5>
                </div>
                <img
                  className="weight-icon mx-0 py-3"
                  src="/img/energy.png"
                  alt="img"
                />

                <h5 className="user-weight font-weight-bold">
                  {userdata?.data?.activeEnergyData?.toString().split(".")[0]
                    ? userdata?.data?.activeEnergyData?.toString().split(".")[0]
                    : "0"}
                  <span> cal</span>
                </h5>
              </div>
            </Card>
          </div>

          <div className="col-md-4 pt-3">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Cycling
                  </h5>
                </div>
                <img className="mx-0 " src="/img/cycling.png" alt="img" />
                <h5 className="text-center mt-2 font-weight-bold">
                  {userdata?.data?.cyclingData?.toString().split(".")[0]
                    ? userdata?.data?.cyclingData?.toString().split(".")[0]
                    : "0"}{" "}
                  miles
                </h5>
              </div>
            </Card>
          </div>

          <div className="col-md-4 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Swimming
                  </h5>
                </div>
                <img
                  className="weight-icon mx-0 py-3"
                  src="/img/swimming.png"
                  alt="img"
                />

                <h5 className="user-weight font-weight-bold">
                  {/* {userdata?.data?.swimmingData?.toString().split(".")[0]} */}
                  {userdata?.data?.swimmingData
                    ? userdata?.data?.swimmingData?.toString().split(".")[0]
                    : "0"}
                  <span> meters</span>
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Walking
                  </h5>
                </div>
                <img
                  className="weight-icon mx-0 py-3"
                  src="/img/walking.png"
                  alt="img"
                />

                <h5 className="user-weight font-weight-bold">
                  {userdata?.data?.walkingData?.toString().split(".")[0]
                    ? userdata?.data?.walkingData?.toString().split(".")[0]
                    : "0"}{" "}
                  <span> miles</span>
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Mindful minutes
                  </h5>
                </div>
                <img
                  className="weight-icon mx-0 py-3"
                  src="/img/mindfulness.png"
                  alt="img"
                />

                <h5 className="user-weight font-weight-bold">
                  {userdata?.data?.mindfullMinutes?.toString().split(".")[0]
                    ? userdata?.data?.mindfullMinutes?.toString().split(".")[0]
                    : "0"}{" "}
                  <span>hrs</span>
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3 weight">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Workout
                  </h5>
                </div>
                <img
                  className="weight-icon mx-0 py-3"
                  src="/img/workout.png"
                  alt="img"
                />

                <h5 className="user-weight font-weight-bold">
                  <span>
                    {convertMinutesToHoursAndMinutes(
                      userdata?.data?.workoutData
                    )}
                  </span>
                </h5>
              </div>
            </Card>
          </div>
          <div className="col-md-4 pt-3">
            <Card>
              <div className="p-3">
                <div className="d-flex">
                  <h5 className="healthKitUpdateCardsFont font-weight-bold">
                    Sleep
                  </h5>
                </div>
                <img
                  className="Sleep-icon mx-0 pt-3"
                  src="/img/sleep.png"
                  alt="img"
                />
                <h5 className="text-center pt-3 mb-3 font-weight-bold">
                  <span style={{ paddingRight: "20px" }}>In bed</span>
                  {isNaN(
                    new Date(userdata?.data?.sleepTime?.endDate).getHours() -
                      new Date(userdata?.data?.sleepTime?.startDate).getHours()
                  )
                    ? 0
                    : new Date(userdata?.data?.sleepTime?.endDate).getHours() -
                      new Date(
                        userdata?.data?.sleepTime?.startDate
                      ).getHours()}{" "}
                  hrs
                </h5>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default HealthKit;
