import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import cloneDeep from "lodash/cloneDeep";
import { Oval } from "react-loader-spinner";
import Badge from "react-bootstrap/Badge";
import CoachDashboardInputFields from "../../../components/CoachDashboardInputFields";
import { socket } from "../../../constants/socket";

const CoachDashboard = () => {
  const [activityData, setActivityData] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [comment, setComment] = useState("");
  const [userId, setUserId] = useState("");
  const [coachData, setCoachData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const persistFilterState = useSelector(
    (state) => state?.getCoachDashboardData?.persistFilter
  );

  const dispatch = useDispatch();
  const myRef = useRef(null);

  const today = new Date();
  useEffect(() => {
    setLoading(true);
    dispatch(authActions.getCoachActivityNewsFeed());
    dispatch(authActions.getDueSoonClientList());
    dispatch(authActions.getTodayActivityClientList());
    dispatch(authActions.getNeedAttentionClientList());
    dispatch(authActions.getCoachEngagements());
    dispatch(authActions.getUserDetails());

    socketConnectionFun();
  }, []);
  const { coachActivityNewsFeedData } = useSelector(
    (state) => state.getCoachActivityNewsFeed
  );
  const { coachClientsData } = useSelector((state) => state.getClientsData);
  const { user } = useSelector((state) => state.authentication);
  const { userprofileData } = useSelector((state) => state.getProfileData);
  const myRef2 = useRef(coachActivityNewsFeedData);
  useEffect(() => {
    socket.emit("notification-count", userprofileData?.data?._id);
  }, [userprofileData]);
  useEffect(() => {
    if (coachActivityNewsFeedData?.data !== undefined) {
      let rrr = [...coachActivityNewsFeedData?.data];
      setActivityData(rrr);
    }
  }, [coachActivityNewsFeedData]);
  const {
    dueSoonClientList,
    todayActivityClientList,
    needAttentionClientList,
    coachEngagementsData,
  } = useSelector((state) => state.getCoachDashboardData);

  const socketConnectionFun = () => {
    socket.on("new-notification", (notification) => {});
  };

  const sendCommentBtn = (id, str) => {
    if (str !== "") {
      socket?.emit("activity-comments", {
        senderId: user?.data?.userId,
        msg: str,
        activityId: id,
        createdTimeStamp: Date.now(),
      });
    }
  };
  // const updateComments = (newComment) => {
  //   let findIndex = coachActivityNewsFeedData?.data?.findIndex(
  //     (e) => e._id == newComment.activityId
  //   );
  //   // if (findIndex !== -1) {
  //   //   let item = coachActivityNewsFeedData?.data[findIndex];
  //   //   item.activityComments = newComment.messages;
  //   //   let newArray = cloneDeep(coachActivityNewsFeedData?.data);
  //   //   setCoachData(newArray);
  //   //   setLoading(false);
  //   // }
  //   if (findIndex !== -1) {
  //     let newArray = cloneDeep(coachActivityNewsFeedData?.data);
  //     newArray[findIndex].activityComments = newComment.messages;
  //     setCoachData(newArray);
  //   }
  // };

  const updateComments = (newComment) => {
    // Find the index of the activity to update
    const findIndex = coachData.findIndex(
      (activity) => activity._id === newComment.activityId
    );
    if (findIndex !== -1) {
      // Update the activity's comments
      const updatedActivity = {
        ...coachData[findIndex],
        activityComments: newComment.messages,
      };
      // Update the coachData state with the updated activity
      const updatedData = [
        ...coachData.slice(0, findIndex),
        updatedActivity,
        ...coachData.slice(findIndex + 1),
      ];
      setCoachData(updatedData);
      setLoading(false);
    }
  };

  const selectFilter = (obj) => {
    if (obj._id === 1) {
      setCoachData(coachActivityNewsFeedData?.data);
      setSelectedUser({ _id: 1, firstName: "All Clients" });
      return;
    }
    let t = coachActivityNewsFeedData?.data.filter(
      (e) => e.userInfo._id === obj._id
    );
    setCoachData(t);
    dispatch({ type: "PERSIST_FILTER_IN_COACH_DASHBOARD", payload: obj });
    setSelectedUser(obj);
  };
  useEffect(() => {
    setLoading(true);
    setCoachData(coachActivityNewsFeedData?.data);
    setSelectedUser({ _id: 1, firstName: "All Clients" });
    setLoading(false);
  }, [coachActivityNewsFeedData]);

  useEffect(() => {
    setLoading(true);
    socket.on("new-comments", (newComment3) => {
      setNewComment(newComment3);
      setLoading(false);
    });
  }, [socket]);

  // useEffect(() => {
  //   if (newComment !== null) {
  //     setLoading(true);

  //     updateComments(newComment);
  //     selectFilter(persistFilterState);

  //   }
  // }, [newComment]);

  useEffect(() => {
    if (newComment !== null) {
      setLoading(true);
      updateComments(newComment);
    }
  }, [newComment]);

  useEffect(() => {
    setLoading(false); // Set loading state to false after all necessary updates
  }, [coachData]);
  return (
    <>
      <div className="row coach-dashboard-container">
        <div className="col-md-3 col-lg-3 p-0">
          <div className="main-container-left">
            <div className="day-date-leftpanel">
              <div className="day-leftpanel">
                {moment(today).format("dddd")}
              </div>
              <div className="date-leftpanel">
                {moment(today).format("MMM Do")}
              </div>
            </div>
            <div className="today-activity-container">
              <h6>Today's Activity Performers </h6>
              {/* <Dropdown className="d-inline mx-2 dropAtStart">
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className="btn-update-list"
                >
                  Today's Activity
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#">person 1</Dropdown.Item>
                  <Dropdown.Item href="#">person 2</Dropdown.Item>
                  <Dropdown.Item href="#">person 3</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}{" "}
            </div>
            <div className="today-activity-data-list">
              {todayActivityClientList?.data?.length ? (
                todayActivityClientList?.data?.map(
                  (todayActivityData, index) => {
                    return (
                      <div key={index} className="t-a-container">
                        <div className="today-activity-data d-flex">
                          <div className="client-img">
                            {todayActivityData?.imageUrl ? (
                              <img
                                src={todayActivityData?.imageUrl}
                                alt="img"
                              />
                            ) : (
                              <img
                                className="profile-pic"
                                src="/img/sample_img_2.png"
                                alt="img"
                              />
                            )}
                          </div>
                          <div className="client-name pt-1">
                            <div className="today-activity-performers-client-name">
                              {todayActivityData?.firstName}{" "}
                              {todayActivityData?.lastName}
                            </div>
                            <div
                              className="client-name-status"
                              style={{
                                fontWeight: "bolder",
                                color:
                                  todayActivityData?.status === "Complete"
                                    ? "#55bab2"
                                    : "rgb(245 48 48)",
                              }}
                            >
                              {todayActivityData?.status}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div
                  style={{
                    fontSize: "12px",
                    paddingTop: "10vh",
                  }}
                >
                  No activity performers today
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 p-0">
          <div className="main-container-center">
            {loading ? (
              <>
                <Oval
                  height={30}
                  width={30}
                  color="#1b46b4"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#56bcb2"
                  strokeWidth={6}
                  strokeWidthSecondary={6}
                />
              </>
            ) : (
              <>
                <div className="updates-selector">
                  <p>Updates from</p>
                  <div className="dropdown-update-selector">
                    <Dropdown className="d-inline mx-2 dropdownAtEnd">
                      <Dropdown.Toggle
                        id="dropdown-autoclose-true"
                        className="btn-update-list"
                      >
                        {selectedUser?.firstName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {coachClientsData?.data
                          ?.concat([{ _id: 1, firstName: "All clients" }])
                          ?.sort((a, b) =>
                            a.firstName.localeCompare(b.firstName)
                          )
                          ?.map((clientData, index) => {
                            return (
                              <Dropdown.Item
                                href="#"
                                onClick={() => selectFilter(clientData)}
                              >
                                {clientData?.firstName} {clientData?.lastName}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {loading ? (
                  <Oval
                    height={30}
                    width={30}
                    color="#1b46b4"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#56bcb2"
                    strokeWidth={6}
                    strokeWidthSecondary={6}
                  />
                ) : coachData?.length !== 0 ? (
                  <>
                    <div className="activity-news-feed-container">
                      {coachData?.map((activityData, index) => {
                        return (
                          <div
                            key={index}
                            className="updates-from-client-container"
                          >
                            <div className="u-f-c-header">
                              <div className="client-img-name d-flex">
                                <div className="client-img">
                                  {activityData?.userInfo?.imageUrl ? (
                                    <img
                                      src={activityData?.userInfo?.imageUrl}
                                      alt="img"
                                    />
                                  ) : (
                                    <img
                                      className="profile-pic"
                                      src="/img/sample_img_2.png"
                                      alt="img"
                                    />
                                  )}
                                </div>
                                <div className="client-name-and-date-news-feed">
                                  <div className="client-name date-news-feed-client-name">
                                    <h5>
                                      {activityData?.userInfo?.firstName}{" "}
                                      {activityData?.userInfo?.lastName}
                                    </h5>
                                  </div>
                                  <div className="client-activity-time">
                                    <p>
                                      {activityData?.createdAt ? (
                                        <>
                                          Due{" "}
                                          {moment(
                                            activityData.createdAt
                                          ).format("dddd MMM Do, YYYY")}{" "}
                                          |
                                        </>
                                      ) : (
                                        " No activity due | "
                                      )}{" "}
                                      {activityData?.latestPerformedActivity ? (
                                        <>
                                          {" "}
                                          Last activity{" "}
                                          {moment(
                                            activityData?.latestPerformedActivity
                                          ).format("dddd MMM Do, YYYY")}
                                        </>
                                      ) : (
                                        "No activity completed yet"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="u-f-c-body">
                              <div className="events-and-checkbox ">
                                <div className="events d-flex">
                                  <img src="/img/Rectangle client.png" alt="" />
                                  <p>{activityData?.title}</p>
                                </div>
                                <div className="checkbox">
                                  {activityData?.isCompleted ? (
                                    <>
                                      <button
                                        className="coach-dashboard-activity-status-button"
                                        style={{
                                          marginRight: "1.5rem",
                                          backgroundColor: "#55bab2",
                                          border: "none",
                                          color: "white",
                                          padding: "6px 9px",
                                          borderRadius: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Completed
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="coach-dashboard-activity-status-button"
                                        style={{
                                          marginRight: "1.5rem",
                                          backgroundColor: "rgb(42, 99, 179)",
                                          border: "none",
                                          color: "white",
                                          padding: "6px 9px",
                                          borderRadius: "8px",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Incomplete
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className="comments-section-dashboard"
                                style={{
                                  overflowY: "auto",
                                  maxHeight: "50vh",
                                }}
                              >
                                {activityData?.activityComments?.map(
                                  (activityclientcomment, index) => {
                                    return (
                                      <div
                                        key={index}
                                        ref={myRef}
                                        style={{
                                          backgroundColor: "rgb(242, 242, 244)",
                                          borderWidth: 1,
                                          borderColor: "black",
                                          marginBottom: 20,
                                          marginLeft: 50,
                                          marginRight: 50,
                                          paddingLeft: 10,
                                          paddingRight: 10,
                                          paddingTop: 10,
                                          paddingBottom: 10,
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            {activityclientcomment?.imageUrl ? (
                                              <img
                                                src={
                                                  activityclientcomment?.imageUrl
                                                }
                                                alt="img"
                                                style={{
                                                  width: 50,
                                                  height: 50,
                                                  borderRadius: 25,
                                                  // marginRight: 10,
                                                }}
                                              />
                                            ) : (
                                              <img
                                                className="profile-pic"
                                                style={{
                                                  width: 40,
                                                  height: 40,
                                                  borderRadius: 25,
                                                  // marginRight: 10,
                                                }}
                                                src="/img/sample_img_2.png"
                                                alt="img"
                                              />
                                            )}
                                            <p
                                              style={{
                                                marginTop: 6,
                                                marginLeft: 10,
                                              }}
                                            >
                                              {activityclientcomment?.firstName}
                                            </p>
                                          </div>
                                          <div
                                            style={{
                                              marginTop: 0,
                                              fontSize: "12px",
                                              color: "#8c99a8",
                                            }}
                                          >
                                            {moment(
                                              activityclientcomment?.createdAt
                                            ).format("dddd MMM Do")}
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            textAlign: "left",
                                            overflowWrap: "anywhere",
                                            paddingTop: 20,
                                            fontSize: "13px",
                                          }}
                                        >
                                          {activityclientcomment?.comment}
                                        </div>
                                      </div>
                                      // <div key={index} className="u-f-c-client-comment">
                                      //   <div className="name-and-time">
                                      //     <div
                                      //       className="client-img"
                                      //       style={{
                                      //         display: "flex",
                                      //         flexDirection: "row",
                                      //       }}
                                      //     >
                                      // <img
                                      //   src={activityclientcomment?.imageUrl}
                                      //   alt="img"
                                      // />
                                      // <div
                                      //   className=""
                                      //   style={{ marginTop: 5, marginLeft: 10 }}
                                      // >
                                      //   {activityclientcomment?.firstName}
                                      // </div>
                                      //     </div>

                                      //     <div className="time">
                                      //       {moment(
                                      //         activityclientcomment?.createdAt
                                      //       ).format("dddd MMM Do")}
                                      //     </div>
                                      //   </div>
                                      // <div className="comments">
                                      //   {activityclientcomment?.comment}
                                      // </div>
                                      // </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <div className="u-f-c-footer">
                              <p>Comments</p>
                              <CoachDashboardInputFields
                                sendCommentBtn={sendCommentBtn}
                                activityData={activityData}
                                setComment={setComment}
                              />
                              {/* <div className="u-f-c-comments-input">
                        <input
                          value={comment}
                          row="2"
                          onChange={(e) => setComment(e.target.value)}
                          placeholder="Write a comment ..."
                        />
                      </div> */}
                              {/* <div className="emoji-camera-and-button">
                        <div className="emoji-camera">
                          <img
                            className="camera"
                            src="/img/coach-d-camera.png"
                            alt="camera"
                          />
                          <img
                            className="smile"
                            src="/img/coach-d-smile.png"
                            alt="smile"
                          />
                        </div>
                        <div className="u-f-c-comments-sender-btn">
                          <button
                            onClick={(e) => sendCommentBtn(activityData._id)}
                          >
                            Send
                          </button>
                        </div> */}
                              {/* </div> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <h1
                    style={{
                      height: "100%",
                      width: "100%",
                      marginTop: "50%",
                    }}
                  >
                    No feeds yet
                  </h1>
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-md-3 col-lg-3 p-0">
          <div className="main-container-right">
            <div className="engagement-rate-container">
              <h6>Engagements rate</h6>
              <div className="engagement-rate-param">
                <p>7 days </p>
                <p>30 days</p>
                <p>90 days </p>
              </div>
              <div className="engagement-rate-data">
                <p>
                  {coachEngagementsData?.data?.totalSevenDays
                    ? coachEngagementsData?.data?.totalSevenDays
                        ?.toString()
                        .split(".")[0]
                    : "0"}
                  %{" "}
                </p>

                <p>
                  {coachEngagementsData?.data?.totalThirtDays
                    ? coachEngagementsData?.data?.totalThirtDays
                        ?.toString()
                        .split(".")[0]
                    : "0"}
                  %
                </p>
                <p>
                  {coachEngagementsData?.data?.totalNinetyDays
                    ? coachEngagementsData?.data?.totalNinetyDays
                        ?.toString()
                        .split(".")[0]
                    : "0"}
                  %{" "}
                </p>
              </div>
            </div>
            <div className="due-soon-container">
              <div className="due-soon-dropdown">
                <h6>Due soon</h6>
                {/* <Dropdown className="d-inline mx-2 dropdownAtEnd">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    className="btn-update-list"
                  >
                    Due soon
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">person 1</Dropdown.Item>
                    <Dropdown.Item href="#">person 2</Dropdown.Item>
                    <Dropdown.Item href="#">person 3</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
              <div className="due-soon-data">
                {dueSoonClientList?.data?.length ? (
                  dueSoonClientList?.data?.map((dueSoonClientList, index) => {
                    return (
                      <div key={index} className="t-a-container">
                        <div className="due-soon-data-container d-flex">
                          <div className="client-img ">
                            {dueSoonClientList?.imageUrl ? (
                              <img
                                className="image"
                                src={dueSoonClientList?.imageUrl}
                                alt="img"
                              />
                            ) : (
                              <img
                                className="image"
                                src="/img/sample_img_2.png"
                                alt="img"
                              />
                            )}
                          </div>
                          <div className="client-name-and-time">
                            <div className="client-name due-soon-client-name">
                              {dueSoonClientList?.firstName}{" "}
                            </div>
                            <div className="client-time">
                              <span>
                                {moment(dueSoonClientList?.dueDate).format(
                                  "ddd MMM Do"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      paddingTop: "10vh",
                    }}
                  >
                    No clients are due soon
                  </div>
                )}
              </div>
            </div>
            <div className="needs-attention-container">
              <div className="needs-attention-dropdown">
                <h6>Needs attention</h6>
                {/* <Dropdown className="d-inline mx-2 dropdownAtEnd">
                  <Dropdown.Toggle
                    id="dropdown-autoclose-true"
                    className="btn-update-list"
                  >
                    Needs attention
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#">person 1</Dropdown.Item>
                    <Dropdown.Item href="#">person 2</Dropdown.Item>
                    <Dropdown.Item href="#">person 3</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </div>
              <div className="need-attention-data-list">
                {needAttentionClientList?.data?.length ? (
                  needAttentionClientList?.data?.map(
                    (todayAttentionData, index) => {
                      return (
                        <div key={index} className="t-a-container">
                          <div className="today-activity-data d-flex">
                            <div className="client-img">
                              {todayAttentionData?.imageUrl ? (
                                <img
                                  className="image"
                                  src={todayAttentionData?.imageUrl}
                                  alt="img"
                                />
                              ) : (
                                <img
                                  className="image"
                                  src="/img/sample_img_2.png"
                                  alt="img"
                                />
                              )}
                            </div>
                            <div className="client-name need-attention-client-name">
                              <div>{todayAttentionData?.firstName}</div>
                              <div className="client-sevendays-engagement">
                                Recent 7 days engagement is
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {todayAttentionData?.recentSevenDaysEng} %
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div style={{ marginTop: "25%", fontSize: "12px" }}>
                    No clients need attention
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoachDashboard;
