import React, { useState, useEffect } from "react";

const SelectOption = ({ option, onOptionPress, selected, disabled }) => {
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handlePress = () => {
    if (!disabled) {
      onOptionPress();
      setIsSelected((prevSelected) => !prevSelected);
    }
  };

  return (
    <div
      className="container-survey-goals-checkboxoptions"
      onClick={!disabled ? handlePress : undefined}
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <div className="survey-goals-checkboxoptions">
        {isSelected && (
          <img
            className="image-survey-goals-checkboxoptions"
            src={"/img/check-goals.png"}
            alt="check"
          />
        )}
      </div>
      <div
        className={
          isSelected
            ? "selectedText-survey-goals-checkboxoptions"
            : "unselectedText-survey-goals-checkboxoptions"
        }
      >
        {option}
      </div>
    </div>
  );
};

export default SelectOption;
