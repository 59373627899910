import * as actionTypes from "../actions/actionType";
const initialState = {
  userActivityData: [],
  userSingleActivityData: [],
  updatedUserActivity: [],
  createUserActivityData: [],
  createCoachActivityData: [],
  userGoalsData: [],
  selectedActivityDataOfUser: [],
};
export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITY_DATA:
      return {
        ...state,
        userActivityData: action.payload,
      };
    case actionTypes.GET_SINGLE_ACTIVITY_DATA:
      return {
        ...state,
        userSingleActivityData: action.payload,
      };
    case actionTypes.GET_SELECTED_ACTIVITY_DATA_OF_USER:
      return {
        ...state,
        selectedActivityDataOfUser: action.payload,
      };
    case actionTypes.UPDATE_USER_ACTIVITY:
      return {
        ...state,
        updatedUserActivity: action.payload,
      };
    case actionTypes.CREATE_USER_ACTIVITY:
      return {
        ...state,
        createUserActivityData: action.payload,
      };
    case actionTypes.CREATE_COACH_ACTIVITY:
      return {
        ...state,
        createCoachActivityData: action.payload,
      };
    case actionTypes.GET_USER_GOALS_DATA:
      return {
        ...state,
        userGoalsData: action.payload,
      };
      case actionTypes.DELETE_ACTIVITY:
        return {
          ...state,
          DeleteActivityInReducer: action.payload,
        };
      case actionTypes.UPDATE_ACTIVITY:
        return {
          ...state,
          updateActivityInReducer: action.payload,
        };
    default:
      return state;
  }
};
