import React, { useState, useEffect } from "react";
import { Form, Spinner, Card } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OTPPassword = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.authentication);

  // useEffect(() => {
  //   dispatch(authActions.userSendOtpRequest(
  //     { phoneNumber: localStorage.getItem("phoneNumber") }))
  // }, []);

  const otpVerify = () => {
    if (otpCode.length === 4) {
      dispatch(
        authActions.userOtpVerificationRequest(
          { otpCode, phoneNumber: localStorage.getItem("phoneNumber") },
          callback
        )
      );
      setIsLoading(true);
    }
  };
  const resendotp = () => {
    dispatch(
      authActions.userSendOtpRequest({
        phoneNumber: localStorage.getItem("phoneNumber"),
      })
    );
  };

  const callback = (status) => {
    if (status === 200) {
      navigate("/survey");
    }
    setIsLoading(false);
  };
  return (
    <>
      <div className="otp-password">
        <div className="text-center my-3">
          <img src="/img/logo@2x.png" className="img-fluid" />
        </div>
        <div className="background-screen">
          <div className="d-grid justify-content-center align-items-center h-100">
            <Card className="">
              <Card.Body className="mx-5 my-5 otp-card-body">
                <div>
                  <h3 className="text-center mb-4 font-weight-bold">
                    Verify Your Phone
                  </h3>
                  <p>Please enter the four digit code sent to your phone</p>
                  <div className="d-flex verify-input mb-4">
                    <OtpInput
                      length={4}
                      value={otpCode}
                      className="otpContainer"
                      inputClassName="otpInput"
                      isNumberInput
                      autoFocus
                      onChange={(otp) => setOtpCode(otp)}
                    />
                  </div>
                  <div className="text-center">
                    {isLoading ? (
                      <button className="btn-login btn-lg">
                        <Spinner
                          className=""
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        variant="primary"
                        type="submit"
                        className="btn-login btn-lg"
                        onClick={otpVerify}
                      >
                        Verify
                      </button>
                    )}
                    <div className="mt-3">
                      <a
                        href="#"
                        className="mt-3 text-decoration-none"
                        onClick={resendotp}
                      >
                        Resend Code
                      </a>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div>
              <p className="text-center text-white">
                &copy; 2024 EQWELL ~ All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default OTPPassword;
