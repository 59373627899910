import React, { useState } from "react";

const CoachDashboardInputFields = (props) => {
  const [comment, setComment] = useState("");

  return (
    <>
      <div className="u-f-c-comments-input">
        <input
          value={comment}
          row="2"
          onChange={(e) => setComment(e.target.value)}
          placeholder="Write a comment ..."
        />
      </div>
      <div className="emoji-camera-and-button">
        {/* <div className="emoji-camera">
                          <img
                            className="camera"
                            src="/img/coach-d-camera.png"
                            alt="camera"
                          />
                          <img
                          />
                        </div> */}
        <div className="u-f-c-comments-sender-btn">
          <button
            className="coach-dashboard-activity-status-button"
            onClick={(e) => {
              setComment("");
              props.sendCommentBtn(props.activityData?._id, comment);
            }}
          >
            Send
          </button>
        </div>
      </div>
    </>
  );
};

export default CoachDashboardInputFields;
