import * as actionTypes from "../actions/actionType";
const initialState = {
  userprofileData: [],
  updateUserprofileData: [],
};
export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS:
      return {
        ...state,
        userprofileData: action.payload,
      };
    case actionTypes.UPDATE_USER_PROFILE:
      return {
        ...state,
        updateUserprofileData: action.payload,
      };
    default:
      return state;
  }
};
