import * as actionTypes from "../actions/actionType";
const initialState = {
  socketData: [],
};
export const socketSaverReducer = (state = initialState, action) => {


  switch (action.type) {
    
    case actionTypes.SOCKET_SAVER:
      return {
        ...state,
        socketData: action.payload,
      };
    default:
      return state;
  }
};
