import * as actionTypes from "../actions/actionType";

const initialState = {
  dueSoonClientList: [],
  todayActivityClientList: [],
  needAttentionClientList: [],
  coachEngagementsData: [],
  persistFilter: [],
};

export const coachDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DUE_SOON_CLIENT_LIST:
      return {
        ...state,
        dueSoonClientList: action.payload,
      };
    case actionTypes.TODAY_ACTIVITY_CLIENT_LIST:
      return {
        ...state,
        todayActivityClientList: action.payload,
      };
    case actionTypes.NEED_ATTENTION_CLIENT_LIST:
      return {
        ...state,
        needAttentionClientList: action.payload,
      };
    case actionTypes.GET_COACH_ENGAGEMENTS:
      return {
        ...state,
        coachEngagementsData: action.payload,
      };
    case actionTypes.PERSIST_FILTER_IN_COACH_DASHBOARD:
      return {
        ...state,
        persistFilter: action.payload,
      };
    default:
      return state;
  }
};
