import React, { useEffect, useState } from "react";
import { Form, Card, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

const EmailUnsubscribe = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token_id");

    if (token) {
      dispatch(authActions.emailUnsubscribeRequest({token}));
    }
  }, [dispatch]);

  return (
    <>
      <div className="forget-password">
        <div className="text-center my-3">
          <img src="/img/logo@2x.png" className="img-fluid" alt="Logo" />
        </div>
        <div className="background-screen">
          <div className="d-grid justify-content-center align-items-center h-100">
            <Card>
              <Card.Body style={{ margin: "5%", textAlign: "left" }}>
                <div>
                  <h3 className="text-left mb-4 font-weight-bold">Thank you</h3>
                  <p>
                    You have been successfully removed from this subscriber list
                    and won't receive any further email from us.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <div>
              <p className="text-center text-white">
                &copy; 2024 EQWELL ~ All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EmailUnsubscribe;
