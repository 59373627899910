import { io } from "socket.io-client";
const socketURL = process.env.REACT_APP_SOCKET_URL;
export var socket = io.connect(socketURL, {
  transports: ["polling"],
});
socket.on("disconnect", () => {
  // console.log("connect disconnect"); 
});

socket.io.on("reconnection_attempt", () => {
  // console.log("connect reconnection_attempt");
});

socket.io.on("reconnect", () => {
  // console.log("connect reconnect");
});

export const socketFun = (userId, setSocketLoading) => {
  socket = io.connect(socketURL, {
    transports: ["polling"],
  });
  if (userId !== undefined && userId !== null && userId !== "") {
    socket.on("connect", () => {
      socket.emit("client-connected", {
        userId: userId,
        clientSocketId: socket.id,
      });
    });
    // setSocketLoading(false);
  } else {
    // setSocketLoading(false);
  }
};
