import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers/index.js";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import localforage from "localforage";
//redux-logger used to see the redux data in the console
// const loggerMiddleware = createLogger();

const persistConfig = {
  key: "root",
  storage: localforage,
  blacklist: ["getQuestion"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [
  thunk,
  // ,createLogger
];

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

const persistor = persistStore(store);

export { store, persistor };

// blacklist: ['notes']
// whitelist: ['users']
