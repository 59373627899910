import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { ToastContainer } from "react-toastify";
import { toastify } from "././../../../components/Toast";

const UserProfile = () => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editProfileState, setEditProfileState] = useState(true);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const { userprofileData } = useSelector((state) => state.getProfileData);
  useEffect(() => {
    dispatch(authActions.getUserDetails());
  }, []);
  useEffect(() => {
    setFirstName(userprofileData?.data?.firstName);
    setLastName(userprofileData?.data?.lastName);
    setEmail(userprofileData?.data?.email);
    setPhoneNumber(userprofileData?.data?.phoneNumber);
    setImage(userprofileData?.data?.imageUrl);
  }, [userprofileData]);

  const onEditprofile = () => {
    setEditProfileState(!editProfileState);
    setShowImage(null);
    setIsImageEdit(false);
  };
  const onProfileSave = () => {
    setIsLoading(true);
    let formData = new FormData();
    if (isImageEdit) {
      formData.append("image", image);
    }
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phoneNumber", phoneNumber);

    if (firstName && lastName) {
      dispatch(
        authActions.updateUserProfileData(formData, callbackForUpdatedProfile)
      );
    } else {
      toastify("error", "Please fill all fields");
      setIsLoading(false);
    }
  };
  const callbackForUpdatedProfile = () => {
    setIsLoading(false);
    dispatch(authActions.getUserDetails());
    setEditProfileState(true);
  };

  const onImageSelect = (e) => {
    setIsImageEdit(true);
    setImage(e.target.files[0]);
    setShowImage(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <>
      {editProfileState ? (
        <div className="user-profile p-5">
          <div className="profile-header">
            <h1>Profile</h1>
          </div>
          <div className="profile-main-container ">
            <Card style={{ height: "100%" }}>
              <div className="profile-btn">
                <button onClick={onEditprofile}> Edit Profile</button>
              </div>
              <div className="profile-card-container coach-profile py-3">
                <div>
                  {userprofileData?.data?.imageUrl ? (
                    <img
                      className=" user-profile-img"
                      src={userprofileData?.data?.imageUrl}
                      alt="img"
                    />
                  ) : (
                    <img
                      className=" user-profile-img"
                      src="/img/sample_img_2.png"
                      alt="img"
                    />
                  )}

                  <h5 className="font-weight-bold mb-5">
                    {firstName} {lastName}
                  </h5>
                  <div className="profile-info mb-5">
                    <div className="user-email">
                      <div className=" email-icon">
                        <img src="/img/profile-email.png" alt="pic" />
                      </div>
                      <div className="email-wrapper">
                        <div>
                          <p className="email-head profile-heading">
                            Email address
                          </p>
                        </div>
                        <div>
                          <p className="email-address">{email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="user-phone">
                      <div className="phone-icon">
                        <img src="/img/phone-profile.png" alt="pic" />
                      </div>
                      <div>
                        <p className="phone-head profile-heading">
                          Mobile number
                        </p>
                        <p className="phone-number">{phoneNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      ) : (
        <div className="user-profile p-5">
          <div className="profile-header">
            <h1> Edit Profile</h1>
          </div>
          <div className="profile-main-container">
            <Card style={{ height: "100%" }}>
              <div className="coach-profile py-3">
                <div>
                  <div className="edit-profile-img">
                    {showImage !== null ? (
                      <img
                        className=" user-profile-img"
                        src={showImage}
                        alt="img1"
                      />
                    ) : (
                      <>
                        {userprofileData?.data?.imageUrl ? (
                          <img
                            className="user-profile-img"
                            src={userprofileData?.data?.imageUrl}
                            alt="img"
                          />
                        ) : (
                          <img
                            className="user-profile-img"
                            src="/img/sample_img_2.png"
                            alt="img"
                          />
                        )}
                      </>
                    )}

                    <div className="image-file">
                      <label htmlFor="file-input">
                        <img
                          className="edit-profile-img-icon"
                          src="/img/edit.png"
                          alt="img"
                        />
                      </label>
                      <input
                        type="file"
                        id="file-input"
                        onChange={onImageSelect}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </div>
                  </div>
                  <div className="edit-profile-inputs">
                    <div className="profile-inputs d-flex justify-content-center">
                      <form action="">
                        <div className="edit-profile-input-field">
                          <input
                            type="text"
                            defaultValue={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            id="fname"
                            required
                          />
                          <label htmlFor="fname">First name</label>
                        </div>
                      </form>

                      <form action="">
                        <div className="edit-profile-input-field">
                          <input
                            type="text"
                            defaultValue={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            id="lname"
                            required
                          />
                          <label htmlFor="lname">Last name</label>
                        </div>
                      </form>
                    </div>
                    <div className="profile-inputs d-flex justify-content-center">
                      <form action="">
                        <div className="edit-profile-input-field">
                          <input
                            type="text"
                            value={email}
                            id="email"
                            required
                          />
                          <label className="ml-2" htmlFor="email">
                            Email address
                          </label>
                        </div>
                      </form>

                      <form action="">
                        <div className="edit-profile-input-field">
                          <input
                            type="text"
                            value={phoneNumber}
                            // onChange={(e) => setPhoneNumber(e.target.value)}
                            id="mobile"
                            required
                          />
                          <label className="ml-3" htmlFor="mobile">
                            Mobile number
                          </label>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="edit-profile-btns">
                    <div className="edit-profile-btn-cancel">
                      <button
                        className="edit-profile-button-cancel"
                        onClick={onEditprofile}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="edit-profile-btn-save">
                      {isLoading ? (
                        <button className="edit-profile-button-save">
                          <Spinner
                            className=""
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </button>
                      ) : (
                        <button
                          className="edit-profile-button-save"
                          onClick={onProfileSave}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;
