import React, { useState, useEffect, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "../../../redux/actions/authAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { socket, socketFun } from "../../../constants/socket";
// import { io, Socket } from "socket.io-client";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef(null);

  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [eye, seteye] = useState(true);
  const [type, settype] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  var values = {
    email: email,
    password: password,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const { user } = useSelector((state) => state.authentication);

  const { user } = useSelector((state) => state?.authentication);
  const userIDFromState = user?.data?.userId;

  const Eye = () => {
    if (type == "password") {
      settype("text");
      seteye(false);
    } else {
      settype("password");
      seteye(true);
    }
  };

  const onSubmit = () => {
    if (values) {
      dispatch(
        authActions.userLoginRequest(
          values,
          callback,
          callBack,
          callBackForError
        )
      );
      setIsLoading(true);
    }
  };
  const callBack = (data) => {
    if (
      data?.message !== "Invalid credentials" &&
      data?.message === "Please verify your number"
    ) {
      navigate("/otppassword");
      dispatch(
        authActions.userSendOtpRequest({
          phoneNumber: data?.data?.phoneNumber,
        })
      );
    }
  };
  const callback = (response) => {
    if (
      response.status === 200 &&
      !response?.data?.isAdmin &&
      response?.data?.isCoach === true
    ) {
      const userIdfromResponse = response?.data?.userId;
      setTimeout(() => {
        socketFun(userIdfromResponse, setIsLoading);
        setIsLoading(false);

        navigate("/coach-dashboard");
      }, 3000);
    }
    if (
      response.status === 200 &&
      !response?.data?.isAdmin &&
      !response?.data?.isCoach
    ) {
      const userIdfromResponse = response?.data?.userId;
      setTimeout(() => {
        socketFun(userIdfromResponse, setIsLoading);
        setIsLoading(false);
        navigate("/dashboard");
      }, 3000);
    }
    dispatch(authActions.getUserDetails());
  };
  const callBackForError = () => {
    setIsLoading(false);
  };
  return (
    <>
      <div className="login">
        <div className="row">
          <div className="col-md-8">
            <div className="">
              <div className="left-side container ">
                <div>
                  <div className="mb-4">
                    <img src="/img/logo-white.png" className="img-fluid" />
                  </div>
                  <div className="generic-font font-weight-bold">
                    <h1 className="text-white d-flex">You want to sign</h1>
                    <h1 className="text-white d-flex">up as organization</h1>
                    <h1 className="text-white d-flex">or user?</h1>
                  </div>
                  <div className="my-5">
                    <Link to="/register" className="text-decoration-none">
                      <button className="d-flex btn-signup font-weight-bold ">
                        Sign Up
                      </button>
                    </Link>
                  </div>
                  <div>
                    <ul className="d-flex justify-content-between  font-weight-bold list-unstyled">
                      <li>
                        <a href="#" className="text-white text-decoration-none">
                          Why Eqwell ?
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-white text-decoration-none">
                          Pillars of Well-being
                        </a>
                      </li>
                      <li>
                        <a href="#" className="text-white text-decoration-none">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex align-items-center">
            <div className="right-side container my-3">
              <div className="login-title">
                <h2 className="text-center mb-5 font-weight-bold">
                  Login to your account
                </h2>
              </div>
              <div className=" login-form">
                <Form
                  className="login-inputs"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="float-left">
                      Email Address
                    </Form.Label>
                    <Form.Control
                      {...register("email", {
                        required: true,
                        pattern:
                          "^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[d]){1,})(?=(.*[W]){1,})(?!.*s).{8,}$",
                      })}
                      type="email"
                      // placeholder="Email Address"
                      name="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  {errors?.email?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                  {errors?.email?.type === "pattern" && (
                    <p>kindly input correct format</p>
                  )}

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="float-left">Password</Form.Label>
                    <Form.Control
                      {...register("password", {
                        required: true,
                      })}
                      type={type}
                      // placeholder="Password"
                      required
                      onChange={(e) => setPassword(e.target.value)}
                    />

                    <i
                      onClick={Eye}
                      className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    ></i>
                  </Form.Group>
                  {errors?.email?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                  <div className="mb-3">
                    <p className="d-flex justify-content-end">
                      <Link
                        to="/forgetpassword"
                        className="text-decoration-none"
                      >
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                  <div className="text-center">
                    {isLoading ? (
                      <button className="btn-login btn-lg">
                        <Spinner
                          className=""
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </button>
                    ) : (
                      <button
                        variant="primary"
                        type="submit"
                        className="btn-login btn-lg font-weight-bold"
                      >
                        Login
                      </button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default Login;
