import * as actionTypes from "../actions/actionType";

const initialState = {
  questions: [],
  reports: [],
  details: [],
  score: [],
  goals: [],
  journeysData: [],
  suggestedSurvey: [],
};

export const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUESTION_DATA:
      return {
        ...state,
        questions: action.payload,
      };
    case actionTypes.NEXT_QUESTION_DATA_EMPTY:
      return {
        questions: [...state.questions, []],
      };
    case actionTypes.GET_REPORT_DATA:
      return {
        ...state,
        reports: action.payload,
      };
    case actionTypes.GET_DETAIL_DATA:
      return {
        ...state,
        details: action.payload,
      };
    case actionTypes.GET_SCORE_DATA:
      return {
        ...state,
        score: action.payload,
      };
    case actionTypes.GET_GOALS_DATA:
      return {
        ...state,
        goals: action.payload,
      };
    case actionTypes.GET_JOURNEYS_DATA:
      return {
        ...state,
        journeysData: action.payload,
      };
    case actionTypes.GET_SURVEY_SUGGESTION:
      return {
        ...state,
        suggestedSurvey: action.payload,
      };
    default:
      return state;
  }
};
