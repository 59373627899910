import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import * as authActions from "../redux/actions/authAction";

const AppHeader = () => {
  const dispatch = useDispatch();
  // const { userprofileData } = useSelector((state) => state.getProfileData);

  return (
    <>
      <div className="top-dashboard px-5 pt-4">
        <div className="row mb-3">
          <div className="col-md-6">
            <h1 className="top-dashboard-username d-flex">
              {/* Good Morning, {userprofileData?.data?.firstName} */}
            </h1>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div>
              <NavLink to="/chat-coach">
                <Button
                  variant="success"
                  className="btn-coach me-4"
                  onClick={() => dispatch(authActions.showCoachContainer(true))}
                >
                  Chat Coach
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AppHeader;
